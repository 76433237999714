import {useEffect, useState} from "react";
import {useAppSelector} from "../../../store/hooks";
import s from "../operations.module.scss";
import {InputCurrency} from "../../generic/input/input-currency/InputCurrency";
import {getCurrencySymbol} from "../../../utils/currency/currency";
import {Button} from "../../generic/button/button-default/Button";
import {InputText} from "../../generic/input/input-text/inputText";

export const TransferByUsername: React.FC = () => {
  const chatData = useAppSelector((state) => state.chat.data);

  const [form, setForm] = useState({
    isValid: false,
    data: {summ: "", username: `@${chatData.username || ""}`},
  })

  useEffect(() => {
    setForm((form) => {
      return {
        ...form,
        isValid: form.data.summ !== "" && form.data.username !== "",
      }
    })
  }, [form.data.summ, form.data.username])

  const onChangeSumm = (v: string) => {
    setForm({
      ...form,
      data: {
        ...form.data,
        summ: v,
      },
    })
  }

  const onChangeUsername = (e: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      data: {
        ...form.data,
        username: e.currentTarget.value,
      },
    })
  }

  const onSubmitForm = () => {};

  return (
    <div className={s["operations"]}>
      <div className={s["operations__row"]}>
        <InputText
          value={form.data.username}
          onChange={onChangeUsername}
          />
      </div>
      <div className={s["operations__row"]}>
        <InputCurrency
          id="summ"
          value={form.data.summ || ""}
          placeholder="Сумма"
          autoComplete="off"
          onChangeValue={onChangeSumm}
          currencySymbol={getCurrencySymbol("643")}
        />
        <p className={s["operations__fee"]}>
          Размер комиссии будет уточнен после ввода реквизитов
        </p>
      </div>
      <div className={s["operations__row"]}>
        <Button
          buttonText="Отправить"
          disabled={!form.isValid}
          onClick={onSubmitForm}
        />
      </div>
    </div>
  )
}