import s from "./history.module.scss"
import a from "./analytics.module.scss"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { getHistory, historySelector } from "store/history/HistoryActions"
import { Operations } from "components/history/operations/Operations"
import { Moment } from "moment"
import {
  addMccGroup,
  byPeriod,
  calculateChartData,
  calculateGroupSpents,
  calculateOperationsHistory,
  bySpents,
  Period,
  ActiveGroup,
  byActiveGroup,
  GroupSpent,
} from "./utils"
import { CustomCalendar } from "components/generic/vendor/calendar/Calendar"
import { Time } from "utils/Time"
import { ReactComponent as CloseIcon } from "components/icon/close.svg"
import { HistoryLegend } from "./legend/Legend"
import { ActiveChart, Charts } from "components/history/charts/Charts"
import { AnalyticsHead } from "./analytics-head/AnalyticsHead"
import { Mcc } from "store/mcc/Mcc"
import { OperationItem } from "./operations/OperationItem"
import { OperationSingle } from "./operations/OperationSingle"

interface OperationsHistoryProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  mcc: Mcc

  defaultChart?: ActiveChart
  isStatsHidden?: boolean
  minimal?: boolean
}

export const OperationsHistory: React.FC<OperationsHistoryProps> = ({
  mcc,
  defaultChart = "bar",
  isStatsHidden = false,
  minimal = false,
  ...props
}) => {
  const dispatch = useDispatch()
  const { history, pending } = useSelector(historySelector)

  const [isHidden, setIsHidden] = useState(isStatsHidden)
  const [activeGroups, setActiveGroups] = useState<ActiveGroup>({})
  const [activeChart, setActiveChart] = useState<ActiveChart>(defaultChart)

  const [currentPeriod, setCurrentPeriod] = useState<Period>({
    begin: Time.now().startOf("month"),
    end: Time.now().endOf("month"),
  })

  const periodHistory = addMccGroup(history || [], mcc).filter((item) =>
    byPeriod(item, currentPeriod)
  )

  const onChangeCalendar = (period: { begin: Moment; end: Moment }) => {
    const newPeriod = {
      begin: period.begin.startOf("day"),
      end: period.end.endOf("day"),
    }

    setCurrentPeriod(newPeriod)
    dispatch(
      getHistory(
        Time.toIsoString(newPeriod.begin),
        Time.toIsoString(newPeriod.end)
      )
    )
  }

  const onClickHideLegend = (item: GroupSpent) => {
    setActiveGroups({ ...activeGroups, [item.name]: !activeGroups[item.name] })
  }

  const onClickSwitcher = () => {
    setActiveChart(activeChart === "donut" ? "bar" : "donut")
  }

  const onClickHider = () => {
    setIsHidden(!isHidden)
  }

  const onResetGroups = () => {
    setActiveGroups(
      Object.keys(activeGroups).reduce((acc: ActiveGroup, curr) => {
        acc[curr] = true
        return acc
      }, {})
    )
  }

  useEffect(() => {
    const mccStateGroups = Object.values(mcc.groups)
    if (mccStateGroups.length) {
      const groups = mccStateGroups.reduce((acc: ActiveGroup, curr) => {
        acc[curr.name] = true
        return acc
      }, {})

      setActiveGroups(groups)
    }
  }, [mcc])

  const spentsHistory = periodHistory.filter(bySpents)
  const chartData = calculateChartData(
    spentsHistory,
    currentPeriod,
    activeGroups
  )
  const legendData = calculateGroupSpents(spentsHistory, activeGroups)
  const operationsData = calculateOperationsHistory(
    periodHistory.filter((item) => byActiveGroup(item, activeGroups))
  )

  const activeLegend = legendData.filter((l) => l.active)
  const total = activeLegend.reduce((previous: number, current) => {
    return previous + current.amount
  }, 0)

  const diff = legendData.length - activeLegend.length

  return (
    <div className={`${s["history"]} ${props.className || ""}`}>
      {!minimal && (
        <div className={s["history__head"]}>
          <CustomCalendar
            currentPeriod={currentPeriod}
            onCalendarChanged={onChangeCalendar}
          />
        </div>
      )}

      <div className={a["analytics"]}>
        <AnalyticsHead
          total={total}
          activeChart={activeChart}
          onSwitchChartType={onClickSwitcher}
          onHideData={onClickHider}
          isDataHidden={isHidden}
        >
          {diff > 0 && (
            <p className={a["diff"]} onClick={() => onResetGroups()}>
              -{diff} кат.{" "}
              <span className={a["diff__img"]}>
                <CloseIcon />
              </span>
            </p>
          )}
        </AnalyticsHead>

        {Object.values(legendData).length > 0 && !isHidden && (
          <>
            <p className={a["total-text"]}>
              Общая сумма расходов
              {Time.isFullMonth(currentPeriod.begin, currentPeriod.end)
                ? " за " + Time.formatMoment(currentPeriod.begin, "MMM")
                : ""}
            </p>
            <Charts
              barChartData={chartData}
              donutChartData={legendData}
              activeChart={activeChart}
            >
              {!minimal && (
                <HistoryLegend
                  legendData={legendData}
                  onClickHideLegend={onClickHideLegend}
                />
              )}
            </Charts>
          </>
        )}
      </div>

      {!minimal ? (
        <>
          <div style={{ padding: "10px 0" }} />
          <Operations history={operationsData} pending={pending} />
        </>
      ) : (
        ""
        // <OperationSingle history={periodHistory[0] || null} pending={pending} />
      )}
    </div>
  )
}
