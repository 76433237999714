import { getCurrencySymbol } from "utils/currency/currency"
import { splitAmount } from "utils/currency/format-currency"
import s from "./pretty-amount.module.scss"

export interface PrettyAmountProps {
  amount: string | number
  currencyCode: string
  balance?: { current: number; before: number }
  shouldHideKop?: boolean
}

export const PrettyAmount: React.FC<PrettyAmountProps> = ({
  amount,
  currencyCode,
  balance,
  shouldHideKop = false,
}) => {
  const amt = splitAmount(amount + "" || "000")
  const rub = amt?.rubParts.length ? amt.rubParts.join(" ") : "0"
  shouldHideKop = rub === "0"
  const kop = amt?.kop === "00" || shouldHideKop ? "" : amt?.kop
  const leadingSybmol = balance
    ? balance?.current >= balance?.before
      ? "+"
      : "-"
    : null

  return (
    <span className={s["pretty-amount"]}>
      {leadingSybmol ? (
        <span className={s["pretty-amount__leading-symbol"]}>
          {leadingSybmol}
        </span>
      ) : (
        ""
      )}
      <span className={s["pretty-amount__rub"]}>{rub}</span>
      <span
        className={s["pretty-amount__kop"]}
        style={{ display: kop ? "inline-block" : "none" }}
      >
        ,{kop}
      </span>
      <span className={s["pretty-amount__currency"]}>
        {getCurrencySymbol(currencyCode)}
      </span>
    </span>
  )
}
