import { ApiResponseError } from "store/types"
import { Account, Card } from "./Card"
import { CardsActionTypes, PayloadedAction } from "./CardsActions"

interface CardsData {
  primaryCard: Card | null
  primaryAccount: Account | null
  cards: Card[] | never[] | null
  pending: boolean
  errors: ApiResponseError[] | null
}

export interface CardsState {
  readonly data: CardsData
}

const initialState: { data: CardsData } = {
  data: {
    primaryAccount: null,
    primaryCard: null,
    cards: null,
    
    pending: false,
    errors: null,
  },
}

export const CardsReducer = (
  state: CardsState = initialState,
  payloadedAction: PayloadedAction
): CardsState => {
  if (payloadedAction.payload) {
    const {
      payload: { cards, primaryCard, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    const primaryAccount = primaryCard?.accounts.find(
      (acct) => acct.number.toUpperCase().startsWith("QT") && acct.type === 1
    )

    switch (payloadedAction.type) {
      case CardsActionTypes.CARDS_REQUEST:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: true,
          },
        }
      case CardsActionTypes.CARDS_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: false,
            cards,
            primaryCard,
            primaryAccount: primaryAccount || null,
          },
        }
      case CardsActionTypes.CARDS_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            errors: errors,
            pending: false,
          },
        }
      case CardsActionTypes.CARDS_SET_DEFAULT_CARD:
        return {
          data: {
            ...stateData,
            primaryCard,
            primaryAccount: primaryAccount || null,
          },
        }
      case CardsActionTypes.CARDS_CLEAR:
        return initialState
      default:
        return state
    }
  }

  return state
}
