import { ReactComponent as DoneIcon } from "components/icon/done.svg"
import { ReactComponent as ChatIcon } from "components/icon/chat.svg"
import { List } from "components/generic/modular/List"
import { useState } from "react"
import { Faq } from "./Faq"
import { Support } from "./Support"

export const Help: React.FC = () => {
  const [popupsState, setPopupsState] = useState({
    faq: false,
    support: false,
  })

  const onClick = (id: keyof typeof popupsState) => {
    setPopupsState({
      ...(Object.keys(popupsState).reduce((acc, curr) => {
        return { ...acc, [curr]: false }
      }, {}) as { [K in keyof typeof popupsState]: boolean }),
      [id]: !popupsState[id],
    })
  }

  return (
    <div>
      <List
        listItems={[
          {
            value: "Частые вопросы",
            icon: <DoneIcon />,
            onClickEnabled: true,
            onClick: () => {
              onClick("faq")
            },
          },
          {
            value: "Написать в поддержку",
            icon: <ChatIcon />,
            onClick: () => {
              onClick("support")
            },
            onClickEnabled: true,
          },
        ]}
      />

      {/* Faq popup */}
      <div
        className="inblock-popup"
        style={{ display: popupsState.faq ? "block" : "none" }}
      >
        <Faq onClose={() => onClick("faq")} />
      </div>

      {/* Support popup */}
      <div
        className="inblock-popup"
        style={{ display: popupsState.support ? "block" : "none" }}
      >
        <Support onClose={() => onClick("support")} />
      </div>
    </div>
  )
}
