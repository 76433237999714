import { CloseableBlock } from "components/generic/modular/CloseableBlock"
import { Drawers } from "components/generic/modular/Drawer/Drawers"
import { Drawer } from "components/generic/modular/Drawer/Drawer"
import { faqGet, faqSelector } from "store/faq/FaqActions"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

export interface FaqProps {
  onClose?: () => void
}

export const Faq: React.FC<FaqProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { faq } = useSelector(faqSelector)

  useEffect(() => {
    if (!faq?.length) dispatch(faqGet())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faq])

  return (
    <CloseableBlock
      title="Частые вопросы"
      closeTitle="Назад"
      fullwidth
      onClickClose={onClose}
    >
      <Drawers>
        {faq &&
          faq.map((v, i) => {
            return (
              <Drawer title={v.title} key={i}>
                <p
                  className="color-very-dark-gray font-input-label"
                  style={{ margin: 0, fontSize: 14 }}
                >
                  {v.description}
                </p>
              </Drawer>
            )
          })}
      </Drawers>
    </CloseableBlock>
  )
}
