import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import { HistoryWithGroup } from "../utils"
import s from "./operations.module.scss"

export interface OperationItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  history: Pick<
    HistoryWithGroup,
    | "retailerName"
    | "transactionAmount"
    | "transactionFee"
    | "transactionCurrency"
    | "balanceCurrent"
    | "balanceBefore"
    | "group"
  > | null
  onClick?: () => void
  single?: boolean
}

export const OperationItem: React.FC<OperationItemProps> = ({
  history,
  onClick,
  single,
  ...props
}) => {
  return history ? (
    <div
      className={`${s["operation"]} ${single && s["operation-single"]} ${
        props.className || ""
      }`}
      onClick={() => onClick && onClick()}
    >
      <div className={s["operation-image"]}>
        <div
          className={s["operation-image__background"]}
          style={{ backgroundColor: history?.group?.groupColor }}
        />
        <div className={s["operation-image__img"]}>
          <img src={history?.group?.groupIcon} alt="" />
        </div>
      </div>
      <div className={s["operation__data"]}>
        <div className={s["operation__text-wrapper"]}>
          <p className={`${s["operation__text"]} ${s["operation__title"]}`}>
            {history.retailerName}
          </p>
          <p className={`${s["operation__text"]} ${s["operation__amt"]}`}>
            <PrettyAmount
              amount={history.transactionAmount}
              currencyCode={history.transactionCurrency}
              balance={{
                current: +history.balanceCurrent,
                before: +history.balanceBefore,
              }}
            />
          </p>
        </div>
        <div className={s["operation__text-wrapper"]}>
          <p className={`${s["operation__text"]} ${s["operation__category"]}`}>
            {history.group?.name}
          </p>

          {history.transactionFee && +history.transactionFee ? (
            <p className={`${s["operation__text"]} ${s["operation__fee"]}`}>
              комиссия{" "}
              <PrettyAmount
                amount={history.transactionFee}
                currencyCode={history.transactionCurrency}
              />
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={s["operation-blank"]}>
      <div className={s["operation-image"]}>
        <div className={s["operation-image__background"]} />
      </div>
      <div className={s["operation__data"]}>
        <div className={s["operation__text-wrapper"]}>
          <p className={`${s["operation__text"]} ${s["operation__title"]}`} />
          <p className={`${s["operation__text"]} ${s["operation__amt"]}`} />
        </div>
        <div className={s["operation__text-wrapper"]}>
          <p
            className={`${s["operation__text"]} ${s["operation__category"]}`}
          />
        </div>
      </div>
    </div>
  )
}
