import { MAX_PHONE_LEN } from "config"
import { formatCardNumber } from "./card/card"

export class Formatter {
  public static formatPhoneNumber = (phone: string): string => {
    if (!phone) return ""

    if (phone.length === MAX_PHONE_LEN) {
      const digits = phone.split("")
      digits.splice(1, 0, " ")
      digits.splice(5, 0, " ")
      digits.splice(9, 0, " ")
      digits.splice(12, 0, " ")

      return "+" + digits.join("")
    }

    return "+" + phone
  }

  public static formatPass = (pass: string) => {
    if (pass.length >= 4) return pass.slice(0, 4) + " " + pass.slice(4)
    return pass
  }

  public static formatCardExpiry = (expiry: string) => {
    return [expiry.slice(0, 2), "/", expiry.slice(2, 4)].join(" ")
  }

  public static formatPan = (pan: string) => {
    return formatCardNumber(pan)
  }
}
