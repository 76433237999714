export class Transformer {
  public static maskPan = (
    pan: string,
    options?: {
      maskWith?: string
      partsJoiner?: string
      masked?: number
      returnOnly?: number
    }
  ): string => {
    const maskWith = options?.maskWith || "·" // Replaces pan digits
    const partsJoiner = options?.partsJoiner || " " // Joins digits array parts
    const masked = options?.masked ?? 3 // Count of parts that should be masked
    const returnOnly = options?.returnOnly ?? 0 // Slicing parts starting from right

    if (!pan) return ""

    if (pan.length === 16) {
      const acc = 4
      const panDigits = [
        pan.substring(0, acc),
        pan.substring(acc, acc * 2),
        pan.substring(acc * 2, acc * 3),
        pan.substring(acc * 3),
      ]

      const maskedDigits: string[] = panDigits.map((item, i) => {
        return i + 1 > masked
          ? item
          : item.replace(/.+/gi, "".padStart(item.length, maskWith))
      })

      if (returnOnly !== 0)
        return maskedDigits
          .reverse()
          .splice(0, returnOnly)
          .reverse()
          .join(partsJoiner)

      return maskedDigits.join(partsJoiner)
    }

    return ""
  }

  public static replaceNonCyr = (str: string) => {
    return str
      .replace(
        /[\\U0401\\U0451\\U0410-\\U044f]|[&\\/\\#,_!№+()$~%.'":*?<>{}`[\]]|^\s/gim,
        ""
      )
      .replace(/\s{2,}/gi, " ")
  }

  public static replaceNonDigits = (str: string) => {
    return str.replace(/\D/gm, "")
  }
}
