import { ApiResponseError } from "store/types"
import { Mcc } from "./Mcc"
import { MccActionTypes, PayloadedMccAction } from "./MccActions"

interface MccData {
  mcc: Mcc | null
  pending: boolean
  errors: ApiResponseError[]
}

export interface MccState {
  readonly data: MccData
}

const initialState: { data: MccData } = {
  data: {
    mcc: null,
    pending: false,
    errors: [],
  },
}

export const MccReducer = (
  state: MccState = initialState,
  payloadedAction: PayloadedMccAction
): MccState => {
  if (payloadedAction.payload) {
    const {
      payload: { mcc, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case MccActionTypes.MCC_REQUEST:
        return {
          data: {
            ...stateData,
            pending: true,
            errors: [],
          },
        }
      case MccActionTypes.MCC_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            mcc,
            pending: false,
            errors: [],
          },
        }
      case MccActionTypes.MCC_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            pending: false,
            errors: errors || [],
          },
        }
      default:
        return state
    }
  }

  return state
}
