import s from "./errors-box.module.scss"
import { ApiResponseError } from "store/types"

interface ErrorsBoxProps {
  errors: string | ApiResponseError[]
}

export const ErrorsBox: React.FC<ErrorsBoxProps> = ({ errors }) => {
  return (
    <div className={s["errors-box"]}>
      {typeof errors === "string"
        ? errors
        : errors.map((e) => e.description).join(", ")}
    </div>
  )
}
