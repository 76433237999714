import { Period } from "components/history/utils"
import { _moment } from "index"
import { Moment } from "moment"
import { Time } from "utils/Time"
import { Validator } from "utils/Validator"

type DatePeriod = Omit<Period, "begin" | "end"> & { begin: Date; end: Date }

export const periodToDate = (period: Period): DatePeriod => ({
  begin: _moment(period.begin).toDate(),
  end: _moment(period.end).toDate(),
})

export const dateToPeriod = (period: DatePeriod): Period => ({
  begin: _moment(period.begin),
  end: _moment(period.end),
})

export const periodTitle = (period: Period | DatePeriod) => {
  const begin = Validator.isDateType(period.begin)
    ? _moment(period.begin)
    : (period.begin as Moment)
  const end = Validator.isDateType(period.end)
    ? _moment(period.end)
    : (period.end as Moment)

  const format = Time.formatMoment

  if (Time.isFullMonth(begin, end)) return format(begin, "MMMM")

  if (format(begin, "YYYYMMDD") === format(end, "YYYYMMDD"))
    return format(begin, "DD MMMM")

  return `${format(begin, "DD MMMM")} - ${format(end, "DD MMMM")}`
}

export const checkEnabled = (period: Period) => {
  const end = period.end.clone()
  if (Time.isFullMonth(period.begin, period.end)) {
    return !(end.add(1, "month").endOf("month") > Time.now().endOf("month"))
  } else {
    return !(end.add(1, "day") > Time.now().endOf("day"))
  }
}
