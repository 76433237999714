import PhoneInput from "react-phone-input-2"
// import "react-phone-input-2/lib/style.css"
// import s from "../input.module.scss"
import s from "components/generic/input/input.module.scss"

interface InputPhoneProps {
  onChangeValue?: (value: string) => void
  valid?: boolean
  disabled?: boolean
  value?: string
  onEnterKeyPress?: () => void
}

export const InputPhone: React.FC<InputPhoneProps> = ({
  value,
  valid = true,
  disabled = false,
  onChangeValue,
  onEnterKeyPress,
}) => {
  // https://www.npmjs.com/package/react-phone-input-2
  const country = "ru"
  const onChange = (value: string) => onChangeValue && onChangeValue(value)

  return (
    <PhoneInput
      inputClass={`${s["input"]} ${value && !valid && s["input-error"]}`}
      containerClass="input-phone"
      specialLabel=""
      placeholder="Номер телефона"
      value={value}
      disabled={disabled}
      onEnterKeyPress={() => onEnterKeyPress && onEnterKeyPress()}
      onChange={(phone) => {
        if (country === "ru") {
          if (phone) {
            switch (phone[0]) {
              case "8":
                onChange("7" + phone.substring(1))
                break
              case "9":
                onChange("79" + phone.substring(1))
                break
              default:
                onChange(phone)
                break
            }
          }
        }
      }}
    />
  )
}
