import s from "./button-icon.module.scss"

interface ButtonIconProps {
  text: string
  addText?: string
  buttonSize?: "default" | "small"
  buttonStyle?: "default" | "lightgray"
  activeClass?: "default" | "all-blue" | "bg-blue"
  disabled?: boolean
  onClick?: () => void
  icon?: JSX.Element
  active?: boolean
}

export const ButtonIcon = ({
  text,
  addText,
  disabled,
  onClick,
  icon,
  buttonSize = "default",
  buttonStyle = "default",
  activeClass = "default",
  active,
}: ButtonIconProps): JSX.Element => {
  const onButtonClick = () => {
    if (typeof onClick === "function" && !disabled) onClick()
  }

  // TODO -> Refactor styling
  return (
    <button
      className={`${s["button-icon"]} 
      ${s[`button-icon-size-${buttonSize}`]}
      ${s[`button-icon-style-${buttonStyle}`]}
      
      ${active ? s[`button-icon-active`] : ""}
      ${active ? s[`button-icon-style-${buttonStyle}-active`] : ""}

      ${active ? s[`button-icon-active-${activeClass}`] : ""}
      `}
      onClick={
        !disabled
          ? () => {
              onButtonClick()
            }
          : undefined
      }
      disabled={disabled}
    >
      {icon ? <div className={s["button-icon__icon"]}>{icon}</div> : ""}
      <p className={s["button-icon-text"]}>
        <span className={s["button-icon-text__main"]}>{text}</span>
        {addText && (
          <span className={s["button-icon-text__secondary"]}>{addText}</span>
        )}
      </p>
    </button>
  )
}
