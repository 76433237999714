import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "store/hooks"
import { OtpConfirmView } from "./OtpConfirmView"
import { Validator } from "utils/Validator"
import { CookieManager } from "utils/CookieManager"
import { getAuthToken } from "store/auth/AuthActions"
import { useNavigate } from "react-router-dom"
import { OTP_LEN_DEFAULT, routes } from "config"
import { SigninState } from "store/forms/signin/SigninReducer"
import { SignupState } from "store/forms/signup/SignupReducer"

interface OtpConfirmProps {
  title: string
  state: SigninState | SignupState
  onSubmit: (data: { phone: string; otp?: string; at: string }) => void
  onSuccessSubmit: () => void
}

export const OtpConfirm: React.FC<OtpConfirmProps> = ({
  title,
  state,
  onSubmit,
  onSuccessSubmit,
  children,
}) => {
  document.title = title
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authState = useAppSelector((state) => state.auth.data)
  const { token, wasOtpSent, errors } = state.data

  const [phone, setPhone] = useState({ value: "", valid: false })
  const [otp, setOtp] = useState({ value: "", valid: false })
  const [step, setStep] = useState(0)
  const [countdown, setCountdown] = useState<null | number>(null)

  const onChangePhone = (phone: string) => {
    setPhone({ value: phone, valid: Validator.isPhone(phone) })
  }

  const onChangeOtp = (otp: string) => {
    if (otp.length <= OTP_LEN_DEFAULT)
      setOtp({ value: otp, valid: Validator.isOtpCode(otp) })
  }

  const onResendOtp = () => {
    if (step === 1 && phone.valid) {
      dispatch(onSubmit({ phone: phone.value, at: authState.token }))
    }
  }

  const onClickForward = () => {
    if (step === 0 && phone.valid) {
      dispatch(onSubmit({ phone: phone.value, at: authState.token }))
    }
    if (step === 1 && phone.valid && otp.valid) {
      dispatch(
        onSubmit({ phone: phone.value, otp: otp.value, at: authState.token })
      )
      setOtp({ value: "", valid: false })
    }
  }

  const onClickBackward = () => {
    if (step === 1) setStep(step - 1)
    // check bug here (OTP_REQUIRED) if step is 0 when return from step 1
  }

  const countdownTimer = () => {
    let time = 10
    const timerId = setInterval(() => {
      setCountdown(--time)
    }, time * 100)

    setTimeout(() => {
      clearInterval(timerId)
    }, time * 1000)
  }

  useEffect(() => {
    if (!authState.token) dispatch(getAuthToken())
  }, [authState.token, dispatch])

  useEffect(() => {
    if (wasOtpSent) {
      setStep(1)
      // countdownTimer() // Need to to cleanup
    }
    if (token) {
      dispatch(onSuccessSubmit())
      CookieManager.setTokenCookie(token)
      navigate(routes.personal.path)
    }
  }, [token, wasOtpSent, errors, dispatch, onSuccessSubmit, navigate])

  return (
    <OtpConfirmView
      title={title}
      phone={phone}
      otp={otp}
      onChangePhone={onChangePhone}
      onChangeOtp={onChangeOtp}
      onClickForward={onClickForward}
      onClickBackward={onClickBackward}
      onResendOtp={onResendOtp}
      errors={errors}
      step={step}
      countdown={countdown}
    >
      {children}
    </OtpConfirmView>
  )
}
