import { ButtonIcon } from "components/generic/button/button-icon/ButtonIcon"
import { routes } from "config"
import s from "./card-nav.module.scss"
import { ReactComponent as FireIcon } from "components/icon/fire.svg"
import { CustomLink } from "components/custom-link/CustomLink"
import { CardDetailsContainer } from "components/card/card-details/CardDetailsContainer"
import { TransferLimitContainer } from "components/card/transfer-limit/TransferLimitContainer"
import { CardChanger } from "components/card/card-changer/CardChanger"

interface CardNavProps {}

export const CardNav: React.FC<CardNavProps> = () => {
  return (
    <aside className={s["card-nav"]}>
      <div className={s["card-nav__item"]}>
        <CardChanger size="big" />
      </div>
      <div className={s["card-nav__item"]}>
        <TransferLimitContainer />
      </div>
      <div className={s["card-nav__item"]}>
        <CardDetailsContainer />
      </div>

      {/* Card is index */}
      <CustomLink className={s["card-nav__item"]} to={routes.card.path}>
        <ButtonIcon
          text="V+ Card"
          addText="Остаток до 60 000 ₽ и переводы"
          icon={<FireIcon />}
          activeClass="bg-blue"
        />
      </CustomLink>
    </aside>
  )
}
