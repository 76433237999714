import { AppState } from "../store"
import { Dispatch } from "redux"
import { deviceSizes, Settings } from "./Settings"

export enum SettingsActionTypes {
  WINDOW_RESIZE = "WINDOW_RESIZE",
}

export interface PayloadedSettingsAction {
  type: SettingsActionTypes
  payload: Settings
}

export const settingsSelector = (state: AppState) =>
  state.settings.data.settings

export const resizeWindow =
  (windowWidth: number) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    const device = ((): keyof typeof deviceSizes => {
      if (windowWidth >= deviceSizes.DESKTOP) return "DESKTOP"
      if (windowWidth < deviceSizes.DESKTOP && windowWidth > deviceSizes.MOBILE)
        return "TABLET_SMALL"
      if (windowWidth < deviceSizes.MOBILE) return "MOBILE"

      return "DESKTOP"
    })()

    dispatch<PayloadedSettingsAction>({
      type: SettingsActionTypes.WINDOW_RESIZE,
      payload: { windowSize: device },
    })
  }
