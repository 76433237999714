import s from "./footer.module.scss"

interface FooterProps {
  children?: React.ReactNode
}

export const PublicFooter: React.FC<FooterProps> = ({ children }) => {
  return (
    <footer>
      <div className={`container ${s.footer}`}></div>
    </footer>
  )
}
