import { ApiResponseError } from "store/types"
import { Validator } from "utils/Validator"
import { Invite } from "./Invite"
import { InviteActionTypes, PayloadedInviteAction } from "./InviteActions"

interface InviteForm {
  values: Invite
  formValid: boolean
  errors: ApiResponseError[]
}

export interface InviteFormState {
  readonly data: InviteForm
}

export const initialState: { data: InviteForm } = {
  data: {
    values: {
      partnerUrl: { value: "", pending: false },
      email: { value: "", valid: false, pending: false },
      sentTo: { value: "" },
    },
    formValid: false,
    errors: [],
  },
}

export const InviteFormReducer = (
  state: InviteFormState = initialState,
  payloadedAction: PayloadedInviteAction
): InviteFormState => {
  if (payloadedAction.payload) {
    const {
      payload: { data, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data
    const { partnerUrl, email } = stateData.values

    switch (payloadedAction.type) {
      case InviteActionTypes.INVITE_PARTNER_LINK_REQUEST:
        return {
          data: {
            ...stateData,
            values: {
              ...stateData.values,
              partnerUrl: {
                ...partnerUrl,
                pending: true,
              },
            },
          },
        }
      case InviteActionTypes.INVITE_PARTNER_LINK_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            values: {
              ...stateData.values,
              partnerUrl: { value: data.partnerUrl.value, pending: false },
            },
          },
        }
      case InviteActionTypes.INVITE_PARTNER_LINK_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            values: {
              ...stateData.values,
              partnerUrl: {
                ...partnerUrl,
                pending: false,
              },
            },
            errors,
          },
        }
      case InviteActionTypes.INVITE_SEND_INVITE_REQUEST:
        return {
          data: {
            ...stateData,
            values: {
              ...stateData.values,
              email: { ...email, pending: true },
            },
          },
        }
      case InviteActionTypes.INVITE_SEND_INVITE_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            values: {
              ...stateData.values,
              email: { ...email, pending: false },
              sentTo: { value: `${data?.sentTo?.value || ""}` },
            },
          },
        }
      case InviteActionTypes.INVITE_SEND_INVITE_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            values: {
              ...stateData.values,
              email: { ...email, pending: false },
            },
            errors,
          },
        }
      case InviteActionTypes.INVITE_CLEAR_FORM:
        return {
          ...initialState,
          data: {
            ...initialState.data,
            values: {
              ...initialState.data.values,
              partnerUrl: initialState.data.values.partnerUrl,
            },
            errors: [],
          },
        }
      default:
        return state
    }
  }

  return state
}
