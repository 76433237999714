import {
  InputText,
  InputTextProps,
} from "components/generic/input/input-text/inputText"
import { Clipboarder } from "components/generic/clipboarder/Clipboarder"
import s from "../input.module.scss"

export const InputCopy: React.FC<InputTextProps> = ({ ...props }) => {
  return (
    <div className={s["input-clipboarder"]}>
      <div className={s["input-copy"]}>
        <InputText {...props} disabled />
      </div>
      {props.value && (
        <div className={s["clipboarder-wrapper"]}>
          <div className={s["clipboarder"]}>
            <Clipboarder text={(props.value as string) || ""} />
          </div>
        </div>
      )}
    </div>
  )
}
