import { ApiResponseError } from "store/types"
import { SavedCard } from "./SavedCard"
import {
  SavedCardActionTypes,
  PayloadedSavedCardAction,
} from "./SavedCardActions"

interface SavedCardData {
  data: SavedCard[] | null
  pending: boolean
  error?: ApiResponseError[]
}

export interface SavedCardState {
  readonly data: SavedCardData
}

const initialState: { data: SavedCardData } = {
  data: {
    pending: false,
    error: [],
    data: null,
  },
}

export const SavedCardReducer = (
  state: SavedCardState = initialState,
  payloadedAction: PayloadedSavedCardAction
): SavedCardState => {
  if (payloadedAction.payload) {
    const {
      payload: { data, error },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (error) console.log(error)
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case SavedCardActionTypes.SAVED_CARD_REQUEST:
        return {
          data: {
            ...stateData,
            error: [],
            pending: true,
          },
        }
      case SavedCardActionTypes.SAVED_CARD_SUCCESS:
        return {
          data: {
            ...stateData,
            error: [],
            pending: false,
            data,
          },
        }
      case SavedCardActionTypes.SAVED_CARD_FAILURE:
        return {
          data: {
            ...stateData,
            error: error,
            pending: false,
          },
        }
      default:
        return state
    }
  }

  return state
}
