import { AppState } from "../store"
import { Dispatch } from "redux"
import { PhoneTransfers } from "./LatestPhoneTransfers"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum LatestPhoneTransfersActionTypes {
  LATEST_PHONE_TRANSFERS_REQUEST = "LATEST_PHONE_TRANSFERS_REQUEST",
  LATEST_PHONE_TRANSFERS_FAILURE = "LATEST_PHONE_TRANSFERS_FAILURE",
  LATEST_PHONE_TRANSFERS_SUCCESS = "LATEST_PHONE_TRANSFERS_SUCCESS",
}

export interface PayloadedLatestPhoneTransfersAction {
  type: LatestPhoneTransfersActionTypes
  payload: {
    data: PhoneTransfers[]
    error?: ApiResponseError[]
  }
}

export const getLatestPhoneTransfers =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: LatestPhoneTransfersActionTypes.LATEST_PHONE_TRANSFERS_REQUEST,
      payload: {},
    })

    fetcher({ url: "/phone", method: "GET" }).then(
      (res: ApiResponse<{ latestPhoneTransfers: PhoneTransfers[] }>) => {
        if (res.success) {
          return dispatch<PayloadedLatestPhoneTransfersAction>({
            type: LatestPhoneTransfersActionTypes.LATEST_PHONE_TRANSFERS_SUCCESS,
            payload: { data: res.data.latestPhoneTransfers },
          })
        } else {
          return dispatch({
            type: LatestPhoneTransfersActionTypes.LATEST_PHONE_TRANSFERS_FAILURE,
            payload: { data: [], error: res.errors },
          })
        }
      }
    )
  }
