import {Theme} from "./Theme";
import {PayloadedThemeAction} from "./ThemeActions";

export interface ThemeState {
    readonly data: Theme
}

export const initialThemeState: {data: Theme} = {
    data: {
        colorScheme: "light",
        bgColor: "0000"
    }
}

export const ThemeReducer = (state: ThemeState = initialThemeState, payloadedAction: PayloadedThemeAction): ThemeState => {
    if (payloadedAction.payload) {
        const stateData = state.data;

        if (payloadedAction.type === "THEME_PARAMS_RECEIVED")
            return {
                data: payloadedAction.payload.data
            }
    }

    return state
}