import s from "./avatar-image.module.scss"
import avatarDefault from "./avatar-default.svg"
import { Validator } from "utils/Validator"

export interface Avatar {
  avatarImage: string | null
  backgroundColor: string | null
}

export interface AvatarProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  avatar: Avatar
  withBorder?: boolean
  borderColor?: string
  size?: "small" | "medium" | "big"
}

export const AvatarImage: React.FC<AvatarProps> = ({
  avatar,
  withBorder = false,
  borderColor,
  size = "medium",
  ...props
}) => {
  const bg = avatar?.backgroundColor
  const image = avatar?.avatarImage

  const img = (() => {
    if (image === null) return null
    if (Validator.isImage(image)) return image
  })()

  const styles: React.CSSProperties = {}
  if (!withBorder) {
    styles.border = "none"
  }
  if (withBorder && borderColor) styles.borderColor = borderColor

  return (
    <div
      {...props}
      className={`${s["avatar-wrapper"]} ${s[`avatar-wrapper-${size}`]} ${
        props.className || ""
      }`}
      style={styles}
    >
      <div
        className={`${s["avatar"]} ${!withBorder ? s["avatar-fullwidth"] : ""}`}
        style={{
          backgroundColor: bg ? bg : "transparent",
        }}
      >
        {image && Validator.isEmoji(image) && (
          <div className={s["avatar__emoji"]}>{image}</div>
        )}
        {!image && img && (
          <div className={s["avatar__img"]}>
            <img src={img} alt="avatar" />
          </div>
        )}
        {!image && !img && !bg && (
          <div className={s["avatar__img"]}>
            <img src={avatarDefault} alt="avatar" />
          </div>
        )}
      </div>
    </div>
  )
}
