import s from "./clipboarder.module.scss"
import { ReactComponent as CopyIcon } from "components/icon/copy.svg"
import { ReactComponent as DoneIcon } from "components/icon/done.svg"
import { useState } from "react"

interface ClipboarderProps {
  text: string | undefined
}

export const Clipboarder: React.FC<ClipboarderProps> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false)
  const TIMEOUT = 1500
  const copy = () => {
    if (text) {
      navigator.clipboard.writeText(text)

      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, TIMEOUT)
    }
  }

  return (
    <div className={s["clipboarder"]} onClick={copy}>
      {!isCopied ? <CopyIcon /> : <DoneIcon />}
    </div>
  )
}
