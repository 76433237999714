import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { cardsSelector } from "store/cards/cards/CardsActions"
import { cvvClear, cvvGet, cvvSelector } from "store/cards/cvv/CvvActions"
import { useAppSelector } from "store/hooks"
import { Formatter } from "utils/Formatter"
import { Transformer } from "utils/Transformer"
import { CardDetailsView } from "./CardDetailsView"

interface CardDetailsProps {}

export const CardDetailsContainer: React.FC<CardDetailsProps> = () => {
  const dispatch = useDispatch()
  const { primaryCard } = useAppSelector(cardsSelector)
  const { pan, expDate } = primaryCard || {}
  const { data, pending } = useAppSelector(cvvSelector)

  const initialCard = {
    pan: Transformer.maskPan(pan || ""),
    date: "·· / ··",
    cvv: "···",
  }

  const [card, setCard] = useState(initialCard)
  const [isDataHidden, setIsDataHidden] = useState(true)

  const onShowData = () => {
    const isHidden = !isDataHidden
    setIsDataHidden(isHidden)

    if (!isHidden) dispatch(cvvGet())
    else dispatch(cvvClear())
  }

  useEffect(() => {
    if (pan !== card.pan) {
      setCard({ ...card, pan: Transformer.maskPan(pan || "") })
    }
    if (data?.cvv) {
      setCard({
        pan: Transformer.maskPan(pan || "", { masked: 0 }),
        date: Formatter.formatCardExpiry(expDate || ""),
        cvv: data.cvv,
      })
    } else {
      setCard(initialCard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pan, data?.cvv])

  return (
    <CardDetailsView
      card={card}
      isDataHidden={isDataHidden}
      onToggleShow={onShowData}
      onClickCopy={(str: string) => {
        navigator.clipboard.writeText(str)
      }}
    />
  )
}
