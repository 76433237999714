import { JSEncrypt } from "jsencrypt"

export class Cryptor {
  private cryptor = new JSEncrypt({
    default_key_size: "2048",
  })

  constructor(publicKey: string) {
    this.cryptor.setPublicKey(publicKey)
  }

  public encrypt = (message: string) => this.cryptor.encrypt(message)
}
