import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import a from "./analytics-head.module.scss"
import { ReactComponent as ArrowIcon } from "components/icon/arrow-next.svg"
import { ActionButton } from "components/generic/button/action-button/ActionButton"
import { ReactComponent as ChartDonutIcon } from "components/icon/chart-donut.svg"
import { ReactComponent as ChartBarIcon } from "components/icon/chart-bar.svg"
import { ActiveChart } from "../charts/Charts"

export interface AnalyticsHeadProps {
  total: number
  activeChart: ActiveChart
  onSwitchChartType: () => void
  onHideData: () => void
  isDataHidden: boolean
}

export const AnalyticsHead: React.FC<AnalyticsHeadProps> = ({
  total,
  activeChart,
  onSwitchChartType,
  onHideData,
  isDataHidden,
  children,
}) => {
  const withData = total > 0
  return (
    <div className={a["head"]}>
      <div className={a["data-row"]}>
        <div className={a["data-row__amount"]}>
          {withData ? (
            <PrettyAmount
              amount={total}
              currencyCode={"643"}
              shouldHideKop={true}
            />
          ) : (
            "Нет трат"
          )}
        </div>
        <div className={a["data-row__children"]}>{children}</div>
      </div>

      <div
        className={a["buttons"]}
        style={{ visibility: withData ? "visible" : "hidden" }}
      >
        {!isDataHidden && (
          <div className={a["button"]}>
            <ActionButton
              icon={
                activeChart === "bar" ? <ChartBarIcon /> : <ChartDonutIcon />
              }
              onClick={onSwitchChartType}
            />
          </div>
        )}
        <div className={a["button"]}>
          <ActionButton
            icon={
              <span
                style={{
                  transform: !isDataHidden ? "rotate(-90deg)" : "rotate(90deg)",
                  display: "inline-block",
                }}
              >
                <ArrowIcon />
              </span>
            }
            onClick={onHideData}
          />
        </div>
      </div>
    </div>
  )
}
