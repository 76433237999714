import s from "../input.module.scss"
import { CardType, getCardTypeByValue } from "utils/card/card"
import { useState } from "react"
import { Formatter } from "utils/Formatter"
import { Transformer } from "utils/Transformer"

export interface PanInputData {
  value: string
  formattedValue: string
  cardType: CardType | null
  valid: boolean
}

type InputElement = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface InputPanProps extends Omit<InputElement, "onChange" | "ref"> {
  value: PanInputData["value"]
  onChange?: (data: PanInputData) => void
  innerRef?: React.LegacyRef<HTMLInputElement>
}

export const InputPan: React.FC<InputPanProps> = ({
  value,
  onChange,
  placeholder = "Номер карты",
  innerRef,
  ...props
}) => {
  const [pan, setPan] = useState(Formatter.formatPan(value))

  const onChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const v = Transformer.replaceNonDigits(e.currentTarget.value)
    const formattedPan = Formatter.formatPan(v)
    const cardType = getCardTypeByValue(formattedPan)
    const maxPan = cardType.maxCardNumberLength

    if (!(formattedPan.length > maxPan)) {
      setPan(v)
      onChange &&
        onChange({
          value: Transformer.replaceNonDigits(v),
          formattedValue: Formatter.formatPan(v),
          cardType,
          valid: cardType.maxCardNumberLength === formattedPan.length,
        })
    }
  }

  return (
    <input
      type="text"
      {...props}
      value={pan}
      placeholder={placeholder}
      onChange={onChangeValue}
      onFocus={(e) => {
        setPan(Transformer.replaceNonDigits(pan))
      }}
      onBlur={() => setPan(Formatter.formatPan(pan))}
      className={`${s["input"]} ${props.className || ""}`}
      ref={innerRef}
    />
  )
}
