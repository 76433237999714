import { ApiResponseError } from "store/types"
import { Faq } from "./Faq"
import { FaqActionTypes, PayloadedFaqAction } from "./FaqActions"

interface FaqData {
  faq: Faq[] | null
  pending: boolean
  errors: ApiResponseError[]
}

export interface HelpFormState {
  readonly data: FaqData
}

const initialState: { data: FaqData } = {
  data: {
    faq: [],
    pending: false,
    errors: [],
  },
}

export const FaqReducer = (
  state: HelpFormState = initialState,
  payloadedAction: PayloadedFaqAction
): HelpFormState => {
  if (payloadedAction.payload) {
    const {
      payload: { faq, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case FaqActionTypes.FAQ_REQUEST:
        return {
          data: {
            ...stateData,
            pending: true,
            errors: [],
          },
        }
      case FaqActionTypes.FAQ_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            faq,
            pending: false,
            errors: [],
          },
        }
      case FaqActionTypes.FAQ_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            pending: false,
            errors: errors || [],
          },
        }
      default:
        return state
    }
  }

  return state
}
