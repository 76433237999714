import { ReactComponent as DoneStarIcon } from "components/icon/done-star.svg"
import { ErrorsBox } from "components/generic/errors-container/ErrorsBox"
import { InputText } from "components/generic/input/input-text/inputText"
import { Button } from "components/generic/button/button-default/Button"
import { userSelector, userUpdate } from "store/user/UserActions"
import { Popup } from "components/generic/popup/Popup"
import { POPUPS_DISSAPEAR_TIMEOUT } from "config"
import { useAppSelector } from "store/hooks"
import { useEffect, useState } from "react"
import { Validator } from "utils/Validator"
import { useDispatch } from "react-redux"

interface EmailChangerContainerProps {
  onSuccessSubmit?: () => void
}

export const EmailChanger: React.FC<EmailChangerContainerProps> = ({
  onSuccessSubmit,
}) => {
  const dispatch = useDispatch()
  const { qUser, errors, pending } = useAppSelector(userSelector)
  const _errors = errors || []

  const [isPopupShown, setIsPopupShown] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const [data, setData] = useState({
    email: { value: "", valid: false },
  })

  const onChangeForm = (id: keyof typeof data, val: string) => {
    let valid = false

    if (id === "email" && Validator.isEmail(val)) {
      valid = true
    }

    setData({
      ...data,
      [id]: { value: val, valid },
    })
  }

  const closePopup = () => {
    setIsPopupShown(false)
    setRequestSent(false)
    onSuccessSubmit && onSuccessSubmit()
  }

  useEffect(() => {
    if (!pending) {
      if (data.email.value === "" && qUser?.email)
        setData({
          ...data,
          email: { ...data.email, value: qUser?.email || "" },
        })

      if (!_errors?.length && requestSent && qUser?.email) {
        setIsPopupShown(true)

        setTimeout(() => {
          closePopup()
        }, POPUPS_DISSAPEAR_TIMEOUT)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qUser?.email, data.email.value, requestSent, _errors, pending])

  const onSubmit = () => {
    if (data.email.valid) {
      setRequestSent(true)

      dispatch(
        userUpdate({
          email: data.email.value,
        })
      )

      return
    }
  }

  return (
    <>
      <div className="rows">
        <div className="row">
          <InputText
            value={data.email.value}
            placeholder="Адрес эл. почты"
            onChange={(e) => onChangeForm("email", e.currentTarget.value)}
          />
          <p className="small-note">
            На эту почту будут приходить данные и ответы от поддержки
          </p>

          <ErrorsBox errors={_errors} />
        </div>
        <div className="row">
          <Button
            buttonText="Подтвердить"
            disabled={!data.email.valid}
            onClick={onSubmit}
          />
        </div>
      </div>

      <Popup onClosePopup={closePopup} isShown={isPopupShown}>
        <div style={{ textAlign: "center" }}>
          <div className="popup-icon">
            <DoneStarIcon />
          </div>
          <p className="popup-text">
            Адрес электронной почты
            <br />
            изменен на {qUser?.email}
          </p>
        </div>
      </Popup>
    </>
  )
}
