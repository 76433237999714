import { AppState } from "../../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum InviteActionTypes {
  INVITE_PARTNER_LINK_REQUEST = "INVITE_PARTNER_LINK_REQUEST",
  INVITE_PARTNER_LINK_REQUEST_SUCCESS = "INVITE_PARTNER_LINK_REQUEST_SUCCESS",
  INVITE_PARTNER_LINK_REQUEST_FAILURE = "INVITE_PARTNER_LINK_REQUEST_FAILURE",
  INVITE_SEND_INVITE_REQUEST = "INVITE_SEND_INVITE_REQUEST",
  INVITE_SEND_INVITE_REQUEST_SUCCESS = "INVITE_SEND_INVITE_REQUEST_SUCCESS",
  INVITE_SEND_INVITE_REQUEST_FAILURE = "INVITE_SEND_INVITE_REQUEST_FAILURE",
  INVITE_CLEAR_FORM = "INVITE_CLEAR_FORM",
}

export interface PayloadedInviteAction {
  type: InviteActionTypes
  payload: {
    data: {
      partnerUrl: { value: string }
      email: { value: string }
      sentTo?: { value: string }
    }
    errors: ApiResponseError[]
  }
}


export const invitePartnerUrl =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: InviteActionTypes.INVITE_PARTNER_LINK_REQUEST,
      payload: {},
    })

    fetcher({ url: "/invite/partnerUrl/", method: "GET" }).then(
      (res: ApiResponse<{ partnerUrl: string }>) => {
        if (res.success) {
          dispatch({
            type: InviteActionTypes.INVITE_PARTNER_LINK_REQUEST_SUCCESS,
            payload: { data: { partnerUrl: { value: res.data.partnerUrl } } },
          })
        } else {
          dispatch({
            type: InviteActionTypes.INVITE_PARTNER_LINK_REQUEST_FAILURE,
            payload: {
              errors: res.errors,
            },
          })
        }
      }
    )
  }

export const inviteSendInvite =
  (email: string) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: InviteActionTypes.INVITE_SEND_INVITE_REQUEST,
      payload: {},
    })

    dispatch({
      type: InviteActionTypes.INVITE_SEND_INVITE_REQUEST_SUCCESS,
      payload: {
        data: { sentTo: { value: email } },
      },
    })
    // if (getState().inviteForm.inviteForm.formValid)
    //   fetcher({
    //     url: "/invite/sendInvite/",
    //     method: "POST",
    //     body: { email: getState().inviteForm.inviteForm.data.email.value },
    //   }).then((res: ApiResponse<{ sentTo: string }>) => {
    //     if (res.success) {
    //       dispatch({
    //         type: InviteActionTypes.INVITE_SEND_INVITE_REQUEST_SUCCESS,
    //         payload: {
    //           data: { sentTo: { value: res.data.sentTo } },
    //         },
    //       })
    //     } else {
    //       dispatch({
    //         type: InviteActionTypes.INVITE_SEND_INVITE_REQUEST_FAILURE,
    //         payload: {
    //           errors: res.errors,
    //         },
    //       })
    //     }
    //   })
  }

export const inviteClearForm =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: InviteActionTypes.INVITE_CLEAR_FORM,
      payload: {},
    })
  }
