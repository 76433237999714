import { ButtonIcon } from "components/generic/button/button-icon/ButtonIcon"
import { Tabs } from "components/generic/tabs/Tabs"
import { TransferByPhone } from "./TransferByPhone"
import { TransferByCard } from "./TransferByCard"
import {TransferByUsername} from "./TransferByUsername";

export const Transfer: React.FC = () => {
  return (
    <Tabs
      tabList={[
        <ButtonIcon
          text="В чате"
          buttonSize="small"
          buttonStyle="lightgray"
          activeClass="all-blue"
        />,
        <ButtonIcon
          text="По телефону"
          buttonSize="small"
          buttonStyle="lightgray"
          activeClass="all-blue"
        />,
        <ButtonIcon
          text="По карте"
          buttonSize="small"
          buttonStyle="lightgray"
          activeClass="all-blue"
        />
      ]}
      tabPanel={[ <TransferByUsername />, <TransferByPhone />, <TransferByCard />]}
    />
  )
}
