import { Button } from "components/generic/button/button-default/Button"
import s from "./profile-status.module.scss"

interface ProfileStatusViewProps {
  text?: string | JSX.Element
  buttonText?: string | JSX.Element
}

export const ProfileStatusView: React.FC<ProfileStatusViewProps> = ({
  text,
  buttonText,
}) => {
  return (
    <div className={s["profile-status"]}>
      <div className={s["text"]}>{text}</div>
      {buttonText && typeof buttonText === "string" ? (
        <Button buttonText={buttonText} buttonStyle="blue-dark" />
      ) : (
        <span>{buttonText}</span>
      )}
    </div>
  )
}
