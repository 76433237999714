import { InputText } from "components/generic/input/input-text/inputText"
import { PhoneTransfers } from "store/latest-phone-transfers/LatestPhoneTransfers"
import s from "./phone-list.module.scss"
import { CloseableBlock } from "components/generic/modular/CloseableBlock"
import { LabelPanel } from "components/generic/modular/LabelPanel"

interface ModifiedPhoneTransfer extends PhoneTransfers {
  lastNameShortened: string
}

interface PhoneListViewProps {
  pending: boolean

  previewData: ModifiedPhoneTransfer[]
  fullData: ModifiedPhoneTransfer[]

  opened: boolean
  setOpened: () => void

  onClick: (phone: string) => void

  searchValue: string
  onChangeSearch?: (e: React.FormEvent<HTMLInputElement>) => void
}

export const PhoneListView: React.FC<PhoneListViewProps> = ({
  pending,
  previewData,
  fullData,
  opened,
  setOpened,
  onClick,
  searchValue,
  onChangeSearch,
}) => {
  return (
    <div className={s["phone-list"]}>
      <LabelPanel
        title="Недавние"
        button={{ title: "Посмотреть", onClickButtonTitle: () => setOpened() }}
      />
      <div className={s["suggested-area-wrapper"]}>
        <div
          className={`${s["suggested-area"]} ${s["suggested-area-horizotal-scroll-mobile"]}`}
        >
          {previewData.map((i) => (
            <div
              className={s["suggested"]}
              key={i.phone}
              onClick={() => {
                onClick(i.phone)
              }}
            >
              <div className={s["suggested__img"]}>
                <img
                  src={i.avatarPath || "/assets/images/default-avatar-q.png"}
                  alt=""
                />
              </div>
              <p className={s["suggested__name"]}>
                {i.name}
                {i.lastNameShortened && " " + i.lastNameShortened}
              </p>
            </div>
          ))}
        </div>
      </div>
      {opened && (
        <div className={s["selection"]}>
          <CloseableBlock
            title="Недавние"
            closeTitle="Назад"
            onClickClose={setOpened}
          >
            <div className="">
              <InputText
                placeholder="Поиск"
                onChange={onChangeSearch}
                value={searchValue}
              />
            </div>
            <div
              className={`${s["suggested-area"]} ${s["suggested-area-vertical"]}`}
            >
              {fullData.length ? (
                fullData.map((i) => (
                  <div
                    className={s["suggested"]}
                    key={i.phone}
                    onClick={() => {
                      onClick(i.phone)
                      setOpened()
                    }}
                  >
                    <div className={s["suggested__img"]}>
                      <img
                        src={
                          i.avatarPath || "/assets/images/default-avatar-q.png"
                        }
                        alt=""
                      />
                    </div>
                    <p className={s["suggested__name"]}>
                      {i.name}
                      {i.lastNameShortened && " " + i.lastNameShortened}
                    </p>
                  </div>
                ))
              ) : (
                <div className={s["not-found"]}>Ничего не найдено</div>
              )}
            </div>
          </CloseableBlock>
        </div>
      )}
    </div>
  )
}
