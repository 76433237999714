import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination } from "swiper"

interface SlickSliderProps {
  slides: JSX.Element[]
  onChangeSwiper: (index: number) => void
}
export const SwiperCarousel = ({
  slides,
  onChangeSwiper,
}: SlickSliderProps): JSX.Element => {
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="swiper-own swiper-own-last-bullet-plus"
        onSwiper={(swiper) => onChangeSwiper(swiper.activeIndex)}
        onSlideChange={(swiper) => {
          onChangeSwiper(swiper.realIndex)
        }}
      >
        {slides.map((s, i) => {
          return <SwiperSlide key={i}>{s}</SwiperSlide>
        })}
      </Swiper>
    </>
  )
}
