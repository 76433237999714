import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import s from "./operations.module.scss"
import { ReactComponent as LocationIcon } from "components/icon/location.svg"
import { CardChanger } from "components/card/card-changer/CardChanger"
import { HistoryWithGroup } from "../utils"

export interface OperationsViewProps {
  history: HistoryWithGroup
}

export const OperationPopupContent: React.FC<OperationsViewProps> = ({
  history,
}) => {
  const {
    retailerName,
    retailerMcc,
    transactionAmount,
    transactionFee,
    transactionCurrency,
    transactionDate,
    balanceCurrent,
    balanceBefore,
    terminalCity,
    terminalLocation,
    group,
  } = history

  return (
    <div className={s["operation-popup"]}>
      <div className={s["operation-popup__row"]}>
        <div className={s["info"]}>
          <div className={s["info__row"]}>
            <p className={s["info__date"]}>{transactionDate}</p>
          </div>
          <div className={s["info__row"]}>
            <div className={s["info-content"]}>
              <div className={s["operation-image"]}>
                <div
                  className={s["operation-image__background"]}
                  style={{ backgroundColor: group?.groupColor }}
                />
                <div className={s["operation-image__img"]}>
                  <img src={group?.groupIcon} alt="" />
                </div>
              </div>

              <div className={s["info-content__data"]}>
                <p className={s["info-content__owner"]}>{retailerName}</p>
                <p className={s["info-content__mcc"]}>
                  <span>{group?.name}</span>
                  <span>{`MCC ${retailerMcc}`}</span>
                </p>
              </div>
            </div>
          </div>
          <div className={s["info__row"]}>
            <p className={s["info__amt"]}>
              <PrettyAmount
                amount={transactionAmount}
                currencyCode={transactionCurrency}
                balance={{
                  current: +balanceCurrent,
                  before: +balanceBefore,
                }}
              />
            </p>
            {transactionFee && +transactionFee ? (
              <p className={`${s["operation__text"]} ${s["operation__fee"]}`}>
                комиссия{" "}
                <PrettyAmount
                  amount={transactionFee}
                  currencyCode={transactionCurrency}
                />
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={s["operation-popup__row"]}>
        <p className={s["buy-from"]}>Покупка с карты</p>
        <CardChanger />
      </div>
      <div className={s["operation-popup__row"]}>
        <div className={s["location"]}>
          <div className={s["location__image"]}>
            <LocationIcon />
          </div>
          <div className={s["location__text"]}>
            <p className={s["location__city"]}>{terminalCity}</p>
            <p className={s["location__location"]}>{terminalLocation}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
