import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import { useEffect, useState } from "react"
import s from "./donut-chart.module.scss"

export interface DonutChartProps {
  donutChartData: {
    name: string
    background: string
    active: boolean
    amount: number
    currency: string
  }[]
}

export const DonutChart: React.FC<DonutChartProps> = ({ donutChartData }) => {
  const props = {
    cx: "21", // Circle center X
    cy: "21", // Circle center Y
    r: "15.91549430918954", // Position from centre,
  }

  const onlyActive = donutChartData.filter((item) => item.active)
  const max = onlyActive.reduce((acc, curr) => acc + curr.amount, 0)
  const title = onlyActive.length === 1 ? onlyActive[0].name : "Расходы"
  const defaultData = {
    title,
    amount: max,
    percent: "100%",
  }

  const [totalData, setTotalData] = useState(defaultData)
  let dashOffsetNext = 0

  useEffect(() => {
    setTotalData({
      title,
      percent: "100%",
      amount: max,
    })
  }, [title, max])

  return (
    <div className={s["wrapper"]}>
      <div className={s["chart"]}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 42 42"
          className={s["donut"]}
        >
          <circle className={s["donut__ring"]} {...props} />

          {onlyActive.map((legend, i) => {
            const perc = +((legend.amount / max) * 100).toFixed(2)

            return (
              <circle
                className={s["donut__segment"]}
                {...props}
                stroke={legend.background}
                strokeDasharray={`${perc}, 100`}
                // strokeLinecap={corners}
                strokeDashoffset={(() => {
                  let dashOffsetCurrent = 0

                  if (i === 0) {
                    dashOffsetNext = dashOffsetNext + perc
                  } else {
                    dashOffsetCurrent = dashOffsetNext
                    dashOffsetNext = dashOffsetNext + perc
                  }

                  return -dashOffsetCurrent
                })()}
                key={i}
                onMouseEnter={() =>
                  setTotalData({
                    title: legend.name,
                    amount: legend.amount,
                    percent: perc + "%",
                  })
                }
                onMouseLeave={() => setTotalData(defaultData)}
              />
            )
          })}

          <circle className={s["donut__hole"]} {...props} />
        </svg>
        {totalData.amount > 0 ? (
          <div className={s["total-container"]}>
            <div className={s["total"]}>
              <span className={s["total__title"]}>{totalData.title}</span>
              <span className={s["total__amount"]}>
                <PrettyAmount
                  amount={totalData.amount}
                  currencyCode={"643"}
                  shouldHideKop={true}
                />
              </span>
              <span className={s["total__percent"]}>{totalData.percent}</span>
            </div>
          </div>
        ) : (
          <div className={s["total-container"]}>
            <span className={s["total__title"]}>Нет трат</span>
          </div>
        )}
      </div>
    </div>
  )
}
