import React from "react"
import { useState } from "react"
import s from "./tabs.module.scss"

interface TabsProps {
  tabList: JSX.Element[]
  tabPanel: JSX.Element[]
  tabListProps?: React.HTMLAttributes<HTMLDivElement>
}

export const Tabs: React.FC<TabsProps> = ({
  tabList,
  tabPanel,
  ...tabListProps
}) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className={s["tabs"]}>
      <div
        style={tabListProps.tabListProps?.style}
        className={`${s["tab-list"]} ${
          tabListProps.tabListProps?.className || ""
        }`}
      >
        {tabList.map((listItem, i) => (
          <div
            onClick={() => {
              setActiveTab(i)
            }}
            key={i}
            className={s["tab-list__item"]}
          >
            {React.isValidElement(listItem)
              ? React.cloneElement(listItem as any, { active: i === activeTab })
              : { listItem }}
          </div>
        ))}
      </div>
      <div className={s["tab-panel"]}>
        {tabPanel.map((panelItem, i) => (
          <div
            id={`tab-panel__item_${i}`}
            className={s["tab-panel__item"]}
            style={{ display: i === activeTab ? "block" : "none" }}
            key={i}
          >
            {panelItem}
          </div>
        ))}
      </div>
    </div>
  )
}
