import s from "./button.module.scss"

interface ButtonProps {
  buttonText: string
  buttonStyle?: "default" | "blue" | "blue-dark" | "gray" | "black"
  buttonSize?: "default" | "small" | "smaller"
  fullWidth?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const Button = ({
  buttonText,
  fullWidth = true,
  buttonStyle = "default",
  buttonSize = "default",
  disabled,
  onClick,
}: ButtonProps): JSX.Element => {
  const withOnclick = typeof onClick === "function"
  const onButtonClick = () => {
    if (withOnclick && !disabled) onClick()
  }

  return (
    <button
      className={`${s["button"]} 
      ${buttonStyle !== "default" ? s[`button-${buttonStyle}`] : ""} 
      ${s[`button-size-${buttonSize}`]}
      ${fullWidth ? s["button-fullwidth"] : ""}`}
      onClick={onButtonClick}
      style={{ cursor: withOnclick && !disabled ? "pointer" : "default" }}
      disabled={disabled}
    >
      {buttonText}
    </button>
  )
}
