import { PhoneTransfers } from "./LatestPhoneTransfers"
import {
  LatestPhoneTransfersActionTypes,
  PayloadedLatestPhoneTransfersAction,
} from "./LatestPhoneTransfersActions"
import { ApiResponseError } from "store/types"

interface PhoneTransfersData {
  data: PhoneTransfers[] | null
  pending: boolean
  error?: ApiResponseError[]
}

export interface LatestPhoneTransfersState {
  readonly data: PhoneTransfersData
}

const initialState: {
  data: PhoneTransfersData
} = {
  data: {
    pending: false,
    error: [],
    data: null,
  },
}

export const LatestPhoneTransfersReducer = (
  state: LatestPhoneTransfersState = initialState,
  payloadedAction: PayloadedLatestPhoneTransfersAction
): LatestPhoneTransfersState => {
  if (payloadedAction.payload) {
    const {
      payload: { data, error },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (error) console.log(error)
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case LatestPhoneTransfersActionTypes.LATEST_PHONE_TRANSFERS_REQUEST:
        return {
          data: {
            ...stateData,
            error: [],
            pending: true,
          },
        }
      case LatestPhoneTransfersActionTypes.LATEST_PHONE_TRANSFERS_SUCCESS:
        return {
          data: {
            ...stateData,
            error: [],
            pending: false,
            data,
          },
        }
      case LatestPhoneTransfersActionTypes.LATEST_PHONE_TRANSFERS_FAILURE:
        return {
          data: {
            ...stateData,
            error: error,
            pending: false,
          },
        }
      default:
        return state
    }
  }

  return state
}
