import { AppState } from "../../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"
import { Cryptor } from "utils/Cryptor"

export enum SigninActionTypes {
  SIGNIN_REQUEST = "SIGNIN_REQUEST",
  SIGNIN_REQUEST_FAILTURE = "SIGNIN_REQUEST_FAILTURE",
  SIGNIN_REQUEST_SUCCESS = "SIGNIN_REQUEST_SUCCESS",
  SIGNIN_SET_TOKEN = "SIGNIN_SET_TOKEN",
  SIGNIN_CLEAR_FORM = "SIGNIN_CLEAR_FORM",
}

export interface PayloadedSigninAction {
  type: SigninActionTypes
  payload: {
    token?: string
    wasOtpSent?: boolean
    errors?: ApiResponseError[]
  }
}

export class SigninActions {
  public static sendSigninRequest =
    (data: { phone: string; otp?: string; at: string }) =>
    (dispatch: Dispatch, getState: () => AppState): void => {
      dispatch({
        type: SigninActionTypes.SIGNIN_REQUEST,
        payload: {},
      })

      fetcher({
        url: "/auth/login",
        method: "POST",
        body: {
          phone: data.phone,
          otp: data.otp
            ? new Cryptor(getState().auth.data.pk).encrypt(data.otp)
            : null,
          authToken: data.at,
        },
      }).then(
        (
          res: ApiResponse<{
            sessionToken?: string
            wasOtpSent: boolean
          }>
        ) => {
          const { sessionToken, wasOtpSent } = res.data

          if (res.success) {
            dispatch<PayloadedSigninAction>({
              type: SigninActionTypes.SIGNIN_REQUEST_SUCCESS,
              payload: { token: sessionToken },
            })

            if (sessionToken) {
              dispatch<PayloadedSigninAction>({
                type: SigninActionTypes.SIGNIN_SET_TOKEN,
                payload: { token: sessionToken },
              })
            }
          } else {
            dispatch<PayloadedSigninAction>({
              type: SigninActionTypes.SIGNIN_REQUEST_FAILTURE,
              payload: {
                wasOtpSent: wasOtpSent,
                errors: res.errors,
              },
            })
          }
        }
      )
    }

  public static resetState =
    () =>
    (dispatch: Dispatch, getState: () => AppState): void => {
      dispatch({
        type: SigninActionTypes.SIGNIN_CLEAR_FORM,
        payload: {},
      })
    }
}
