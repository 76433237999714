import { ApiResponse, ApiResponseError } from "store/types"
import { AppState } from "../../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { Card } from "./Card"

export enum CardsActionTypes {
  CARDS_REQUEST = "CARDS_REQUEST",
  CARDS_REQUEST_SUCCESS = "CARDS_REQUEST_SUCCESS",
  CARDS_REQUEST_FAILURE = "CARDS_REQUEST_FAILURE",
  CARDS_SET_DEFAULT_CARD = "CARDS_SET_DEFAULT_CARD",
  CARDS_CLEAR = "CARDS_CLEAR",
}

export interface PayloadedAction {
  type: CardsActionTypes
  payload: {
    cards: Card[] | never[]
    primaryCard: Card | null
    errors: ApiResponseError[] | null
  }
}

export const cardsSelector = (state: AppState) => state.cards.data

export const cardsPrimaryCardSet =
  (card: Card) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch<PayloadedAction>({
      type: CardsActionTypes.CARDS_SET_DEFAULT_CARD,
      payload: { cards: [], primaryCard: card, errors: null },
    })
  }

export const cardsClear =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch<PayloadedAction>({
      type: CardsActionTypes.CARDS_CLEAR,
      payload: { cards: [], primaryCard: null, errors: null },
    })
  }

export const cardsGet =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: CardsActionTypes.CARDS_REQUEST, payload: {} })

    fetcher({ url: "/cards", method: "GET" }).then(
      (res: ApiResponse<{ cards: Card[] | never[] }>) => {
        if (res.success) {
          const cardsData = res.data.cards

          dispatch<PayloadedAction>({
            type: CardsActionTypes.CARDS_REQUEST_SUCCESS,
            payload: {
              cards: cardsData,
              primaryCard: cardsData[0],
              errors: [],
            },
          })
        } else {
          dispatch<PayloadedAction>({
            type: CardsActionTypes.CARDS_REQUEST_FAILURE,
            payload: { cards: [], primaryCard: null, errors: [] },
          })
        }
      }
    )
  }
