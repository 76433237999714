import s from "./styles.module.scss"
import { ReactComponent as ArrowIcon } from "components/icon/arrow-next.svg"

interface ListElement {
  value: string
  icon?: JSX.Element
  onClick?: () => void
  onClickEnabled: boolean
}

interface ListProps {
  listItems: ListElement[] | JSX.Element[]
}

export const List: React.FC<ListProps> = ({ listItems }) => {
  return (
    <ul className={s["list"]}>
      {listItems.map((item, i) => {
        if (item.hasOwnProperty("props")) {
          return (
            <li className={s["list-item"]} key={i}>
              {item}
            </li>
          )
        } else {
          const o = item as ListElement
          const isClickEnabled = o.onClickEnabled && o.onClick
          const click = () => o.onClickEnabled && o.onClick && o.onClick()

          return (
            <li
              className={s["list-item"]}
              onClick={click}
              style={{ cursor: isClickEnabled ? "pointer" : "default" }}
              key={i}
            >
              <div className={s["list-item__content"]}>
                <div className={s["list-item__icon"]}>{o.icon}</div>
                <p className={s["list-item__text"]}>{o.value}</p>
              </div>
              {isClickEnabled && (
                <div
                  className={`${s["list-item__icon"]} ${s["list-item__icon-arrow"]}`}
                >
                  <ArrowIcon />
                </div>
              )}
            </li>
          )
        }
      })}
    </ul>
  )
}
