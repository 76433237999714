import { LatestPhoneTransfersReducer } from "./latest-phone-transfers/LatestPhoneTransfersReducer"
import { createStore, combineReducers, applyMiddleware } from "redux"
import { AvatarPresetsReducer } from "./avatar-presets/AvatarPresetsReducer"
import { SavedCardReducer } from "./cards/saved-card/SavedCardReducer"
import { InviteFormReducer } from "./forms/invite/InviteReducer"
import { SupportReducer } from "./forms/support/SupportReducer"
import { composeWithDevTools } from "redux-devtools-extension"
import { SigninReducer } from "./forms/signin/SigninReducer"
import { SignupReducer } from "./forms/signup/SignupReducer"
import { SettingsReducer } from "./settings/SettingsReducer"
import { HistoryReducer } from "./history/HistoryReducer"
import { UserReducer } from "./user/UserReducer"
import { AuthReducer } from "./auth/AuthReducer"
import { MccReducer } from "./mcc/MccReducer"
import thunkMiddleware from "redux-thunk"
import { CardsReducer } from "./cards/cards/CardsReducer"
import { CvvReducer } from "./cards/cvv/CvvReducer"
import { FaqReducer } from "./faq/FaqReducer"
import { LimitsReducer } from "./cards/limits/LimitsReducer"
import {ChatReducer} from "./chat/ChatReducer";
import {ThemeReducer} from "./theme/ThemeReducer";

const combinedReducers = combineReducers({
  settings: SettingsReducer,
  history: HistoryReducer,
  user: UserReducer,
  cards: CardsReducer,
  cvv: CvvReducer,
  limits: LimitsReducer,
  savedCard: SavedCardReducer,
  latestPhoneTransfers: LatestPhoneTransfersReducer,
  inviteForm: InviteFormReducer,
  faq: FaqReducer,
  support: SupportReducer,
  auth: AuthReducer,
  signin: SigninReducer,
  signup: SignupReducer,
  mcc: MccReducer,
  avatarPresets: AvatarPresetsReducer,
  chat: ChatReducer,
  theme: ThemeReducer
})

export const store = createStore(
  combinedReducers,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(thunkMiddleware))
    : applyMiddleware(thunkMiddleware)
)

export type AppState = ReturnType<typeof store.getState>
