import { ButtonIcon } from "components/generic/button/button-icon/ButtonIcon"
import { Tabs } from "components/generic/tabs/Tabs"
import { DepositFromBank } from "./DepositFromBank"
import { DepositFromCard } from "./DepositFromCard"

export const Deposit: React.FC = () => {
  return (
    <Tabs
      tabList={[
        <ButtonIcon
          text="С карты"
          buttonSize="small"
          buttonStyle="lightgray"
          activeClass="all-blue"
        />,
        <ButtonIcon
          text="Со счета в другом банке"
          buttonSize="small"
          buttonStyle="lightgray"
          activeClass="all-blue"
        />,
      ]}
      tabPanel={[<DepositFromCard />, <DepositFromBank />]}
    />
  )
}
