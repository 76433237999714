import { ApiResponseError } from "store/types"
import { OperationHistory } from "./History"
import { HistoryActionTypes, PayloadedHistoryAction } from "./HistoryActions"

interface OperationHistoryData {
  history: OperationHistory[] | null
  pending: boolean
  errors: ApiResponseError[]
}

export interface HistoryState {
  readonly data: OperationHistoryData
}

const initialState: { data: OperationHistoryData } = {
  data: {
    history: null,
    pending: false,
    errors: [],
  },
}

export const HistoryReducer = (
  state: HistoryState = initialState,
  payloadedAction: PayloadedHistoryAction
): HistoryState => {
  if (payloadedAction.payload) {
    const {
      payload: { history, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case HistoryActionTypes.HISTORY_REQUEST:
        return {
          data: {
            ...stateData,
            pending: true,
            errors: [],
          },
        }
      case HistoryActionTypes.HISTORY_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            history,
            pending: false,
            errors: [],
          },
        }
      case HistoryActionTypes.HISTORY_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            pending: false,
            errors: errors || [],
          },
        }
      default:
        return state
    }
  }

  return state
}
