import { Outlet } from "react-router-dom"
import { PublicHeader } from "./header/PublicHeader"
import { PublicFooter } from "./footer/PublicFooter"

export const Layout: React.FC = () => {
  return (
    <>
      {/*<PublicHeader />*/}
      <main className="layout container">
        <Outlet />
      </main>
      <PublicFooter />
    </>
  )
}
