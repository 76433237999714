import { OtpConfirm } from "./OtpConfirm"
import { SigninActions } from "store/forms/signin/SigninActions"
import { AppState } from "store/store"
import { useAppSelector } from "store/hooks"
import {useEffect} from "react";
import {CookieManager} from "../../utils/CookieManager";
import {useDispatch} from "react-redux";

export const Signin: React.FC = () => {
  const dispatch = useDispatch();
  const state = useAppSelector((state: AppState) => state.signin);

  // useEffect(() => {
  //   if (CookieManager.getTokenCookie() !== "")
  //     dispatch({type: "SIGNIN_SET_TOKEN", payload: {token: CookieManager.getTokenCookie(), wasOtpSent: false, errors: []}})
  // }, [])
  //
  useEffect(() => {
    alert(CookieManager.getTokenCookie())
  }, [state.data.token])

  return (
    <OtpConfirm
      title="Быстрый вход"
      onSubmit={SigninActions.sendSigninRequest}
      onSuccessSubmit={SigninActions.resetState}
      state={useAppSelector((state: AppState) => state.signin)}
    >
    </OtpConfirm>
  )
}
