import { InputText } from "components/generic/input/input-text/inputText"
import { formatExpiry } from "utils/card/card"
import s from "./card-input.module.scss"
import { ReactComponent as CloseIcon } from "components/icon/close.svg"
import { ReactComponent as ArrowIcon } from "components/icon/arrow-next.svg"
import { useRef, useEffect } from "react"
import { InputPan, PanInputData } from "components/generic/input/input-pan/InputPan"

interface CardDataVal {
  value: string
  valueMasked?: string

  valid: boolean
  error: string
}

interface CardInputViewProps {
  cardData: {
    pan: CardDataVal
    expiredate: CardDataVal
    cvv: CardDataVal
  }
  isFirstStepPassed: boolean
  receiverBank: {
    image: string
    backgroundColor: string
  } | null
  touched: boolean
  onChangePan: (data: PanInputData) => void
  onChangeExpireDate: (value: string) => void
  onChangeCvv: (value: string) => void
  onNavClick: () => void
  onTouch: () => void
}

export const CardInputView: React.FC<CardInputViewProps> = ({
  cardData,
  isFirstStepPassed,
  receiverBank,
  touched = false,
  onChangePan,
  onChangeExpireDate,
  onChangeCvv,
  onNavClick,
  onTouch,
}) => {
  const { pan, expiredate, cvv } = cardData
  const isPanValid = cardData?.pan?.valid
  const bankImage = receiverBank?.image

  const panRef = useRef<any>(null)
  const expireDateRef = useRef<any>(null)
  const cvvRef = useRef<any>(null)

  useEffect(() => {
    if (touched) {
      // If click forward icon and cross icon when everything is valid
      if (pan.valid && expiredate.valid && cvv.valid) return
      // If only pan is filled
      if (
        pan.valid &&
        !expiredate.valid &&
        !cvv.valid &&
        expireDateRef.current
      ) {
        expireDateRef.current.focus()
        return
      }
      // If pan and expire date are filled
      if (pan.valid && expiredate.valid && cvvRef.current) {
        cvvRef.current.focus()
        return
      }
      // If cross icon clicked
      if (!isFirstStepPassed && pan.valid) {
        panRef.current.focus()
        return
      }
    }
  }, [pan.valid, expiredate.valid, cvv.valid, isFirstStepPassed, touched])

  return (
    <div className={s["card-input"]} onClick={onTouch}>
      <div
        className={`${s["card-input__part"]} ${s["card-input__part-main-data"]}`}
      >
        <div className={s["card-input__part-main-data__inputs"]}>
          <InputPan
            value={pan?.value || ""}
            innerRef={panRef}
            autoComplete="off"
            onChange={(data) => onChangePan(data)}
          />
        </div>
        {isPanValid && (
          <div className={s["card-input__icon"]} onClick={onNavClick}>
            <ArrowIcon />
          </div>
        )}
      </div>
      {isFirstStepPassed && isPanValid && (
        <div
          className={`${s["card-input__part"]} ${s["card-input__part-rest-data"]}`}
        >
          <div className={s["card-input__part-rest-data__inputs"]}>
            <div className={s["card-input-bank"]}>
              {bankImage && <img src={bankImage} alt="bank" />}
            </div>
            <p className={s["card-input-masked-pan"]} onClick={onNavClick}>
              {pan?.valueMasked}
            </p>
            <div className={s["card-input__expire"]}>
              <InputText
                value={expiredate?.value || ""}
                placeholder="мм/гг"
                innerRef={expireDateRef}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const expiry = formatExpiry(e)
                  onChangeExpireDate(expiry)
                }}
              />
            </div>
            <div className={s["card-input__cvc"]}>
              <InputText
                value={cvv?.value || ""}
                placeholder="CVC"
                innerRef={cvvRef}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  onChangeCvv(e.currentTarget.value)
                }}
              />
            </div>
          </div>
          <div className={s["card-input__icon"]} onClick={onNavClick}>
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  )
}
