import { MAX_PHONE_LEN, SMS_CONFIRMATION_CODE_LENGTH_DEFAULT } from "../config"
import { OTP_LEN_DEFAULT } from "config"

export class Validator {
  public static isImage = (path: string): boolean =>
    /[https?://.*]?\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i.test(path)

  public static isSmsCode = (code: string) =>
    code.replace(/\D/, "").length === SMS_CONFIRMATION_CODE_LENGTH_DEFAULT

  public static isOtpCode = (otp: string) =>
    otp.replace(/\D/, "").length === OTP_LEN_DEFAULT

  public static isPhone = (phone: string) =>
    phone.replace(/\D/, "").length === MAX_PHONE_LEN

  public static isEmail = (email: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )

  public static isEmoji = (emoji: string): boolean =>
    /\p{Extended_Pictographic}/u.test(emoji)

  public static isHexColor = (color: string) =>
    /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i.test(color)

  public static isDateType = (date: { [key: string]: any }): boolean =>
    typeof date?.getMonth === "function"
}
