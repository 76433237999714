import React, { useState } from "react"
import moment from "moment"
import { HistoryWithGroup } from "../utils"
import { Popup } from "components/generic/popup/Popup"
import { OperationItem } from "./OperationItem"
import { OperationPopupContent } from "./OperationPopupContent"
import s from "./operations.module.scss"

export interface OperationsProps {
  history: {
    [date: string]: HistoryWithGroup[]
  }
  pending: boolean
}

export const Operations: React.FC<OperationsProps> = ({ history, pending }) => {
  const [activeStatement, setActiveStatement] =
    useState<HistoryWithGroup | null>(null)

  const onClickOperation = (history: HistoryWithGroup | null) => {
    setActiveStatement(
      history
        ? {
            ...history,
            transactionDate: moment(history.transactionDate).format(
              "DD MMMM YYYY, h:mm"
            ),
          }
        : null
    )
  }

  const operations = Object.entries(history)
  const isNotEmpty = operations.length > 0

  return (
    <>
      {activeStatement && (
        <Popup
          isShown={activeStatement !== null}
          onClosePopup={() => onClickOperation(null)}
          withCloseButton
        >
          <OperationPopupContent history={activeStatement} />
        </Popup>
      )}

      <div
        className={`${s["operations-container"]} ${
          !isNotEmpty ? s["operations-container-blank"] : ""
        }`}
      >
        <div className={s["operations"]}>
          {isNotEmpty ? (
            operations.map((k) => {
              const date = k[0]
              const historyItems = k[1]

              return (
                <div className={s["operations-group"]} key={date}>
                  <p className={s["operations-group__date"]}>{date}</p>
                  <div>
                    {historyItems.map((item, i) => (
                      <OperationItem
                        history={item}
                        onClick={() => {
                          onClickOperation(item)
                        }}
                        key={item.retailerName + item.balanceBefore}
                      />
                    ))}
                  </div>
                </div>
              )
            })
          ) : (
            <>
              <p className={s["no-operations"]}>Нет операций</p>
              <div className={s["operations-group"]}>
                <p
                  className={`${s["operations-group__date"]} ${s["operations-group__date-blank"]}`}
                />
                <div>
                  <OperationItem history={null} onClick={() => {}} />
                  <OperationItem history={null} onClick={() => {}} />
                  <OperationItem history={null} onClick={() => {}} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
