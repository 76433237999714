import { BarChart, BarChartProps } from "components/chart/bar/BarChart"
import { DonutChart, DonutChartProps } from "components/chart/donut/DonutChart"
import s from "./charts.module.scss"

export type ActiveChart = "donut" | "bar"

export interface ChartsProps extends BarChartProps, DonutChartProps {
  activeChart: ActiveChart
}

export const Charts: React.FC<ChartsProps> = ({
  barChartData,
  donutChartData,
  activeChart,
  children,
}) => {
  return (
    <div className="">
      {activeChart === "bar" ? (
        <div className={s["bar-view"]}>
          <div className={s["bar-view__chart"]}>
            <BarChart barChartData={barChartData} />
          </div>
          <div className={s["bar-view__legend"]}>{children}</div>
        </div>
      ) : (
        <div className={s["donut-view"]}>
          <div className={s["donut-view__chart"]}>
            <DonutChart donutChartData={donutChartData} />
          </div>
          <div className={s["donut-view__legend"]}>{children}</div>
        </div>
      )}
    </div>
  )
}
