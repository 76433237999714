import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import { cardsSelector } from "store/cards/cards/CardsActions"
import { useAppSelector } from "store/hooks"
import s from "./balance.module.scss"

export const BalanceView: React.FC = () => {
  const { primaryAccount, pending } = useAppSelector(cardsSelector)

  return (
    <div className={s.balance}>
      {!pending ? (
        <PrettyAmount
          amount={primaryAccount?.availBalance || 0}
          currencyCode={primaryAccount?.currency ? primaryAccount?.currency + "" : "643"}
        />
      ) : (
        ""
      )}
    </div>
  )
}
