import s from "./action-button.module.scss"

interface ActionButtonProps {
  icon: JSX.Element
  onClick?: () => void
}

export const ActionButton = ({
  icon,
  onClick,
}: ActionButtonProps): JSX.Element => {
  const onClickButton = () => onClick && onClick()

  return (
    <div className={s["action-button"]} onClick={onClickButton}>
      <div className={s["action-button__img"]}>{icon}</div>
    </div>
  )
}
