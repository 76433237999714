import { ApiResponseError } from "store/types"
import { SupportActionTypes, PayloadedSupportAction } from "./SupportActions"

interface SupportData {
  sentTo: string | null
  pending: boolean
  errors: ApiResponseError[] | null
}

export interface HelpFormState {
  readonly data: SupportData
}

const initialState: { data: SupportData } = {
  data: {
    sentTo: null,
    pending: false,
    errors: [],
  },
}

export const SupportReducer = (
  state: HelpFormState = initialState,
  payloadedAction: PayloadedSupportAction
): HelpFormState => {
  if (payloadedAction.payload) {
    const {
      payload: { sentTo, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case SupportActionTypes.SUPPORT_SEND_REQUEST:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: true,
          },
        }
      case SupportActionTypes.SUPPORT_SEND_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            sentTo,
            errors: null,
            pending: true,
          },
        }
      case SupportActionTypes.SUPPORT_SEND_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            errors: errors || [],
            pending: false,
          },
        }
      case SupportActionTypes.SUPPORT_RESET_RESULT:
        return initialState
      default:
        return state
    }
  }

  return state
}
