import {
  Avatar,
  AvatarImage,
} from "components/generic/avatar-image/AvatarImage"
import { ReactComponent as CloseIcon } from "components/icon/close.svg"
import { userSelector, userUpdate } from "store/user/UserActions"
import {
  avatarPresetsSelector,
  getAvatarPresets,
} from "store/avatar-presets/AvatarPresetsActions"
import s from "./avatar-changer.module.scss"
import { useAppSelector } from "store/hooks"
import { PresetsBox } from "./PresetsBox"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"

export const AvatarChanger: React.FC = () => {
  const dispatch = useDispatch()
  const { avatarPresets } = useAppSelector(avatarPresetsSelector)
  const { qUser } = useAppSelector(userSelector)

  const [avatar, setAvatar] = useState<Avatar>({
    avatarImage: null,
    backgroundColor: null,
  })

  const isAnyAvatarSet = avatar.avatarImage || avatar.backgroundColor

  const isConfirmDisabled: boolean =
    avatar.avatarImage === qUser?.avatarImage &&
    avatar.backgroundColor === qUser?.avatarBackground

  useEffect(() => {
    if (!avatarPresets) dispatch(getAvatarPresets())
    setAvatar({
      avatarImage: qUser?.avatarImage || null,
      backgroundColor: qUser?.avatarBackground || null,
    })
  }, [avatarPresets, qUser, dispatch])

  const onConfirmChange = () => {
    dispatch(
      userUpdate({
        avatarImage: avatar.avatarImage,
        avatarBackground: avatar.backgroundColor,
      })
    )
  }

  const onDeleteAvatar = () => {
    dispatch(
      userUpdate({
        avatarImage: null,
        avatarBackground: null,
      })
    )
  }

  return (
    <div className={s["avatar-changer"]}>
      <div>
        <div className={s["current"]}>
          <div className={s["current__avatar"]}>
            <AvatarImage avatar={avatar} className={s["preset-avatar"]} withBorder/>
          </div>
          {isAnyAvatarSet && (
            <span className={s["current__delete"]} onClick={onDeleteAvatar}>
              <CloseIcon />
            </span>
          )}
        </div>
      </div>
      <div className={s["delimiter"]} />
      <div className={s["constructor"]}>
        <PresetsBox
          currentAvatar={avatar}
          avatarPresets={avatarPresets!}
          onConfirmChange={() => {
            onConfirmChange()
          }}
          isConfirmDisabled={isConfirmDisabled}
          onAvatarChanged={(a) => {
            setAvatar({ ...avatar, ...a })
          }}
        />
      </div>
    </div>
  )
}
