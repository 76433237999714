import { ReactComponent as ArrowRightIcon } from "components/icon/arrow-right.svg"
import { Link, LinkProps } from "react-router-dom"
import s from "./back-button.module.scss"

interface BackButtonProps {
  action: LinkProps["to"] | (() => void) // Navigate or click
}

export const BackButton = ({ action }: BackButtonProps): JSX.Element => {
  const withFunc = action && typeof action === "function"

  return (
    <Link
      to={withFunc ? "" : action}
      className={s["back-button"]}
      onClick={() => withFunc && action()}
    >
      <div className={s["back-button__img"]}>
        <ArrowRightIcon />
      </div>
    </Link>
  )
}
