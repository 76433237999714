import { TransferLimitView } from "./TransferLimitView"
import moment from "moment"
import { useAppSelector } from "store/hooks"
import { cardsSelector } from "store/cards/cards/CardsActions"
import { limitsSelector } from "store/cards/limits/LimitsActions"

export const TransferLimitContainer: React.FC = () => {
  const { primaryAccount } = useAppSelector(cardsSelector)
  const limitsState = useAppSelector(limitsSelector)
  const limits = limitsState.limits || []

  const limit = {
    current: limits?.[0]?.current,
    max: limits?.[0]?.max,
  }

  const currency = primaryAccount?.currency + "" || ""
  const percetage = (limit.current / limit.max) * 100
  const untilMonthEnd = moment(new Date()).endOf("month").format("D MMMM")

  return (
    <TransferLimitView
      summ={limit.max - limit.current}
      currency={currency}
      until={untilMonthEnd}
      progressPercentage={percetage}
    />
  )
}
