import { Clipboarder } from "components/generic/clipboarder/Clipboarder"
import { LabelPanel } from "components/generic/modular/LabelPanel"
import s from "./card-details.module.scss"

interface CardDetailsProps {
  card: {
    pan: string
    date: string
    cvv: string
  }
  isDataHidden: boolean
  onToggleShow: () => void
  onClickCopy: (str: string) => void
}

export const CardDetailsView: React.FC<CardDetailsProps> = ({
  card,
  isDataHidden,
  onToggleShow,
}) => {
  return (
    <div className={s["card-detail-area"]}>
      <LabelPanel
        title="Реквизиты карты"
        textColor="dark-gray"
        button={{
          title: isDataHidden ? "Показать" : "Скрыть",
          onClickButtonTitle: onToggleShow,
        }}
        style={{ paddingBottom: 10 }}
      />
      <div className={s["card-detail"]}>
        <div className={s["card-detail-parts"]}>
          <div
            className={`${s["card-detail-parts__item"]} ${s["card-detail-parts__item-fullwidth"]}`}
          >
            <p className={s["card-detail__pan"]}>{card.pan}</p>
            {!isDataHidden && <Clipboarder text={card.pan} />}
          </div>
          <div className={s["card-detail-parts__item"]}>
            <p className={s["card-detail__date"]}>{card.date}</p>
            {!isDataHidden && <Clipboarder text={card.date} />}
          </div>
          <div className={s["card-detail-parts__item"]}>
            <p className={s["card-detail__cvv"]}>{card.cvv}</p>
            {!isDataHidden && <Clipboarder text={card.cvv} />}
          </div>
        </div>
      </div>
    </div>
  )
}
