import { ApiResponseError } from "store/types"
import { MinbUser, QUser } from "./User"
import { UserActionTypes, PayloadedUserAction } from "./UserActions"

interface UserData {
  qUser: QUser | null
  minbClient: MinbUser | null
  pending: boolean
  errors: ApiResponseError[] | null
}

export interface UserState {
  readonly data: UserData
}

const initialState: { data: UserData } = {
  data: {
    qUser: null,
    minbClient: null,
    pending: false,
    errors: null,
  },
}

export const UserReducer = (
  state: UserState = initialState,
  payloadedAction: PayloadedUserAction
): UserState => {
  if (payloadedAction.payload) {
    const {
      payload: { data, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case UserActionTypes.USER_REQUEST:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: true,
          },
        }
      case UserActionTypes.USER_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: false,
            qUser: data?.qUser || null,
            minbClient: data?.minbClient || null,
          },
        }
      case UserActionTypes.USER_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            errors: errors,
            pending: false,
          },
        }
      case UserActionTypes.USER_UPDATE_REQUEST:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: true,
          },
        }
      case UserActionTypes.USER_UPDATE_SUCCESS:
        return {
          data: {
            qUser: data?.qUser || null,
            minbClient: data?.minbClient || null,
            errors: null,
            pending: false,
          },
        }
      case UserActionTypes.USER_UPDATE_FAILURE:
        return {
          data: {
            ...stateData,
            errors,
            pending: false,
          },
        }
      case UserActionTypes.USER_LOGOUT:
        return initialState
      default:
        return state
    }
  }

  return state
}
