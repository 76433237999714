import { AppState } from "../store"
import { Dispatch } from "redux"
import { Mcc } from "./Mcc"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum MccActionTypes {
  MCC_REQUEST = "MCC_REQUEST",
  MCC_REQUEST_FAILURE = "MCC_REQUEST_FAILURE",
  MCC_REQUEST_SUCCESS = "MCC_REQUEST_SUCCESS",
}

export interface PayloadedMccAction {
  type: MccActionTypes
  payload: {
    mcc: Mcc | null
    errors?: ApiResponseError[]
  }
}

export const mccSelector = (state: AppState) => state.mcc.data

export const getMcc =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: MccActionTypes.MCC_REQUEST, payload: {} })

    fetcher({ url: "/mcc", method: "GET" }).then(
      (res: ApiResponse<{ mcc: Mcc }>) => {
        if (res.success) {
          return dispatch<PayloadedMccAction>({
            type: MccActionTypes.MCC_REQUEST_SUCCESS,
            payload: { mcc: res.data.mcc },
          })
        } else {
          return dispatch<PayloadedMccAction>({
            type: MccActionTypes.MCC_REQUEST_FAILURE,
            payload: {
              mcc: null,
              errors: res.errors,
            },
          })
        }
      }
    )
  }
