
import React from "react"
import {
  Link,
  useMatch,
  useResolvedPath,
  LinkProps,
  useLocation,
} from "react-router-dom"

interface CustomLinkProps extends LinkProps {
  searchForMatch?: string
}
export const CustomLink: React.FC<CustomLinkProps> = ({
  searchForMatch = "",
  children,
  to,
  ...props
}) => {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true })

  const attrs: { [key: string]: any } = {}
  if (useLocation().pathname === searchForMatch) attrs["data-matched"] = ""
  if (match) attrs["data-active"] = ""

  return (
    <Link to={to} {...props} {...attrs}>
      {React.isValidElement(children)
        ? React.cloneElement(children, { active: match !== null })
        : { children }}
    </Link>
  )
}
