import { ApiResponse, ApiResponseError } from "store/types"
import { AppState } from "../../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { Limit } from "./Limits"

export enum LimitsActionTypes {
  LIMITS_REQUEST = "LIMITS_REQUEST",
  LIMITS_REQUEST_SUCCESS = "LIMITS_REQUEST_SUCCESS",
  LIMITS_REQUEST_FAILURE = "LIMITS_REQUEST_FAILURE",
  LIMITS_CLEAR = "LIMITS_CLEAR",
}

export interface PayloadedAction {
  type: LimitsActionTypes
  payload: {
    data: Limit[] | null
    errors: ApiResponseError[] | null
  }
}

export const limitsSelector = (state: AppState) => state.limits.data

export const limitsGet =
  (cardPublicId: string) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: LimitsActionTypes.LIMITS_REQUEST, payload: {} })

    fetcher({ url: `/cards/${cardPublicId}/limits`, method: "GET" }).then(
      (res: ApiResponse<{ limits: Limit[] }>) => {
        if (res.success) {
          dispatch<PayloadedAction>({
            type: LimitsActionTypes.LIMITS_REQUEST_SUCCESS,
            payload: {
              data: res.data.limits,
              errors: [],
            },
          })
        } else {
          dispatch<PayloadedAction>({
            type: LimitsActionTypes.LIMITS_REQUEST_FAILURE,
            payload: { data: null, errors: [] },
          })
        }
      }
    )
  }

export const limitsClear =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: LimitsActionTypes.LIMITS_CLEAR,
      payload: {
        data: null,
        errors: [],
      },
    })
  }
