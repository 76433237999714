import { AppState } from "../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum AuthActionTypes {
  AUTH_KEY_REQUEST = "AUTH_KEY_REQUEST",
  AUTH_KEY_REQUEST_SUCCESS = "AUTH_KEY_REQUEST_SUCCESS",
  AUTH_KEY_REQUEST_FAILTURE = "AUTH_KEY_REQUEST_FAILTURE",
  AUTH_KEY_SET = "AUTH_KEY_SET",
  AUTH_KEY_REMOVE = "AUTH_KEY_REMOVE",
}

export interface PayloadedAuthAction {
  type: AuthActionTypes
  payload: {
    token: string
    pk: string
    errors: ApiResponseError[]
  }
}

export const authTokenRemove =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch<PayloadedAuthAction>({
      type: AuthActionTypes.AUTH_KEY_REMOVE,
      payload: { token: "", pk: "", errors: [] },
    })
  }

export const getAuthToken =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: AuthActionTypes.AUTH_KEY_REQUEST,
      payload: {},
    })

    fetcher({
      url: "/auth/token",
      method: "POST",
      body: {},
    }).then((res: ApiResponse<{ token: string; pk: string }>) => {
      if (res.success) {
        const at = res.data.token
        const pk = res.data.pk

        dispatch<PayloadedAuthAction>({
          type: AuthActionTypes.AUTH_KEY_REQUEST_SUCCESS,
          payload: { token: "", pk, errors: [] },
        })
        dispatch<PayloadedAuthAction>({
          type: AuthActionTypes.AUTH_KEY_SET,
          payload: { token: at, pk, errors: [] },
        })
      } else {
        dispatch<PayloadedAuthAction>({
          type: AuthActionTypes.AUTH_KEY_REQUEST_FAILTURE,
          payload: {
            token: "",
            pk: "",
            errors: res.errors,
          },
        })
      }
    })
  }
