import { useSelector } from "react-redux"
import React, { useEffect, useMemo } from "react"
import { historySelector } from "store/history/HistoryActions"
import { mccSelector } from "store/mcc/MccActions"
import { OperationsHistory } from "."
import { settingsSelector } from "store/settings/SettingsActions"
import s from "./history.module.scss"

interface StatementProps {
  checkDevice?: boolean
}

export const Statement: React.FC<StatementProps> = ({
  checkDevice = false,
}) => {
  const { windowSize } = useSelector(settingsSelector)
  const mccData = useSelector(mccSelector)
  const mcc = useMemo(() => mccData?.mcc || { groups: {}, mcc: {} }, [mccData])

  return checkDevice ? (
    windowSize === "DESKTOP" ? (
      <OperationsHistory mcc={mcc} />
    ) : (
      <>
        <div style={{padding: "5px 10px", fontSize: "10pt", color: "#6ED3FC"}} onClick={() => {
          const mainPaige = document.getElementById("tab-panel__item_0");
          mainPaige!.style.display = "none";

          const operationPaige = document.getElementById("tab-panel__item_1");
          operationPaige!.style.display = "block";
        }}>Просмотр аналитики</div>
        <OperationsHistory className={s["history-reversal"]} mcc={mcc} minimal />
      </>
    )
  ) : (
    <OperationsHistory mcc={mcc} />
  )
}
