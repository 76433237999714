import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import { useAppSelector } from "store/hooks"
import { userSelector } from "store/user/UserActions"
import { ProfileStatusView } from "./ProfileStatusView"

interface ProfileStatusContainerProps {}

export const ProfileStatusContainer: React.FC<
  ProfileStatusContainerProps
> = () => {
  const { minbClient } = useAppSelector(userSelector)
  const cardName = "Virtual Card Plus"
  const amt = <PrettyAmount amount={60000} currencyCode="643" />
  const text: string | JSX.Element =
    minbClient?.status === 1 ? (
      <p>
        Пройдите быструю идентификацию, чтобы разблокировать{" "}
        <b>переводы и остаток до {amt}</b>
      </p>
    ) : (
      ""
    )

  return <ProfileStatusView text={text} buttonText={`Получить ${cardName}`} />
}
