import { ApiResponseError } from "store/types"
import { Signin } from "./Signin"
import { SigninActionTypes, PayloadedSigninAction } from "./SigninActions"

interface SigninData extends Signin {
  pending: boolean
  wasOtpSent: boolean
  token: string
  errors: ApiResponseError[]
}

export interface SigninState {
  readonly data: SigninData
}

const initialState: { data: SigninData } = {
  data: {
    pending: false,
    wasOtpSent: false,
    token: "",
    errors: [],
  },
}

export const SigninReducer = (
  state: SigninState = initialState,
  payloadedAction: PayloadedSigninAction
): SigninState => {
  if (payloadedAction.payload) {
    const {
      payload: { errors, token, wasOtpSent },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case SigninActionTypes.SIGNIN_REQUEST:
        return {
          data: {
            ...stateData,
            wasOtpSent: false,
            errors: [],
          },
        }
      case SigninActionTypes.SIGNIN_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            errors: [],
          },
        }
      case SigninActionTypes.SIGNIN_REQUEST_FAILTURE:
        const otpRequiredError = (errors || []).some(
          (e) => e.textCode === "OTP_REQUIRED"
        )
        const otherErrors = (errors || []).filter(
          (e) => e.textCode !== "OTP_REQUIRED"
        )

        return {
          data: {
            ...stateData,
            wasOtpSent:
              otpRequiredError && wasOtpSent !== undefined && wasOtpSent,
            errors: otherErrors || [],
          },
        }
      case SigninActionTypes.SIGNIN_SET_TOKEN:
        return {
          data: {
            ...stateData,
            token: token!,
            errors: [],
          },
        }
      case SigninActionTypes.SIGNIN_CLEAR_FORM:
        return {
          ...initialState,
        }

      default:
        return state
    }
  }

  return state
}
