import s from "../input.module.scss"

export interface InputTextProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  className?: string
  innerRef?: any
}

export const InputText: React.FC<InputTextProps> = ({
  onChange,
  className = "",
  innerRef,
  ...props
}) => {
  const onChangeInput = (e: React.FormEvent<HTMLInputElement>) =>
    onChange && onChange(e)

  return (
    <input
      type="text"
      {...props}
      ref={innerRef}
      onChange={onChangeInput}
      className={`${s["input"]} ${className}`}
    />
  )
}
