import { useRef, useState } from "react"
import { formatRuble } from "utils/currency/format-currency"
import s from "./input-currency.module.scss"

interface InputCurrencyProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChangeValue?: (value: string) => void
  className?: string
  value: string
  currencySymbol: string
  innerRef?: any
}

export const InputCurrency: React.FC<InputCurrencyProps> = ({
  onChangeValue,
  value,
  currencySymbol,
  ...props
}) => {
  const onChangeInput = (value: string) =>
    typeof onChangeValue === "function" && onChangeValue(value)

  const textareaRef = useRef<any>(null)
  const [v, setV] = useState("")

  const onChangeSumm = (e: React.FormEvent<HTMLInputElement>) => {
    const nextVal = e.currentTarget.value
    const cleared = (nextVal.match(/\d|,/gi) || []).join("")
    if (cleared.includes(",")) {
      const splittedAmt = cleared.includes(",") ? cleared.split(",") : [cleared]
      const rub = splittedAmt[0]
      const kop = (splittedAmt[1] || "").slice(0, 2)

      const rubleF = formatRuble(rub).join("")
      const v = rubleF.length ? `${rubleF},${kop}` : ""

      setV(v)
      onChangeInput(v)
    } else {
      const rubleF = formatRuble(cleared).join("")
      const v = rubleF.length ? rubleF : ""

      setV(v)
      onChangeInput(v)
    }
  }

  return (
    <div className={s["currency-input"]}>
      <div>
        <input
          type="text"
          {...props}
          value={value}
          ref={textareaRef}
          onChange={(e: any) => {
            onChangeSumm(e)
          }}
          // onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          //   const start = textareaRef.current.selectionStart
          //   const end = textareaRef.current.selectionEnd

          //   if (e.code === "Backspace") {
          //     return
          //   }
          //   if (e.code === "ArrowLeft") {
          //     if (value.charAt(start - 2) === " ") {
          //       textareaRef.current.selectionStart = start - 1
          //       textareaRef.current.selectionEnd = end - 1
          //     }
          //     return
          //   }
          //   if (e.code === "ArrowRight") {
          //     if (value.charAt(end) === " ") {
          //       textareaRef.current.selectionStart = start + 1
          //       textareaRef.current.selectionEnd = end + 1
          //     }
          //     return
          //   }
          // }}
          // onClick={() => {
          //   const start = textareaRef.current.selectionStart
          //   const end = textareaRef.current.selectionEnd
          //   if (start === end && value.charAt(start - 1) === " ") {
          //     textareaRef.current.selectionStart = start - 1
          //     textareaRef.current.selectionEnd = end - 1
          //   }
          // }}
          className={s["input"]}
        />
      </div>
      <div className={s["input-copy"]}>
        <p className={s["input-copy__value"]}>{v}</p>
        <p className={s["input-copy__currency"]}>{v && currencySymbol}</p>
      </div>
    </div>
  )
}
