import set from "lodash.set"

export const RUR = {
  code: "810",
  symbol: "₽",
  alpha3Code: "RUR",
  label: "Российский рубль",
}

export const RUB = {
  ...RUR,
  code: "643",
}

export const USD = {
  code: "840",
  symbol: "$",
  alpha3Code: "USD",
  label: "Доллар США",
}

export const EUR = {
  code: "978",
  symbol: "\u20AC",
  alpha3Code: "EUR",
  label: "Евро",
}

export const ALL_CURRENCIES = [RUR, RUB, USD, EUR]
const DEFAULT_CURRENCY = ALL_CURRENCIES[0]
const DEFAULT_CURRENCY_CODE = DEFAULT_CURRENCY.code
const CURRENCIES_SYMBOLS: { [key: string]: string } = ALL_CURRENCIES.reduce(
  (symbols, currency) => set(symbols, currency.code, currency.symbol),
  {}
)

export const getCurrencyCode = (code: string): string =>
  code || DEFAULT_CURRENCY_CODE

export const getCurrencySymbol = (code: string) =>
  CURRENCIES_SYMBOLS[getCurrencyCode(code)]
