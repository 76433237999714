import { CardNav } from "components/card/card-main/card-nav/CardNav"
import { ContentLayout } from "components/generic/modular/ContentLayout"
import { Aside } from "components/generic/modular/Aside"
import { Outlet, useLocation } from "react-router-dom"
import React, { useEffect } from "react"
import { MainContent } from "components/generic/modular/MainContent"
import { routes } from "config"
import { useSelector } from "react-redux"
import { settingsSelector } from "store/settings/SettingsActions"

export const CardPage: React.FC = () => {
  useEffect(() => {
    document.title = "Карта"
  }, [])

  const { windowSize } = useSelector(settingsSelector)
  const path = useLocation().pathname
  const isMain = path === routes.card.path || path === routes.card.path + "/"

  return (
    <ContentLayout>
      <>
        <Aside>
          <CardNav />
        </Aside>
        <MainContent>
          <div
            style={{
              display: isMain && windowSize === "MOBILE" ? "none" : "block",
            }}
          >
            <Outlet />
          </div>
        </MainContent>
      </>
    </ContentLayout>
  )
}
