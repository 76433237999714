import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLatestPhoneTransfers } from "store/latest-phone-transfers/LatestPhoneTransfersActions"
import { PhoneTransfers } from "store/latest-phone-transfers/LatestPhoneTransfers"
import { AppState } from "store/store"
import { PhoneListView } from "./PhoneListView"

interface PhoneListContainerProps {
  onSelect: (phone: string) => void
}

const modifyDataProps = (data: PhoneTransfers[]) =>
  data.map((item) => ({
    ...item,
    lastNameShortened: item.lastName ? item.lastName.substring(0, 1) + "." : "",
  }))

export const PhoneListContainer: React.FC<PhoneListContainerProps> = ({
  onSelect,
}) => {
  const dispatch = useDispatch()
  const {
    data: { data, pending },
  } = useSelector(lastestPhoneTransfersSelector)
  const initialData = data || []

  const [opened, setOpened] = useState(false)
  const [searchData, setSearchData] = useState(initialData || [])
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (!data?.length) dispatch(getLatestPhoneTransfers())

    const filtered = searchData.filter((v) => {
      return (v.name + " " + v.lastName + " " + v.phone)
        .toLowerCase()
        .includes(search.trim().toLowerCase())
    })

    if (search !== "") {
      setSearchData(filtered)
    } else {
      setSearchData(initialData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, search])

  return (
    <PhoneListView
      previewData={modifyDataProps(initialData).slice(0, 5)}
      fullData={modifyDataProps(searchData)}
      pending={pending}
      opened={opened}
      setOpened={() => setOpened(!opened)}
      searchValue={search}
      onChangeSearch={(e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value)
      }}
      onClick={(phone: string) => {
        onSelect(phone || "")
      }}
    />
  )
}

const lastestPhoneTransfersSelector = ({ latestPhoneTransfers }: AppState) =>
  latestPhoneTransfers
