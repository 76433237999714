import s from "./profile-compact.module.scss"
import { Link } from "react-router-dom"
import { routes } from "../../../config"
import {
  AvatarImage,
  AvatarProps,
} from "components/generic/avatar-image/AvatarImage"
import { useSelector } from "react-redux"
import { userSelector } from "store/user/UserActions"
import { Formatter } from "utils/Formatter"

interface ProfileCompactProps {
  size?: "size-default" | "size-big"
  orientation?: "x" | "y"
  onlyAvatar?: boolean

  avatarSize?: AvatarProps["size"]
  withBorder?: AvatarProps["withBorder"]
}

export const ProfileCompact: React.FC<ProfileCompactProps> = ({
  size = "size-default",
  orientation = "x",
  onlyAvatar,
  avatarSize,
  withBorder = true,
}) => {
  const { qUser } = useSelector(userSelector)

  const profileLink = routes.profile.path
  const nickname = qUser?.telegramNickname || ""
  const phone = Formatter.formatPhoneNumber(qUser?.phone || "")
  const name = (qUser?.firstName || "") + " " + (qUser?.lastName || "")

  return (
    <div
      className={`${s["profile-compact"]} 
      ${s[`profile-compact-${orientation}`]}
      ${s[`profile-compact-${size}`]}
      `}
    >
      {!onlyAvatar && (
        <div className={s["userdata"]}>
          {name.length > 1 && (
            <h4 className={s["username"]}>
              <Link to={profileLink}>{name}</Link>
            </h4>
          )}
          <div className={s["contacts"]}>
            {phone ? <p className={s["contact"]}>{phone}</p> : ""}
            {phone && nickname ? (
              <p className={`${s["contact"]} ${s["dot"]}`}>·</p>
            ) : (
              ""
            )}
            {nickname ? <p className={s["contact"]}>{nickname}</p> : ""}
          </div>
        </div>
      )}
      <div className={s["avatar"]}>
        <Link to={profileLink}>
          <AvatarImage
            avatar={{
              avatarImage: qUser?.avatarImage || null,
              backgroundColor: qUser?.avatarBackground || null,
            }}
            size={avatarSize}
            withBorder={withBorder}
          />
        </Link>
      </div>
    </div>
  )
}
