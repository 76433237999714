import s from "./transfer-limit.module.scss"
import { ReactComponent as ArrowsCrossingIcon } from "components/icon/arrows-crossing.svg"
import { PrettyAmount } from "components/pretty-amount/PrettyAmount"

interface TransferLimitViewProps {
  summ: number
  currency: string
  until: string
  progressPercentage: number
}

export const TransferLimitView: React.FC<TransferLimitViewProps> = ({
  summ,
  until,
  progressPercentage,
}) => {
  return (
    <div className={s["transfer-limit"]}>
      <div className={s["transfer-limit__icon"]}>
        <ArrowsCrossingIcon />
      </div>
      <div className={s["transfer-limit__content"]}>
        <p className={s["transfer-limit__title"]}>Переводы</p>
        <p className={s["transfer-limit__text"]}>
          Осталось{" "}
          <span>
            <PrettyAmount amount={summ} currencyCode={"643"} />
          </span>{" "}
          до {until}
        </p>

        <div className={s["progress-bar"]}>
          <div
            className={s["progress-bar__progress"]}
            style={{ width: progressPercentage + "%" }}
          />
        </div>
      </div>
    </div>
  )
}
