import s from "./legend.module.scss"
import { chunkArray } from "utils"
import { LegendItem } from "./LegendItem"

export interface LegendData {
  name: string
  background: string
  active: boolean
  amount: number
  currency: string
  mcc: string[]
}

export interface HistoryLegendProps {
  legendData: LegendData[]
  onClickHideLegend: (legend: LegendData) => void
}

export const HistoryLegend: React.FC<HistoryLegendProps> = ({
  legendData,
  onClickHideLegend,
}) => {
  const chunks = chunkArray(legendData, 5)
  // const [restShown, setRestShown] = useState(false)

  return (
    <div className={s["legend"]}>
      {chunks.length && (
        <>
          {chunks[0].map((item, i) => (
            <LegendItem
              key={i}
              legend={item}
              onClickHideLegend={onClickHideLegend}
            />
          ))}
          {chunks[1] && (
            <>
              {/* <LegendItem
                legend={{
                  active: !restShown,
                  name: "Остальное",
                  amount: 0,
                  currency: "",
                  background: "#000000",
                  mcc: [],
                }}
                onClickHideLegend={() => setRestShown(!restShown)}
              /> */}

              {/* {restShown &&
                chunks[1].map((item, i) => ( */}

              {chunks[1].map((item, i) => (
                <LegendItem
                  key={i}
                  legend={item}
                  onClickHideLegend={onClickHideLegend}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  )
}
