import s from "./otp-confirm.module.scss"
import { ApiResponseError } from "store/types"
import { ErrorsBox } from "components/generic/errors-container/ErrorsBox"
import { InputWithButton } from "components/generic/input/input-with-button/InputWithButton"
import { InputPhone } from "components/generic/vendor/input-phone/InputPhone"
import { ReactComponent as ArrowRightIcon } from "components/icon/arrow-right.svg"
import { InputText } from "components/generic/input/input-text/inputText"
import { Formatter } from "utils/Formatter"
import { ActionButton } from "components/generic/button/action-button/ActionButton"
import { isEnterKeyPressed } from "utils"

interface OtpConfirmViewProps {
  title: string

  phone: { value: string; valid: boolean }
  otp: { value: string; valid: boolean }
  onChangePhone: (v: string) => void
  onChangeOtp: (v: string) => void
  onClickForward: () => void
  onClickBackward: () => void
  onResendOtp: () => void

  step: number
  countdown: null | number
  errors: ApiResponseError[]
}

export const OtpConfirmView: React.FC<OtpConfirmViewProps> = ({
  title,
  phone,
  otp,
  onChangePhone,
  onChangeOtp,
  onClickForward,
  onClickBackward,
  onResendOtp,
  step,
  countdown,
  errors,
  children,
}) => {
  const onForward = () => phone.valid && onClickForward && onClickForward()
  const formattedPhone = Formatter.formatPhoneNumber(phone.value)

  return (
    <div className="page-content-mv-restricted">
      <div className={s["otp-confirm"]}>
        <div style={{ display: step === 0 ? "block" : "none" }}>
          <h2 className="page-title">{title}</h2>
          <div>
            <InputWithButton
              input={
                <InputPhone
                  value={phone.value}
                  onChangeValue={onChangePhone}
                  onEnterKeyPress={onForward}
                  valid={phone.valid && errors.length === 0}
                />
              }
              buttonContent={<ArrowRightIcon />}
              shouldDisplayButton={phone.valid}
              onClickButton={onForward}
            />
            <ErrorsBox errors={errors} />
            {children}
          </div>
        </div>
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <h2 className="page-title">Введите код</h2>
          <div className={s["otp-step"]}>
            <div className={s["otp-step__back"]}>
              <ActionButton
                icon={<ArrowRightIcon />}
                onClick={onClickBackward}
              />
            </div>
            <div className="">
              <InputWithButton
                input={
                  <InputText
                    placeholder="Код подтверждения"
                    value={otp.value}
                    onChange={(e) => {
                      onChangeOtp(e.currentTarget.value)
                    }}
                    onKeyDown={(e) => {
                      if (isEnterKeyPressed(e)) onForward()
                    }}
                  />
                }
                buttonContent="Отправить"
                shouldDisplayButton={otp.valid} // TODO
                onClickButton={onForward}
              />
            </div>
          </div>

          {countdown != null && countdown >= 1 && (
            <p className="small-note ">
              На номер {formattedPhone} был отправлен код подтверждения, можно
              отправить повторно через {countdown} секунд
            </p>
          )}
          {countdown != null && countdown === 0 && (
            <p className="small-note ">
              <span className="color-blue pointer" onClick={onResendOtp}>
                Отправить код
              </span>{" "}
              повторно на номер {formattedPhone}
            </p>
          )}

          <ErrorsBox errors={errors} />
        </div>
      </div>
    </div>
  )
}
