import { ApiResponseError } from "store/types"
import { Auth } from "./Auth"
import { AuthActionTypes, PayloadedAuthAction } from "./AuthActions"

interface AuthData extends Auth {
  pending: boolean
  errors: ApiResponseError[]
}

export interface AuthState {
  readonly data: AuthData
}

const initialState: { data: AuthData } = {
  data: {
    token: "",
    pk: "",
    pending: false,
    errors: [],
  },
}

export const AuthReducer = (
  state: AuthState = initialState,
  payloadedAction: PayloadedAuthAction
): AuthState => {
  if (payloadedAction.payload) {
    const {
      payload: { token, pk, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case AuthActionTypes.AUTH_KEY_REQUEST:
        return {
          data: {
            ...stateData,
            pending: true,
          },
        }
      case AuthActionTypes.AUTH_KEY_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            pending: false,
            errors: [],
          },
        }
      case AuthActionTypes.AUTH_KEY_SET:
        return {
          data: {
            ...stateData,
            token,
            pk,
            pending: false,
            errors: [],
          },
        }
      case AuthActionTypes.AUTH_KEY_REQUEST_FAILTURE:
        return {
          data: {
            ...stateData,
            pending: false,
            errors,
          },
        }
      case AuthActionTypes.AUTH_KEY_REMOVE: {
        return initialState
      }

      default:
        return state
    }
  }

  return state
}
