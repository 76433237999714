import "react-calendar/dist/Calendar.css"
import { ReactComponent as CalendarIcon } from "components/icon/calendar.svg"
import { checkEnabled, dateToPeriod, periodTitle, periodToDate } from "./utils"
import { ReactComponent as Arrow } from "components/icon/arrow-next.svg"
import React, { useEffect, useState } from "react"
import { Period } from "components/history/utils"
import s from "./custom-calendar.module.scss"
import Calendar from "react-calendar"
import { Time } from "utils/Time"

export interface CustomCalendarProps {
  currentPeriod: Period
  onCalendarChanged: (period: Period) => void
  selectRange?: boolean
  displayAs?: "blocks" | "text" | "icon"
}

export const CustomCalendar: React.FC<CustomCalendarProps> = ({
  currentPeriod,
  onCalendarChanged,
  displayAs = "blocks",
  selectRange = true,
}) => {
  // https://github.com/wojtekmaj/react-calendar
  const [period, setPeriod] = useState(periodToDate(currentPeriod))
  const [title, setTitle] = useState(periodTitle(currentPeriod))
  const [hidden, setHidden] = useState(true)

  const [isNextEnabled, setIsNextEnabled] = useState(
    checkEnabled(currentPeriod)
  )

  const changeHidden = () => {
    setHidden(!hidden)
    // Call only if calendar is not visible to prevent call API each time user clicks calendar day
    if (!hidden) onCalendarChanged(dateToPeriod(period))
  }

  const onClickCalendar = (period: { begin: Date; end: Date }) => {
    setTitle(periodTitle(period))
    setPeriod(period)
  }

  const onClickControls = (shouldIncrement: boolean) => {
    const p = dateToPeriod(period)
    const shiftedDates = Time.shiftDates(p.begin, p.end, shouldIncrement)

    onCalendarChanged(shiftedDates)
    setPeriod(periodToDate(shiftedDates))
    setTitle(periodTitle(shiftedDates))
  }

  useEffect(() => {
    setPeriod(periodToDate(currentPeriod))
    setIsNextEnabled(checkEnabled(currentPeriod))
    setTitle(periodTitle(currentPeriod))
  }, [currentPeriod])

  return (
    <div>
      {!hidden ? <div className={s["overlay"]} onClick={changeHidden} /> : ""}

      <div
        className={`${s["controls"]} 
        ${displayAs === "text" ? s["controls-text"] : ""}
        ${displayAs === "icon" ? s["controls-icon"] : ""}
        `}
      >
        <div
          className={`${s["control"]} ${s["control-back"]}`}
          onClick={() => {
            onClickControls(false)
          }}
        >
          <div className={s["control__img"]}>
            <Arrow />
          </div>
        </div>

        <div className={s["period"]} onClick={changeHidden}>
          <div className={s["period__img"]}>
            <CalendarIcon />
          </div>
          <div className={s["period__text"]}>{title}</div>
        </div>

        <div
          className={`${s["control"]} ${s["control-forward"]} 
            ${isNextEnabled ? "" : s["control-disabled"]}`}
          onClick={() => {
            if (isNextEnabled) onClickControls(true)
          }}
        >
          <div className={s["control__img"]}>
            <Arrow />
          </div>
        </div>
      </div>
      <div className={s["calendar-wrapper"]}>
        <div
          className={`${s["calendar"]}
          ${displayAs === "icon" ? s["calendar-icon"] : ""}`}
        >
          {!hidden ? (
            <>
              <Calendar
                onChange={(value: Date | Date[]) => {
                  const isDateArray = Array.isArray(value)
                  const begin = isDateArray ? value[0] : value
                  let end = null
                  if (isDateArray && value[1]) {
                    end = value[1]
                    if (begin.toDateString() === end.toDateString()) end = null
                  }

                  if (Array.isArray(value) && value.length === 2)
                    onClickCalendar({ begin, end: !end ? begin : end })
                }}
                onClickDay={(value) => {
                  onClickCalendar({ begin: value, end: value })
                }}
                value={
                  period.end && selectRange
                    ? [period.begin, period.end]
                    : period.begin
                }
                selectRange={selectRange}
                maxDate={new Date()}
              />
              <div className={s["calendar__close"]}>
                <span onClick={changeHidden}>Готово</span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  )
}
