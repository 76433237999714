import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import s from "./bar-chart.module.scss"
import { Time } from "utils/Time"
import { _moment } from "index"

export type ChartBarData = {
  [x: string]: {
    name: string
    background: string
    active: boolean
    amount: number
    currency: string
    mcc: string[]
  }[]
}

export interface BarChartProps {
  barChartData: ChartBarData
}

export const BarChart: React.FC<BarChartProps> = ({ barChartData }) => {
  const onlyActive = Object.entries(barChartData).reduce(
    (acc: ChartBarData, curr) => {
      const [k, v] = curr
      acc[k] = v.filter((legend) => legend.active)

      return acc
    },
    {}
  )

  const groupsMax = Math.max(
    ...Object.values(onlyActive).map((group) =>
      group.reduce((acc, curr) => {
        return acc + curr.amount
      }, 0)
    )
  )

  let decrementalZIndex = 5000

  return (
    <div className={s["wrapper"]}>
      <div className={`${s["amount"]} ${s["amount-max"]}`}>
        <PrettyAmount amount={groupsMax} currencyCode={"643"} />
      </div>

      <div className={s["chart"]}>
        {Object.entries(onlyActive).map(([k, v], index) => {
          const dates = Time.matchDates(k)
          const legend = dates
            ? dates.length === 1
              ? Time.formatMoment(_moment(dates[0]), "DD MMM")
              : `${_moment(dates[0]).format("D")}-${_moment(dates[1]).format(
                  "D"
                )}`
            : ""

          return (
            <div className={s["bars"]} key={k}>
              <div
                className={s["bar"]}
                style={{ position: "relative", zIndex: --decrementalZIndex }}
              >
                <div className={s["columns"]}>
                  {v?.length ? (
                    v.map((item, i) => {
                      const perc = ((item.amount / groupsMax) * 100).toFixed(2)
                      const zIndex = ++index + "" + ++i

                      return (
                        <div
                          key={perc + item.amount}
                          className={s["column"]}
                          style={
                            {
                              "--bar-col-height": perc + "%",
                              backgroundColor: item.background,
                              zIndex,
                            } as React.CSSProperties
                          }
                        >
                          <div className={s["tooltip"]}>
                            <span className={s["tooltip__name"]}>
                              {item.name}
                            </span>
                            <span className={s["tooltip__amount"]}>
                              <PrettyAmount
                                amount={item.amount}
                                currencyCode={item.currency}
                              />
                            </span>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div
                      className={s["column"]}
                      style={
                        {
                          "--bar-col-height": "0%",
                          backgroundColor: "#E5E5E5",
                          zIndex: 10,
                        } as React.CSSProperties
                      }
                    />
                  )}
                </div>
                <span className={s["legend"]}>{legend}</span>
              </div>
            </div>
          )
        })}
      </div>

      <div className={`${s["amount"]} ${s["amount-min"]}`}>
        <PrettyAmount amount={"000"} currencyCode={"643"} shouldHideKop />
      </div>
    </div>
  )
}
