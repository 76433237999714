import { UpdateUser } from "components/profile/profile-main/nav-items/identification/UpdateUser"
import { ProfileStatusContainer } from "components/profile/profile-status/ProfileStatusContainer"
import { AvatarChanger } from "components/profile/profile-main/nav-items/avatar/AvatarChanger"
import { Invite } from "components/profile/profile-main/nav-items/invite/Invite"
import { UserData } from "components/profile/profile-main/nav-items/user-data/UserData"
import { CloseableBlock } from "components/generic/modular/CloseableBlock"
import { Help } from "components/profile/profile-main/nav-items/help/Help"
import { Transfer } from "components/card-operations/transfer/Transfer"
import { Deposit } from "components/card-operations/deposit/Deposit"
import { ProfilePage } from "pages/account/profile/ProfilePage"
import { PrivateLayout } from "components/PrivateLayout"
import { CardPage } from "pages/account/card/CardPage"
import { MainPage } from "pages/account/main/MainPage"
import { Signin } from "components/otp-confirm/Signin"
import { Signup } from "components/otp-confirm/Signup"
import { Landing } from "components/landing/Landing"
import {Routes, Route, useNavigate, useSearchParams} from "react-router-dom"
import { Layout } from "components/Layout"
import { routes, cfg } from "./config"
import {useEffect} from "react"
import { useDispatch } from "react-redux"
import { resizeWindow } from "store/settings/SettingsActions"
import { Statement } from "components/history/Statement"
import {useAppSelector} from "./store/hooks";

export const App: React.FC = () => {
  const dispatch = useDispatch()
  const { info, avatar, invite, help, identification } = routes.profile.c
  const { transfer, deposit } = routes.personal.c
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data);

  const [searchParams, setSearchParams] = useSearchParams();

  const onResizeWindow = () =>
    !isNaN(window.innerWidth) && dispatch(resizeWindow(window.innerWidth))

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "tg_script";
    script.src = "https://telegram.org/js/telegram-web-app.js";
    script.async = true;

    document.getElementsByTagName("head")[0].appendChild(script);
  }, [])

  useEffect(() => {
    // alert(searchParams.get("phone"))
    // @ts-ignore
    document.getElementById("tg_script").addEventListener("load", () => {
      // @ts-ignore
      if (window.Telegram) {
        // @ts-ignore
        const webApp = window.Telegram.WebApp;
        const colorScheme = webApp.colorScheme;
        const themeParams = webApp.themeParams;
        dispatch({type: "THEME_PARAMS_RECEIVED", payload: {data: {colorScheme: colorScheme, bgColor: themeParams.bg_color}}})
        const initData = webApp.initDataUnsafe;
        let receiver = initData.receiver;
        if (receiver) {
          //TODO запрос на проверку пользователя по id
          const isUser = receiver.username === "qwerty123bot";
          if (isUser)
            dispatch({type: "IN_CHAT_WITH_Q_USER", payload: {data: {isInChat: true, isQUser: true, id: receiver.id, firstName: receiver.firstName, lastName: receiver.lastName, username: receiver.username}}});
          else
            dispatch({type: "IN_CHAT_WITH_NOT_USER", payload: {data: {isInChat: true, isQUser: false, id: receiver.id, firstName: receiver.firstName, lastName: receiver.lastName, username: receiver.username}}});
        }
      }
      dispatch({type: "THEME_PARAMS_RECEIVED", payload: {data: {colorScheme: "dark", bgColor: "black"}}})
      navigate("/login");
    })
  }, [])

  useEffect(() => {
    window.addEventListener("resize", onResizeWindow)
    return () => window.removeEventListener("resize", onResizeWindow)
  })

  useEffect(() => {
    dispatch(resizeWindow(window.innerWidth))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Routes>
        <Route path={routes.index.path} element={<Layout />}>
          <Route index element={<Landing />} />
          <Route
            path={routes.index.c.login}
            element={
              <div className="hero-layout">
                <Signin />
              </div>
            }
          />
          <Route
            path={routes.index.c.register}
            element={
              <div className="hero-layout">
                <Signup />
              </div>
            }
          >
            <Route
              path={":partner"}
              element={
                <div className="hero-layout">
                  <Signup />
                </div>
              }
            />
          </Route>
        </Route>

        <Route element={<PrivateLayout />}>
          <Route path={routes.personal.path} element={<MainPage />}>
            <Route index element={<Statement checkDevice={true} />} />
            <Route
              path={deposit}
              element={
                <CloseableBlock
                  title="Пополнение"
                  closePath={routes.personal.path}
                >
                  <Deposit />
                </CloseableBlock>
              }
            />
            <Route
              path={transfer}
              element={
                <CloseableBlock
                  title="Перевод"
                  closePath={routes.personal.path}
                >
                  <Transfer />
                </CloseableBlock>
              }
            />
            <Route
              path={invite.path}
              element={
                <CloseableBlock
                  title="Пригласить"
                  closePath={routes.personal.path}
                  fullwidth
                >
                  <Invite />
                </CloseableBlock>
              }
              />
          </Route>
          <Route path={routes.profile.path} element={<ProfilePage />}>
            <Route
              index
              element={
                <CloseableBlock title="Персональные данные" fullwidth>
                  <UserData />
                </CloseableBlock>
              }
            />
            <Route
              path={info.path}
              element={
                <CloseableBlock
                  title="Персональные данные"
                  closePath={routes.profile.path}
                  fullwidth
                >
                  <UserData />
                </CloseableBlock>
              }
            />
            <Route
              path={avatar.path}
              element={
                <CloseableBlock
                  title="Редактировать фото"
                  closePath={routes.profile.path}
                  fullwidth
                >
                  <AvatarChanger />
                </CloseableBlock>
              }
            />
            <Route
              path={invite.path}
              element={
                <CloseableBlock
                  title="Пригласить"
                  closePath={routes.personal.path}
                  fullwidth
                >
                  <Invite />
                </CloseableBlock>
              }
            />
            <Route
              path={help.path}
              element={
                <CloseableBlock
                  title="Помощь"
                  closePath={routes.profile.path}
                  fullwidth
                >
                  <Help />
                </CloseableBlock>
              }
            />
            <Route
              path={identification.path}
              element={
                <CloseableBlock
                  title="Идентификация"
                  closePath={routes.profile.path}
                  fullwidth
                >
                  <UpdateUser />
                </CloseableBlock>
              }
            />
          </Route>
          <Route
            path={routes.card.path}
            element={
              <CloseableBlock
                closePath={routes.personal.path}
                fullwidth
              >
                <CardPage />
              </CloseableBlock>
            }>
            <Route
              index
              element={
                <CloseableBlock title="Откройте больше">
                  <ProfileStatusContainer />
                </CloseableBlock>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
