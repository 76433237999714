const HUNDRED = 100
const THOUSAND = 10 * HUNDRED
const MILLION = 1000 * THOUSAND
const BILLION = 1000 * MILLION

/**
 *
 * @param amt - amount string (78 100,45₽)
 * @returns object { rub: 78100, kop: 45 }
 */
export const clearAmount = (
  amt: string
): { rub: string; kop: string } | null => {
  if (!amt) return null

  const splittedAmt = (amt.includes(",") ? amt.split(",") : [amt]).map((v) =>
    v.replace(/\D/gi, "")
  )
  const rub = splittedAmt[0]
  const kop = (splittedAmt[1] || "").slice(0, 2)

  return { rub, kop }
}

export const formatAmount = (
  amt: string,
  currencySymbol?: string
): string | null => {
  if (!amt) return null

  const curr = currencySymbol || ""
  const clearedAmt = clearAmount(amt)

  if (clearedAmt) {
    const rub = formatRuble(clearedAmt?.rub).join(" ")
    const kop = clearedAmt.kop
    if (!kop) return rub + curr

    return [rub, kop].join(",") + curr
  }

  return null
}

export const formatRuble = (rubles: string) => {
  const rub = Math.trunc(+rubles)
  const parts = []
  if (rub >= BILLION) parts.push(Math.trunc(rub / BILLION))
  if (rub >= MILLION) parts.push(Math.trunc((rub % BILLION) / MILLION))
  if (rub >= THOUSAND) parts.push(Math.trunc((rub % MILLION) / THOUSAND))
  parts.push(Math.trunc(rub % THOUSAND))

  if (parts.length === 1 && parts[0] === 0) return []
  return parts.map((part, i) =>
    i > 0 ? part.toString().padStart(3, "0") : part.toString()
  )
}

/**
 *
 * @param rubWithKop - ruble string (200025: two thousand rouble and 25 kop)
 * @returns splitted ruble. rub as string[], kop as string
 */
export const splitAmount = (
  rubWithKop: string
):
  | {
      rubParts: string[]
      kop: string
    }
  | undefined => {
  let value = +rubWithKop
  if (isNaN(value)) return

  const rubles = rubWithKop
    .replace(/\./g, "")
    .substring(0, rubWithKop.length - 2)
  const kop = rubWithKop.substring(rubWithKop.length - 2)
  // let kop = Math.round((value - rub) * 100)
  //   .toString()
  //   .padStart(2, "0")

  let parts = formatRuble(rubles)

  return { rubParts: parts, kop }
}

export const removeKop = (amount: number): number => {
  const stringAmount = amount + ""

  return +stringAmount.substring(0, stringAmount.length - 2)
}
