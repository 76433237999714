import { AppState } from "../store"
import { Dispatch } from "redux"
import { AvatarPresets } from "./AvatarPresets"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum AvatarPresetsActionTypes {
  AVATAR_PRESETS_REQUEST = "AVATAR_PRESETS_REQUEST",
  AVATAR_PRESETS_REQUEST_FAILURE = "AVATAR_PRESETS_REQUEST_FAILURE",
  AVATAR_PRESETS_REQUEST_SUCCESS = "AVATAR_PRESETS_REQUEST_SUCCESS",
}

export interface PayloadedAvatarPresetsAction {
  type: AvatarPresetsActionTypes
  payload: {
    avatarPresets: AvatarPresets | null
    errors?: ApiResponseError[]
  }
}

export const avatarPresetsSelector = (state: AppState) =>
  state.avatarPresets.data

export const getAvatarPresets =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: AvatarPresetsActionTypes.AVATAR_PRESETS_REQUEST,
      payload: {},
    })

    fetcher({ url: "/avatar/presets", method: "GET" }).then(
      (res: ApiResponse<{ avatarPresets: AvatarPresets }>) => {
        if (res.success) {
          return dispatch<PayloadedAvatarPresetsAction>({
            type: AvatarPresetsActionTypes.AVATAR_PRESETS_REQUEST_SUCCESS,
            payload: { avatarPresets: res.data.avatarPresets },
          })
        } else {
          return dispatch<PayloadedAvatarPresetsAction>({
            type: AvatarPresetsActionTypes.AVATAR_PRESETS_REQUEST_FAILURE,
            payload: {
              avatarPresets: null,
              errors: res.errors,
            },
          })
        }
      }
    )
  }
