import { useEffect, useState } from "react"
import { getCurrencySymbol } from "utils/currency/currency"
import s from "../operations.module.scss"
import { Button } from "components/generic/button/button-default/Button"
import { InputCurrency } from "components/generic/input/input-currency/InputCurrency"
import {
  InputPan,
  PanInputData,
} from "components/generic/input/input-pan/InputPan"

export const TransferByCard: React.FC = () => {
  const [form, setForm] = useState<{
    isValid: boolean
    data: { summ: string; pan: PanInputData | null }
  }>({
    isValid: false,
    data: {
      summ: "",
      pan: null,
    },
  })

  useEffect(() => {
    setForm((form) => {
      return {
        ...form,
        isValid: form.data.summ !== "" && form.data.pan!.valid,
      }
    })
  }, [form.data.summ, form.data.pan])

  const onChangePan = (data: PanInputData) => {
    setForm({
      ...form,
      data: {
        ...form.data,
        pan: data,
      },
    })
  }

  const onChangeSumm = (v: string) => {
    setForm({
      ...form,
      data: {
        ...form.data,
        summ: v,
      },
    })
  }

  const onSubmitForm = () => {}

  return (
    <div className={s["operations"]}>
      <div className={s["operations__row"]}>
        <InputPan value={form.data.pan?.value || ""} onChange={onChangePan} />
      </div>
      <div className={s["operations__row"]}>
        <InputCurrency
          value={form.data.summ || ""}
          placeholder="Сумма"
          autoComplete="off"
          onChangeValue={onChangeSumm}
          currencySymbol={getCurrencySymbol("643")}
        />
        <p className={s["operations__fee"]}>
          Размер комиссии будет уточнен после ввода реквизитов
        </p>
      </div>
      <div className={s["operations__row"]}>
        <Button
          buttonText="Отправить"
          disabled={!form.isValid}
          onClick={onSubmitForm}
        />
      </div>
    </div>
  )
}
