import { InputText } from "components/generic/input/input-text/inputText"
import { ErrorsBox } from "components/generic/errors-container/ErrorsBox"
import { Button } from "components/generic/button/button-default/Button"
import s from "./identification.module.scss"
import { InputDate } from "components/generic/input/input-date/inputDate"
import React, { useEffect, useState } from "react"
import { Transformer } from "utils/Transformer"
import { Formatter } from "utils/Formatter"
import { useDispatch } from "react-redux"
import { userSelector, userUpdate } from "store/user/UserActions"
import { useAppSelector } from "store/hooks"

export const UpdateUser: React.FC = () => {
  const dispatch = useDispatch()
  const { minbClient, pending, errors } = useAppSelector(userSelector)

  const [data, setData] = useState({
    lastName: { value: "", valid: false },
    firstName: { value: "", valid: false },
    middleName: { value: "", valid: false },
    birthDate: { value: "", valid: false },
    passport: { value: "", valid: false },
    passportIssDate: { value: "", valid: false },
    passportIssBy: { value: "", valid: false },
  })

  const [isFormValid, setIsFormValid] = useState(false)

  const isFullData: boolean =
    minbClient?.status !== 1 ||
    (minbClient?.birthDate !== null &&
      minbClient?.passport !== null &&
      minbClient?.passportIssDate !== null &&
      minbClient?.passportIssBy !== null)

  const updateForm = (id: keyof typeof data, val: string) => {
    let valid = false
    if (
      val.length > 1 &&
      (id === "firstName" || id === "middleName" || id === "lastName")
    ) {
      valid = true
    }

    if (id === "birthDate" && val.length === 10) {
      valid = true
    }

    if (id === "passport" && val.replace(/\D/, "").length === 10) {
      valid = true
    }
    if (id === "passportIssDate" && val.length === 10) {
      valid = true
    }
    if (id === "passportIssBy" && val.length > 10) {
      valid = true
    }

    setData({
      ...data,
      [id]: { value: val, valid },
    })
  }

  const onSubmit = () => {
    if (isFormValid) {
      dispatch(
        userUpdate({
          firstName: data.firstName.value,
          middleName: data.middleName.value,
          lastName: data.lastName.value,

          birthDate: data.birthDate.value,

          passport: data.passport.value.replace(/\D/, ""),
          passportIssDate: data.passportIssDate.value,
          passportIssBy: data.passportIssBy.value,
        })
      )
    }
  }

  useEffect(() => {
    const valid = Object.values(data).filter((v) => !v.valid).length > 0
    setIsFormValid(!valid)
  }, [data])

  useEffect(() => {
    const d = {
      lastName: { value: minbClient?.lastName || "", valid: false },
      firstName: { value: minbClient?.firstName || "", valid: false },
      middleName: { value: minbClient?.middleName || "", valid: false },
      birthDate: { value: minbClient?.birthDate || "", valid: false },
      passport: { value: minbClient?.passport || "", valid: false },
      passportIssDate: {
        value: minbClient?.passportIssDate || "",
        valid: false,
      },
      passportIssBy: { value: minbClient?.passportIssBy || "", valid: false },
    }
    setData(d)
  }, [minbClient])

  return (
    <div className="update">
      <div className={s["rows"]}>
        <div className={`${s["row"]} ${s["row-personal"]}`}>
          <InputText
            placeholder="Фамилия"
            disabled={isFullData}
            onChange={(e) => {
              updateForm(
                "lastName",
                Transformer.replaceNonCyr(e.currentTarget.value)
              )
            }}
            value={data.lastName.value}
          />
          <InputText
            placeholder="Имя"
            onChange={(e) => {
              updateForm(
                "firstName",
                Transformer.replaceNonCyr(e.currentTarget.value)
              )
            }}
            value={data.firstName.value}
          />
          <InputText
            placeholder="Отчество"
            onChange={(e) => {
              updateForm(
                "middleName",
                Transformer.replaceNonCyr(e.currentTarget.value)
              )
            }}
            value={data.middleName.value}
          />
        </div>
        <div className={s["row"]}>
          <InputDate
            placeholder="Дата рождения"
            onChange={(e) => {
              updateForm("birthDate", e.currentTarget.value)
            }}
            onChangeCalendar={(date) => {
              updateForm("birthDate", date)
            }}
            value={data.birthDate.value}
          />
        </div>
        <div className={`${s["row"]} ${s["row-pass"]}`}>
          <div className="">
            <InputText
              placeholder="Серия и номер паспорта"
              onChange={(e) => {
                const v = e.currentTarget.value
                const digits = Transformer.replaceNonDigits(
                  e.currentTarget.value
                )

                let caret = e.currentTarget.selectionStart || 0

                if (v.length > data.passport.value.length) {
                  if (v.length >= 4) caret++
                } else {
                  if (v.length === 5) caret--
                }

                const element = e.currentTarget
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret
                  element.selectionEnd = caret
                })

                if (digits.length <= 10)
                  updateForm("passport", Formatter.formatPass(digits))
              }}
              value={data.passport.value}
            />
          </div>
          <div className="">
            <InputDate
              placeholder="Дата выдачи паспорта"
              onChange={(e) => {
                updateForm("passportIssDate", e.currentTarget.value)
              }}
              onChangeCalendar={(date) => {
                updateForm("passportIssDate", date)
              }}
              value={data.passportIssDate.value}
            />
          </div>
        </div>
        <div className={s["row"]}>
          <div className="">
            <InputText
              placeholder="Кем выдан"
              onChange={(e) => {
                updateForm("passportIssBy", e.currentTarget.value)
              }}
              value={data.passportIssBy.value}
            />
          </div>
        </div>
      </div>

      {!pending && !isFullData && (
        <>
          <p className="small-note">
            Отправляя свои персональные данные, вы даете согласие на их
            обработку
          </p>

          <ErrorsBox errors={errors || []} />

          <Button
            buttonText="Отправить"
            onClick={onSubmit}
            buttonSize="small"
            buttonStyle="blue"
            disabled={!isFormValid}
          />
        </>
      )}
    </div>
  )
}
