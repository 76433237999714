import s from "./avatar-changer.module.scss"
import { Button } from "components/generic/button/button-default/Button"
import { AvatarPresets } from "store/avatar-presets/AvatarPresets"
import { useMemo } from "react"
import {
  Avatar,
  AvatarImage,
} from "components/generic/avatar-image/AvatarImage"
import { chooseRandom, Optional } from "utils"
import { Tabs } from "components/generic/tabs/Tabs"
// import { SwiperCarousel } from "components/generic/vendor/swiper/SwiperCarousel"

export type ActivePresetType = "emoji" | "color" | "random"

export interface PresetBoxProps {
  avatarPresets: AvatarPresets
  currentAvatar: Avatar
  onConfirmChange: () => void
  isConfirmDisabled: boolean
  onAvatarChanged: (newAvatar: Optional<Avatar>) => void
}

export const Btn = ({
  text,
  active,
}: {
  text: string
  active?: boolean
}): JSX.Element => {
  return (
    <div className={`${s["btn"]} ${active ? s["btn-active"] : ""}`}>{text}</div>
  )
}

export const PresetsBox: React.FC<PresetBoxProps> = ({
  currentAvatar,
  avatarPresets,
  onConfirmChange,
  isConfirmDisabled,
  onAvatarChanged,
}) => {
  const activeBorderColor = "#6688ff"
  const randomized = useMemo(() => {
    const colorPresets = avatarPresets?.colorPresets || []
    const emojiPresets = avatarPresets?.emojiPresets || []

    return chooseRandom(emojiPresets, 12).map((em) => ({
      avatarImage: em.emoji,
      backgroundColor:
        colorPresets[Math.floor(Math.random() * colorPresets.length)].hexColor,
    }))
  }, [avatarPresets?.colorPresets, avatarPresets?.emojiPresets])

  const onConfirm = () => {
    if (!isConfirmDisabled && onConfirmChange) {
      onConfirmChange()
    }
  }

  const onChangeAvatar = (a: Optional<Avatar>) => {
    onAvatarChanged(a)
  }

  return (
    <div className={s["presets-box"]}>
      <Tabs
        tabList={[
          <Btn text="Emoji" />,
          <Btn text="Цвет" />,
          <Btn text="Случайные" />,
        ]}
        tabPanel={[
          // Emoji
          <div className={s["presets-box__presets"]}>
            <div className={s["presets"]}>
              {avatarPresets?.emojiPresets.map((e, i) => {
                const emoji = e?.emoji
                const isSelected = currentAvatar.avatarImage === emoji

                return (
                  <div
                    className={s["preset"]}
                    key={e.unicode + i}
                    onClick={() => onChangeAvatar({ avatarImage: emoji })}
                  >
                    <AvatarImage
                      avatar={{ avatarImage: emoji, backgroundColor: "" }}
                      withBorder
                      borderColor={isSelected ? activeBorderColor : "#f1f1f1"}
                      className={s["preset-avatar"]}
                    />
                  </div>
                )
              })}
            </div>
          </div>,
          // Color
          <div className={s["presets-box__presets"]}>
            <div className={s["presets"]}>
              {avatarPresets?.colorPresets.map((c, i) => {
                const backgroundColor = c.hexColor
                return (
                  <div
                    className={s["preset"]}
                    key={c.hexColor + i}
                    onClick={() => onChangeAvatar({ backgroundColor })}
                  >
                    <AvatarImage
                      avatar={{ avatarImage: null, backgroundColor }}
                      withBorder={
                        currentAvatar.backgroundColor === backgroundColor
                      }
                      borderColor={activeBorderColor}
                      className={s["preset-avatar"]}
                    />
                  </div>
                )
              })}
            </div>
          </div>,
          // Random
          <div className={s["presets-box__presets"]}>
            <div className={s["presets"]}>
              {randomized.map((p, i) => {
                const isSelected =
                  currentAvatar.avatarImage === p.avatarImage &&
                  currentAvatar.backgroundColor === p.backgroundColor

                return (
                  <div
                    className={s["preset"]}
                    key={i}
                    onClick={() => {
                      onChangeAvatar(p)
                    }}
                  >
                    <AvatarImage
                      avatar={p}
                      withBorder
                      borderColor={isSelected ? activeBorderColor : "#f1f1f1"}
                      className={s["preset-avatar"]}
                    />
                  </div>
                )
              })}
            </div>
          </div>,
        ]}
      />

      <div className={s["button-confirm"]}>
        <Button
          buttonText="Подтвердить"
          fullWidth
          disabled={isConfirmDisabled}
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}
