import { ApiResponseError } from "store/types"
import { Signup } from "./Signup"
import { SignupActionTypes, PayloadedSignupAction } from "./SignupActions"

interface SignupData extends Signup {
  pending: boolean
  wasOtpSent: boolean
  token: string
  errors: ApiResponseError[]
}

export interface SignupState {
  readonly data: SignupData
}

const initialState: { data: SignupData } = {
  data: {
    pending: false,
    wasOtpSent: false,
    token: "",
    errors: [],
  },
}

export const SignupReducer = (
  state: SignupState = initialState,
  payloadedAction: PayloadedSignupAction
): SignupState => {
  if (payloadedAction.payload) {
    const {
      payload: { errors, token, wasOtpSent },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const formState = state.data

    switch (payloadedAction.type) {
      case SignupActionTypes.SIGNUP_REQUEST:
        return {
          data: {
            ...formState,
            wasOtpSent: false,
            errors: [],
          },
        }
      case SignupActionTypes.SIGNUP_REQUEST_SUCCESS:
        return {
          data: {
            ...formState,
            errors: [],
          },
        }
      case SignupActionTypes.SIGNUP_REQUEST_FAILTURE:
        const otpRequiredError = (errors || []).some(
          (e) => e.textCode === "OTP_REQUIRED"
        )
        const otherErrors = (errors || []).filter(
          (e) => e.textCode !== "OTP_REQUIRED"
        )

        return {
          data: {
            ...formState,
            wasOtpSent:
              otpRequiredError && wasOtpSent !== undefined && wasOtpSent,
            errors: otherErrors || [],
          },
        }
      case SignupActionTypes.SIGNUP_SET_TOKEN:
        return {
          data: {
            ...formState,
            token: token!,
            errors: [],
          },
        }
      case SignupActionTypes.SIGNUP_CLEAR_FORM:
        return {
          ...initialState,
        }

      default:
        return state
    }
  }

  return state
}
