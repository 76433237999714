import { AppState } from "../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"
import { Faq } from "./Faq"

export enum FaqActionTypes {
  FAQ_REQUEST = "FAQ_REQUEST",
  FAQ_REQUEST_SUCCESS = "FAQ_REQUEST_SUCCESS",
  FAQ_REQUEST_FAILURE = "FAQ_REQUEST_FAILURE",
}

export interface PayloadedFaqAction {
  type: FaqActionTypes
  payload: {
    faq: Faq[] | null
    errors?: ApiResponseError[]
  }
}

export const faqSelector = (state: AppState) => state.faq.data

export const faqGet =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: FaqActionTypes.FAQ_REQUEST,
      payload: {},
    })

    fetcher({ url: "/faq", method: "GET" }).then(
      (res: ApiResponse<{ faq: Faq[] }>) => {
        if (res.success) {
          dispatch<PayloadedFaqAction>({
            type: FaqActionTypes.FAQ_REQUEST_SUCCESS,
            payload: { faq: res.data.faq },
          })
        } else {
          dispatch({
            type: FaqActionTypes.FAQ_REQUEST_FAILURE,
            payload: {
              data: {
                faq: {
                  errors: res.errors,
                },
              },
            },
          })
        }
      }
    )
  }
