import { OtpConfirm } from "./OtpConfirm"
import s from "./otp-confirm.module.scss"
import { routes } from "config"
import { Link } from "react-router-dom"
import { AppState } from "store/store"
import { useAppSelector } from "store/hooks"
import { SignupActions } from "store/forms/signup/SignupActions"

export const Signup: React.FC = () => {
  // let params = useParams()
  // console.log(params)

  return (
    <OtpConfirm
      title="Создайте аккаунт"
      onSubmit={SignupActions.sendRequest}
      onSuccessSubmit={SignupActions.resetState}
      state={useAppSelector((state: AppState) => state.signup)}
    >
      <p className={`${s["under-text"]} text-under-button`}>
        Уже есть в quanty?{" "}
        <Link to={"/" + routes.index.c.login} className="color-blue">
          Войти
        </Link>
      </p>
    </OtpConfirm>
  )
}
