import s from "./legend.module.scss"
import { PrettyAmount } from "components/pretty-amount/PrettyAmount"
import { ReactComponent as EyeOpened } from "components/icon/eye-opened.svg"
import { ReactComponent as EyeClosed } from "components/icon/eye-closed.svg"
import { GroupSpent } from "../utils"

export interface LegendItemProps {
  legend: GroupSpent
  onClickHideLegend: (legend: GroupSpent) => void
}

export const LegendItem: React.FC<LegendItemProps> = ({
  legend,
  onClickHideLegend,
}) => {
  const { active, name, amount, currency, background } = legend
  const backgroundColor = background

  return (
    <div
      className={`${s["legend-item"]} ${
        !active ? s["legend-item-disabled"] : ""
      }`}
      style={
        {
          "--bgc": backgroundColor,
        } as React.CSSProperties
      }
    >
      <span className={s["legend-item__name"]}>{name}</span>
      {amount > 0 && (
        <span className={s["legend-item__amt"]}>
          <PrettyAmount amount={amount} currencyCode={currency} />
        </span>
      )}
      <span
        className={s["active-indicator"]}
        onClick={() => onClickHideLegend(legend)}
      >
        <span
          className={s["active-indicator__bg"]}
          style={{ backgroundColor }}
        />
        <span className={s["active-indicator__img"]}>
          {active ? <EyeClosed /> : <EyeOpened />}
        </span>
      </span>
    </div>
  )
}
