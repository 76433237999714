import s from "./popup.module.scss"
import { ReactComponent as CloseIcon } from "components/icon/close.svg"
import { useEffect } from "react"

export interface PopupProps {
  onClosePopup: () => void
  withCloseButton?: boolean
  isShown: boolean
}

export const Popup: React.FC<PopupProps> = ({
  children,
  onClosePopup,
  isShown,
  withCloseButton = false,
}) => {
  const onClose = () => {
    document.body.style.overflow = "visible"
    onClosePopup && onClosePopup()
  }

  useEffect(() => {
    if (isShown) document.body.style.overflow = "hidden"
  }, [isShown])

  return (
    <div
      className={`${s["popup"]} ${
        withCloseButton ? s["popup-with-button"] : ""
      }`}
      style={{ display: isShown ? "flex" : "none" }}
    >
      <div className={s["popup__overlay"]} onClick={onClose} />

      <div className={s["popup__content"]}>
        <div className={s["content"]}>{children}</div>
        {withCloseButton && (
          <div className={s["popup__close"]} onClick={onClose}>
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  )
}
