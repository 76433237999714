import { ButtonIcon } from "components/generic/button/button-icon/ButtonIcon"
import { ReactComponent as InviteIcon } from "components/icon/invite.svg"
import { ReactComponent as PersonIcon } from "components/icon/person.svg"
import { ReactComponent as FireIcon } from "components/icon/fire.svg"
import { ReactComponent as EditIcon } from "components/icon/edit.svg"
import { ReactComponent as ChatIcon } from "components/icon/chat.svg"
import { CustomLink } from "components/custom-link/CustomLink"
import s from "./profile-nav.module.scss"
import { routes } from "config"

interface ProfileNavProps {}

export const ProfileNav: React.FC<ProfileNavProps> = () => {
  const { info, invite, identification, avatar, help } = routes.profile.c

  return (
    <aside className={s["profile-nav"]}>
      <CustomLink className={s["profile-nav__item"]} to={invite.path}>
        <ButtonIcon
          text="Пригласить"
          icon={<InviteIcon />}
          activeClass={"bg-blue"}
        />
      </CustomLink>
      <CustomLink className={s["profile-nav__item"]} to={identification.path}>
        <ButtonIcon
          text="V+ Card"
          addText="Остаток до 60 000 ₽ и переводы"
          icon={<FireIcon />}
          activeClass={"bg-blue"}
        />
      </CustomLink>
      {/* Profile is index */}
      <CustomLink className={s["profile-nav__item"]} to={info.path}>
        <ButtonIcon
          text="Персональные данные"
          icon={<PersonIcon />}
          activeClass={"bg-blue"}
        />
      </CustomLink>
      <CustomLink className={s["profile-nav__item"]} to={avatar.path}>
        <ButtonIcon
          text="Редактировать фото"
          icon={<EditIcon />}
          activeClass={"bg-blue"}
        />
      </CustomLink>
      <CustomLink className={s["profile-nav__item"]} to={help.path}>
        <ButtonIcon text="Помощь" icon={<ChatIcon />} activeClass={"bg-blue"} />
      </CustomLink>
    </aside>
  )
}
