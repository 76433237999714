import s from "../input.module.scss"

export interface InputTextProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  className?: string
  innerRef?: any
}

export const InputTextArea: React.FC<InputTextProps> = ({
  onChange,
  className = "",
  innerRef,
  ...props
}) => {
  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    onChange && onChange(e)

  return (
    <textarea
      rows={5}
      {...props}
      ref={innerRef}
      onChange={onChangeInput}
      className={`${s["input"]} ${s["textarea"]} ${className}`}
    />
  )
}
