import { ApiResponseError } from "store/types"
import { AvatarPresets } from "./AvatarPresets"
import {
  AvatarPresetsActionTypes,
  PayloadedAvatarPresetsAction,
} from "./AvatarPresetsActions"

interface AvatarPresetsData {
  avatarPresets: AvatarPresets | null
  pending: boolean
  errors: ApiResponseError[]
}

export interface AvatarPresetsState {
  readonly data: AvatarPresetsData
}

const initialState: { data: AvatarPresetsData } = {
  data: {
    avatarPresets: null,
    pending: false,
    errors: [],
  },
}

export const AvatarPresetsReducer = (
  state: AvatarPresetsState = initialState,
  payloadedAction: PayloadedAvatarPresetsAction
): AvatarPresetsState => {
  if (payloadedAction.payload) {
    const {
      payload: { avatarPresets, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case AvatarPresetsActionTypes.AVATAR_PRESETS_REQUEST:
        return {
          data: {
            ...stateData,
            pending: true,
            errors: [],
          },
        }
      case AvatarPresetsActionTypes.AVATAR_PRESETS_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            avatarPresets,
            pending: false,
            errors: [],
          },
        }
      case AvatarPresetsActionTypes.AVATAR_PRESETS_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            pending: false,
            errors: errors || [],
          },
        }
      default:
        return state
    }
  }

  return state
}
