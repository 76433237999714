import { Link } from "react-router-dom"
import s from "./styles.module.scss"

interface LabelPanelProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title: string
  button?: { title: string; navPath?: string; onClickButtonTitle?: () => void }
  textColor?: "black" | "dark-gray"
}

export const LabelPanel: React.FC<LabelPanelProps> = ({
  title,
  button,
  textColor = "black",
  ...props
}) => {
  return (
    <div
      className={`${s["label-panel"]} ${s[`label-panel-${textColor}`]}`}
      {...props}
    >
      <p className={s["label-panel__title"]}>{title}</p>
      {button && button.navPath && (
        <Link to={button.navPath} className={s["label-panel__button"]}>
          {button.title}
        </Link>
      )}
      {button &&
        !button.navPath &&
        typeof button.onClickButtonTitle === "function" && (
          <p
            onClick={button.onClickButtonTitle}
            className={s["label-panel__button"]}
          >
            {button.title}
          </p>
        )}
    </div>
  )
}
