import { ReactComponent as PersonCircleIcon } from "components/icon/person-circle.svg"
import { CloseableBlock } from "components/generic/modular/CloseableBlock"
import { ReactComponent as MobileIcon } from "components/icon/mobile.svg"
import { ReactComponent as EmailIcon } from "components/icon/email.svg"
import { EmailChanger } from "./EmailChanger"
import { List } from "components/generic/modular/List"
import { userSelector } from "store/user/UserActions"
import { useAppSelector } from "store/hooks"
import { Formatter } from "utils/Formatter"
import { useState } from "react"

export const UserData: React.FC = () => {
  const { qUser } = useAppSelector(userSelector)
  const noname = !qUser?.firstName && !qUser?.middleName && !qUser?.lastName

  const formsIds = { phone: "phone", email: "email" }
  const [popupsState, setPopupsState] = useState<{ [id: string]: boolean }>({
    [formsIds.phone]: false,
    [formsIds.email]: false,
  })

  const onClick = (id: string) => {
    setPopupsState({
      ...Object.keys(popupsState).reduce((acc, curr) => {
        return { ...acc, [curr]: false }
      }, {}),
      [id]: !popupsState[id],
    })
  }

  return (
    <div>
      <List
        listItems={[
          {
            value: noname
              ? "ФИО не указаны"
              : [qUser?.firstName, qUser?.middleName, qUser?.lastName]
                  .filter((v) => !!v)
                  .join(" "),
            icon: <PersonCircleIcon />,
            onClickEnabled: false,
          },
          {
            value: qUser?.phone
              ? Formatter.formatPhoneNumber(qUser.phone)
              : "Телефон не указан",
            icon: <MobileIcon />,
            onClick: () => onClick(formsIds.phone),
            onClickEnabled: false,
          },
          {
            value: qUser?.email || "Email не указан",
            icon: <EmailIcon />,
            onClick: () => onClick(formsIds.email),
            onClickEnabled: true,
          },
        ]}
      />

      <div
        className="inblock-popup"
        style={{ display: popupsState.email ? "block" : "none" }}
      >
        <CloseableBlock
          title="Почта"
          closeTitle="Назад"
          onClickClose={() => onClick(formsIds.email)}
        >
          <EmailChanger onSuccessSubmit={() => onClick(formsIds.email)} />
        </CloseableBlock>
      </div>
    </div>
  )
}
