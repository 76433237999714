import {Chat} from "./Chat";
import {PayloadedChatAction} from "./ChatActions";

export interface ChatState {
  readonly data: Chat
}

export const initialChatState: {data: Chat} = {
  data: {
    isInChat: false,
    isQUser: false,
    userId: "",
    firstName: "",
    lastName: "",
    username: ""
  }
}

export const ChatReducer = (state: ChatState = initialChatState, payloadedChatAction: PayloadedChatAction): ChatState => {
  if (payloadedChatAction.payload) {
    const stateData = state.data;

    if (payloadedChatAction.type === "IN_CHAT_WITH_Q_USER" || payloadedChatAction.type === "IN_CHAT_WITH_NOT_USER")
      return {
        data: payloadedChatAction.payload.data
      }
  }

  return state;
}