import { AppState } from "../store"
import { Dispatch } from "redux"
import { QUser, UserData } from "./User"
import { fetcher, Optional } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum UserActionTypes {
  USER_REQUEST = "USER_REQUEST",
  USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS",
  USER_REQUEST_FAILURE = "USER_REQUEST_FAILURE",

  USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE",

  USER_SET = "USER_SET",
  USER_LOGOUT = "USER_LOGOUT",
}

export interface PayloadedUserAction {
  type: UserActionTypes
  payload: {
    data: UserData | null
    errors: ApiResponseError[] | null
  }
}

export const userSelector = (state: AppState) => state.user.data

export const userGet =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: UserActionTypes.USER_REQUEST, payload: {} })

    fetcher({ url: "/users", method: "GET" }).then(
      (res: ApiResponse<{ user: UserData }>) => {
        if (res.success) {
          dispatch<PayloadedUserAction>({
            type: UserActionTypes.USER_REQUEST_SUCCESS,
            payload: {
              data: res.data.user,
              errors: [],
            },
          })
        } else {
          dispatch<PayloadedUserAction>({
            type: UserActionTypes.USER_REQUEST_FAILURE,
            payload: { data: null, errors: res.errors },
          })
        }
      }
    )
  }

export const userUpdate =
  (
    data: Optional<QUser> &
      Optional<{
        passport: string
        passportIssDate: string
        passportIssBy: string
      }>,
    otp?: string
  ) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: UserActionTypes.USER_UPDATE_REQUEST, payload: {} })

    fetcher({ url: "/users", method: "PUT", body: { otp, user: data } })
      .then((res: ApiResponse<{ user: UserData }>) => {
        if (res.success) {
          return dispatch<PayloadedUserAction>({
            type: UserActionTypes.USER_UPDATE_SUCCESS,
            payload: { data: res.data.user, errors: null },
          })
        } else {
          return dispatch<PayloadedUserAction>({
            type: UserActionTypes.USER_UPDATE_FAILURE,
            payload: { data: null, errors: res.errors },
          })
        }
      })
      .catch((e: Error) => {
        dispatch<PayloadedUserAction>({
          type: UserActionTypes.USER_UPDATE_FAILURE,
          payload: {
            data: null,
            errors: [],
          },
        })
      })
  }

export const userLogout =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: UserActionTypes.USER_LOGOUT, payload: {} })
  }
