import { CloseableBlock } from "components/generic/modular/CloseableBlock"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { Button } from "components/generic/button/button-default/Button"
import { ErrorsBox } from "components/generic/errors-container/ErrorsBox"
import { InputTextArea } from "components/generic/input/textarea/inputTextArea"
import { POPUPS_DISSAPEAR_TIMEOUT, routes } from "config"
import { Link } from "react-router-dom"
import { userSelector } from "store/user/UserActions"
import { useAppSelector } from "store/hooks"
import { ReactComponent as DoneStarIcon } from "components/icon/done-star.svg"
import { Popup } from "components/generic/popup/Popup"
import {
  supportResetResult,
  supportSelector,
  supportSend,
} from "store/forms/support/SupportActions"

export interface SupportProps {
  onClose?: () => void
}

export const Support: React.FC<SupportProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { qUser, errors } = useAppSelector(userSelector)
  const supportData = useAppSelector(supportSelector)

  const [data, setData] = useState({
    question: { value: "", valid: false },
  })

  const onChangeForm = (id: keyof typeof data, val: string) => {
    let valid = false

    if (id === "question" && val.length > 10) {
      valid = true
    }

    setData({
      ...data,
      [id]: { value: val, valid },
    })
  }

  const sendSupportRequest = () => {
    dispatch(supportSend(data.question.value))
  }

  const closePopup = () => {
    onClose && onClose()
  }

  useEffect(() => {
    if (supportData.sentTo) {
      setTimeout(() => {
        closePopup()
        dispatch(supportResetResult())
        onChangeForm("question", "")
      }, POPUPS_DISSAPEAR_TIMEOUT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportData])

  return (
    <div>
      <CloseableBlock
        title="Поддержка"
        closeTitle="Назад"
        onClickClose={onClose}
      >
        {qUser?.email ? (
          <div className="rows">
            <div className="row">
              <InputTextArea
                placeholder="Вопрос"
                value={data.question.value}
                onChange={(e) =>
                  onChangeForm("question", e.currentTarget.value)
                }
              />

              <p className="small-note">
                Опишите свою проблему и мы ответим
                {qUser?.email && (
                  <>
                    {" "}
                    на вашу почту
                    <br />
                    <span className="color-blue">{qUser?.email}</span>
                  </>
                )}
              </p>

              <ErrorsBox errors={errors || []} />
            </div>
            <div className="row">
              <Button
                buttonText="Отправить"
                disabled={!data.question.valid}
                onClick={sendSupportRequest}
              />
            </div>
          </div>
        ) : (
          <div className="rows">
            <div className="row" style={{ textAlign: "center" }}>
              Чтобы отправить запрос в поддержку, <br /> введите email
              <Link to={routes.profile.path} className="color-blue">
                {" "}
                в вашем профиле
              </Link>
            </div>
          </div>
        )}
      </CloseableBlock>

      <Popup onClosePopup={closePopup} isShown={supportData.sentTo !== null}>
        <div style={{ textAlign: "center" }}>
          <div className="popup-icon">
            <DoneStarIcon />
          </div>
          <p className="popup-text">
            Вопрос отправлен. <br />
            Ответ придет вам на почту
            {qUser?.email && (
              <>
                {" "}
                <br />
                <span className="color-blue">{qUser?.email}</span>
              </>
            )}
          </p>
        </div>
      </Popup>
    </div>
  )
}
