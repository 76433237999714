import { limitsGet, limitsSelector } from "store/cards/limits/LimitsActions"
import { cardsGet, cardsSelector } from "store/cards/cards/CardsActions"
import { userGet, userSelector } from "store/user/UserActions"
import { getMcc, mccSelector } from "store/mcc/MccActions"
import { PrivateFooter } from "./footer/PrivateFooter"
import { PrivateHeader } from "./header/PrivateHeader"
import { CookieManager } from "utils/CookieManager"
import {Navigate, Outlet, useNavigate} from "react-router-dom"
import { useAppSelector } from "store/hooks"
import { useDispatch } from "react-redux"
import { routes, cfg } from "config"
import { useEffect } from "react"
import { getHistory, historySelector } from "store/history/HistoryActions"
import { Time } from "utils/Time"

export const PrivateLayout: React.FC = () => {
  const userIsLogged = () => {
    return CookieManager.getTokenCookie() !== undefined
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userState = useAppSelector(userSelector)
  const mccState = useAppSelector(mccSelector)
  const cardsState = useAppSelector(cardsSelector)
  const limitsState = useAppSelector(limitsSelector)
  const historyState = useAppSelector(historySelector)

  const chatData = useAppSelector((state) => state.chat.data);

  useEffect(() => {
    if (!userState.qUser && !userState.pending) dispatch(userGet())
    if (!mccState.mcc && !mccState.pending) dispatch(getMcc())
    if (!cardsState.cards && !cardsState.pending) dispatch(cardsGet())
    if (!historyState.history && !historyState.pending) {
      dispatch(
        getHistory(
          Time.toIsoString(Time.now().startOf("month")),
          Time.toIsoString(Time.now().endOf("month"))
        )
      )
    }

    if (
      !cardsState.pending &&
      cardsState.primaryCard?.publicId &&
      !limitsState.limits &&
      !limitsState.pending
    ) {
      dispatch(limitsGet(cardsState.primaryCard?.publicId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // @ts-ignore
    const mainButton = window.Telegram.WebApp.MainButton;
    if (chatData.isInChat) {
      if (chatData.isQUser) {
        mainButton.setParams({text: "Перевести", color: "#6ed3fc", is_active: true, is_visible: true});
        mainButton.onClick(() => navigate("/personal/transfer"));
      } else {
        mainButton.setParams({text: "Пригласить", color: "#6ed3fc", is_active: true, is_visible: true});
        // mainButton.onClick(() => navigate("/profile/invite"));
        mainButton.onClick(() => {
          // @ts-ignore
          const queryId = window.Telegram.WebApp.initDataUnsafe.query_id;
          alert(queryId);
          // @ts-ignore
          alert(window.Telegram.WebApp.initDataUnsafe.can_send_after)
          const res = JSON.stringify({type: "photo", id: "1", photo_url: "https://www.vladtime.ru/uploads/posts/2018-03/1522438548_evropeyskaya-koshka-dikiy-kot.jpg", thumb_url: "https://www.vladtime.ru/uploads/posts/2018-03/1522438548_evropeyskaya-koshka-dikiy-kot.jpg", caption: "https://t.me/qwertyxyzBot"})
          fetch(`https://api.telegram.org/bot${cfg.TEST_BOT_TOKEN}/test/answerWebAppQuery?web_app_query_id=${queryId}&result=${res}`)
            .then(res => res.text())
            .then(res => alert(res));
        });
      }
    }
  }, [chatData, dispatch])

  return userIsLogged() ? (
    <>
      {/*<PrivateHeader />*/}
      <main className="layout container">
        <Outlet />
      </main>
      <PrivateFooter />
    </>
  ) : (
    <Navigate to={routes.index.c.login} />
  )
}
