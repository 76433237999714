import { Link } from "react-router-dom"
import { BackButton } from "../button/back-button/BackButton"
import s from "./styles.module.scss"

interface CloseableBlockProps {
  title?: string
  closeTitle?: string
  closePath?: string
  onClickClose?: () => void

  children: React.ReactNode
  fullwidth?: boolean
}

export const CloseableBlock: React.FC<CloseableBlockProps> = ({
  title,
  closeTitle = "Закрыть",
  closePath,
  onClickClose,
  children,
  fullwidth = false,
}) => {
  return (
    <div className={s["closeable-block"]}>
      {(title || closePath || onClickClose) && (
        <div className={s["closeable-block__head"]}>
          {title && <p className={s["closeable-block__title"]}>{title}</p>}
          {closePath && !onClickClose && (
            <div className={s["closeable-block-nav-link"]}>
              <Link
                className={s["closeable-block-nav-link__text"]}
                to={closePath}
              >
                {closeTitle}
              </Link>
              <div className={s["closeable-block-nav-link__icon"]}>
                <BackButton action={closePath} />
              </div>
            </div>
          )}
          {!closePath && typeof onClickClose === "function" && (
            <div className={s["closeable-block-nav-link"]}>
              <span
                onClick={onClickClose}
                className={s["closeable-block-nav-link__text"]}
              >
                {closeTitle}
              </span>
              <div className={s["closeable-block-nav-link__icon"]}>
                <BackButton action={onClickClose} />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={s["closeable-block__content"]}
        style={{ maxWidth: fullwidth ? "unset" : "" }}
      >
        {children}
      </div>
    </div>
  )
}
