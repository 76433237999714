import { ApiResponseError } from "store/types"
import { Cvv } from "./Cvv"
import { CvvActionTypes, PayloadedAction } from "./CvvActions"

interface CvvData {
  data: Cvv | null
  pending: boolean
  errors: ApiResponseError[] | null
}

export interface CvvState {
  readonly data: CvvData
}

const initialState: { data: CvvData } = {
  data: {
    data: null,
    
    pending: false,
    errors: null,
  },
}

export const CvvReducer = (
  state: CvvState = initialState,
  payloadedAction: PayloadedAction
): CvvState => {
  if (payloadedAction.payload) {
    const {
      payload: { data, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case CvvActionTypes.CVV_REQUEST:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: true,
          },
        }
      case CvvActionTypes.CVV_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: false,
            data,
          },
        }
      case CvvActionTypes.CVV_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            errors,
            pending: false,
          },
        }
      case CvvActionTypes.CVV_CLEAR:
        return {
          data: initialState.data,
        }
      default:
        return state
    }
  }

  return state
}
