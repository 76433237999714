import { Button } from "components/generic/button/button-default/Button"
import { routes } from "config"
import { Link } from "react-router-dom"
import s from "./landing.module.scss"

export const Landing: React.FC = () => {
  return (
    <div className={s["landing"]}>
      <div className={`${s["hero"]} hero-layout`}>
        <div className={s["hero__cta"]}>
          <h1 className={`${s["hero-text"]} ${s["hero-text-h1"]}`}>
            Твоя карта
            <br /> в новом интерфейсе
          </h1>
          <p className={`${s["hero-text"]} ${s["hero-text-sub"]}`}>
            Присоединяйся к своим друзьям
          </p>
        </div>
        <div className={s["hero__buttons"]}>
          <Link to={routes.index.c.register}>
            <Button
              buttonText="Создать аккаунт"
              buttonStyle="blue"
              buttonSize="small"
              fullWidth={false}
              onClick={() => {}}
            />
          </Link>
          <p className={s["button-note"]}>Мгновенно и бесплатно</p>
        </div>
      </div>
    </div>
  )
}
