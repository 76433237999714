import { BalanceView } from "./balance/BalanceView"
import { CardChanger } from "../card/card-changer/CardChanger"
import { ButtonIcon } from "components/generic/button/button-icon/ButtonIcon"
import { ReactComponent as PlusCircleIcon } from "components/icon/plus-circle.svg"
import { ReactComponent as SendIcon } from "components/icon/send.svg"
import s from "./sidebar.module.scss"
import { CustomLink } from "components/custom-link/CustomLink"
import { routes } from "config"

export const Sidebar: React.FC = () => {
  return (
    <div className={s["sidebar"]}>
      <div className={s["sidebar-head"]}>
        <BalanceView />
        <CardChanger changeable />
      </div>
      <div className={s.buttons}>
        <CustomLink
          to={routes.personal.c.deposit}
          searchForMatch={routes.personal.path}
        >
          <ButtonIcon
            text="Пополнить"
            buttonSize="small"
            icon={<PlusCircleIcon />}
          />
        </CustomLink>
        <CustomLink
          to={routes.personal.c.transfer}
          searchForMatch={routes.personal.path}
        >
          <ButtonIcon
            text="Перевести"
            icon={<SendIcon />}
            buttonSize={"small"}
          />
        </CustomLink>
      </div>
    </div>
  )
}
