import s from "../input.module.scss"

interface InputWithButtonProps {
  input: JSX.Element
  buttonContent?: JSX.Element | string
  shouldDisplayButton: boolean
  onClickButton?: () => void
}

export const InputWithButton: React.FC<InputWithButtonProps> = ({
  input,
  buttonContent,
  shouldDisplayButton,
  onClickButton,
}) => {
  return (
    <>
      <div className={s["input-with-button"]}>
        <div className={s["input-wrapper"]}>{input}</div>
        {buttonContent && shouldDisplayButton && (
          <div
            className={s["button-wrapper"]}
            style={{ display: shouldDisplayButton ? "block" : "none" }}
            onClick={() => onClickButton && onClickButton()}
          >
            <div
              className={
                typeof buttonContent === "string"
                  ? s["button-text"]
                  : s["button"]
              }
            >
              {buttonContent}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
