import { ApiResponse, ApiResponseError } from "store/types"
import { AppState } from "../../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"

export enum SupportActionTypes {
  SUPPORT_SEND_REQUEST = "SUPPORT_SEND_REQUEST",
  SUPPORT_SEND_REQUEST_SUCCESS = "SUPPORT_SEND_REQUEST_SUCCESS",
  SUPPORT_SEND_REQUEST_FAILURE = "SUPPORT_SEND_REQUEST_FAILURE",
  SUPPORT_RESET_RESULT = "SUPPORT_RESET_RESULT",
}

export interface PayloadedSupportAction {
  type: SupportActionTypes
  payload: {
    sentTo: string | null
    errors?: ApiResponseError[]
  }
}

export const supportSelector = (state: AppState) => state.support.data

export const supportResetResult =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: SupportActionTypes.SUPPORT_RESET_RESULT,
      payload: {},
    })
  }

export const supportSend =
  (text: string) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: SupportActionTypes.SUPPORT_SEND_REQUEST,
      payload: {},
    })

    fetcher({
      url: "/support",
      method: "POST",
      body: {
        text,
      },
    }).then((res: ApiResponse<{ sentTo: string }>) => {
      if (res.success) {
        dispatch<PayloadedSupportAction>({
          type: SupportActionTypes.SUPPORT_SEND_REQUEST_SUCCESS,
          payload: {
            sentTo: res.data.sentTo,
          },
        })
      } else {
        dispatch<PayloadedSupportAction>({
          type: SupportActionTypes.SUPPORT_SEND_REQUEST_FAILURE,
          payload: {
            sentTo: null,
            errors: res.errors || [],
          },
        })
      }
    })
  }
