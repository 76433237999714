import { ProfileNav } from "components/profile/profile-main/profile-nav/ProfileNav"
import { Aside } from "components/generic/modular/Aside"
import { ContentLayout } from "components/generic/modular/ContentLayout"
import { MainContent } from "components/generic/modular/MainContent"
import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { routes } from "config"
import { useSelector } from "react-redux"
import { settingsSelector } from "store/settings/SettingsActions"
import { ProfileCompact } from "components/profile/profile-compact/ProfileCompact"

export const ProfilePage: React.FC = () => {
  useEffect(() => {
    document.title = "Профиль"
  }, [])

  // Пришлось костылить из-за того, что макеты десктопа и мобильной версии отличаются по логике
  const { windowSize } = useSelector(settingsSelector)
  const path = useLocation().pathname
  const isMain =
    path === routes.profile.path || path === routes.profile.path + "/"

  return (
    <ContentLayout>
      <>
        <Aside>
          {windowSize === "MOBILE" && (
            <div style={{ paddingBottom: 20, marginTop: -25 }}>
              <ProfileCompact
                orientation="y"
                size="size-big"
                avatarSize="big"
                withBorder={false}
              />
            </div>
          )}
          <ProfileNav />
        </Aside>
        <MainContent>
          <div
            style={{
              display: isMain && windowSize === "MOBILE" ? "none" : "block",
            }}
          >
            <Outlet />
          </div>
        </MainContent>
      </>
    </ContentLayout>
  )
}
