import { ApiResponse, ApiResponseError } from "store/types"
import { AppState } from "../../store"
import { Dispatch } from "redux"
import { fetcher } from "utils"
import { Cvv } from "./Cvv"

export enum CvvActionTypes {
  CVV_REQUEST = "CVV_REQUEST",
  CVV_REQUEST_SUCCESS = "CVV_REQUEST_SUCCESS",
  CVV_REQUEST_FAILURE = "CVV_REQUEST_FAILURE",
  CVV_CLEAR = "CVV_CLEAR",
}

export interface PayloadedAction {
  type: CvvActionTypes
  payload: {
    data: Cvv | null
    errors: ApiResponseError[] | null
  }
}

export const cvvSelector = (state: AppState) => state.cvv.data

export const cvvGet =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: CvvActionTypes.CVV_REQUEST, payload: {} })
    const cardPublicId = getState().cards.data.primaryCard?.publicId

    fetcher({ url: `/cards/${cardPublicId}/cvv`, method: "GET" }).then(
      (res: ApiResponse<{ card: Cvv }>) => {
        if (res.success) {
          dispatch<PayloadedAction>({
            type: CvvActionTypes.CVV_REQUEST_SUCCESS,
            payload: {
              data: res.data.card,
              errors: [],
            },
          })
        } else {
          dispatch<PayloadedAction>({
            type: CvvActionTypes.CVV_REQUEST_FAILURE,
            payload: { data: null, errors: [] },
          })
        }
      }
    )
  }

export const cvvClear =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({
      type: CvvActionTypes.CVV_CLEAR,
      payload: {
        data: null,
        errors: [],
      },
    })
  }
