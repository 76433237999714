import s from "./drawer.module.scss"
import { ReactComponent as ArrowIcon } from "components/icon/arrow-next.svg"
import { useState } from "react"

interface DrawerProps {
  title: string
  withIcon?: boolean
}

export const Drawer: React.FC<DrawerProps> = ({
  title,
  withIcon = true,
  children,
}) => {
  const [contentHidden, setContentHidden] = useState(true)

  return (
    <div className={s["drawer"]}>
      <div
        className={s["drawer-head"]}
        onClick={() => setContentHidden(!contentHidden)}
        style={{ cursor: withIcon ? "pointer" : "default" }}
      >
        <div className={s["drawer-head__title"]}>{title}</div>
        {withIcon && (
          <div
            className={`${s["drawer-head__icon"]} ${
              !contentHidden ? s["drawer-head__icon-upside-down"] : ""
            }`}
          >
            <ArrowIcon />
          </div>
        )}
      </div>
      <div
        className={s["drawer-content"]}
        style={{ display: !contentHidden ? "block" : "none" }}
      >
        {children}
      </div>
    </div>
  )
}
