import { UpdateUserPopup } from "components/profile/profile-main/nav-items/identification/UpdateUserPopup"
import { ContentLayout } from "components/generic/modular/ContentLayout"
import { MainContent } from "components/generic/modular/MainContent"
import { Aside } from "components/generic/modular/Aside"
import { Sidebar } from "components/sidebar"
import { ReactComponent as InviteIcon } from "components/icon/invite.svg"
import {Link, Outlet} from "react-router-dom"
import React, { useEffect } from "react"
import { ButtonIcon } from "components/generic/button/button-icon/ButtonIcon"
import { Tabs } from "components/generic/tabs/Tabs"
import s from "components/generic/modular/styles.module.scss"
import { Statement } from "components/history/Statement"
import {CustomLink} from "../../../components/custom-link/CustomLink";
import {routes} from "../../../config";
import {CloseableBlock} from "../../../components/generic/modular/CloseableBlock";

export const MainPage: React.FC<{}> = () => {
  useEffect(() => {
    document.title = "Личный кабинет"
  }, [])

  return (
    <ContentLayout>
      <UpdateUserPopup />
      <Tabs
        tabListProps={{ style: { padding: 0 }, className: "desktop-hidden" }}
        tabList={[
          <ButtonIcon
            text="Главная"
            buttonSize="small"
            buttonStyle="lightgray"
            activeClass="all-blue"
          />,
          <ButtonIcon
            text="Операции"
            buttonSize="small"
            buttonStyle="lightgray"
            activeClass="all-blue"
          />,
        ]}
        tabPanel={[
          <div className={s["layout"]}>
            <Aside>
              <Sidebar />
            </Aside>
            <MainContent>
              <Outlet />
              <div style={{paddingTop: 20}}>
                <Link
                  to={routes.profile.c.invite.path}
                >
                  <ButtonIcon
                    text="Пригласить"
                    buttonSize="small"
                    icon={<InviteIcon />}
                    activeClass="all-blue"
                    active
                  />
                </Link>
              </div>
            </MainContent>
          </div>,
          <div style={{ paddingTop: 28 }}>
            <CloseableBlock
              title="Аналитика"
              onClickClose={() => {
                const mainPaige = document.getElementById("tab-panel__item_0");
                mainPaige!.style.display = "block";

                const operationPaige = document.getElementById("tab-panel__item_1");
                operationPaige!.style.display = "none";
              }}
            >
              <Statement checkDevice={false} />
            </CloseableBlock>
          </div>,
        ]}
      />
    </ContentLayout>
  )
}
