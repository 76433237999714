export const cfg = {
  isDevelopment: process.env.NODE_ENV === "development",
  BACKEND_URL: process.env.BACKEND_URL || "https://api-dev.quanty.tech" || "http://localhost:5000",
  BOT_TOKEN: "2031761463:AAGFfvXQIoJdRb11RHblaueYh3_a6rjoe_Q",
  TEST_BOT_TOKEN: "5000239950:AAHP7DPXEJwZU3GqrytqytLqODPjB863quU",
  BOT_INVITATION_URL: "https://t.me/share/url?url=https://t.me/qwertyxyzBot"
}

export const routes = {
  index: {
    path: "/",
    c: {
      login: "login",
      register: "register",
    },
  },
  personal: {
    path: "/personal",
    c: {
      deposit: "deposit",
      transfer: "transfer",
    },
  },
  profile: {
    path: "/profile",
    c: {
      info: { path: "info" },
      invite: { path: "invite" },
      identification: { path: "identification" },
      avatar: { path: "avatar" },
      help: { path: "help" },
    },
  },
  card: {
    path: "/card",
    c: {},
  },
}

export const MAX_PHONE_LEN = 11
export const OTP_LEN_DEFAULT = 6
export const SMS_CONFIRMATION_CODE_LENGTH_DEFAULT = 4
export const POPUPS_DISSAPEAR_TIMEOUT = 1500
// export const RESEND_OTP_TIME = moment().endOf("day").unix()
