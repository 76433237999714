import { useEffect, useState } from "react"
import { getCurrencySymbol } from "utils/currency/currency"
import s from "../operations.module.scss"
import { Button } from "components/generic/button/button-default/Button"
import { InputCurrency } from "components/generic/input/input-currency/InputCurrency"
import { InputPhone } from "components/generic/vendor/input-phone/InputPhone"
import { PhoneListContainer } from "components/sbp/phone-list/PhoneListContainer"
import { Validator } from "utils/Validator"

export const TransferByPhone: React.FC = () => {
  const [form, setForm] = useState({
    isValid: false,
    data: { summ: "", phone: "" },
  })

  useEffect(() => {
    setForm((form) => {
      return {
        ...form,
        isValid: form.data.summ !== "" && Validator.isPhone(form.data.phone),
      }
    })
  }, [form.data.summ, form.data.phone])

  const onChangeSumm = (v: string) => {
    setForm({
      ...form,
      data: {
        ...form.data,
        summ: v,
      },
    })
  }

  const onChangePhone = (v: string) => {
    setForm({
      ...form,
      data: {
        ...form.data,
        phone: v,
      },
    })
  }

  const onSubmitForm = () => {}

  return (
    <div className={s["operations"]}>
      <div className={s["operations__row"]}>
        <InputPhone
          value={form.data.phone || ""}
          onChangeValue={onChangePhone}
        />
      </div>
      <div className={s["operations__row"]}>
        <PhoneListContainer onSelect={onChangePhone} />
      </div>
      <div className={s["operations__row"]}>
        <InputCurrency
          id="summ"
          value={form.data.summ || ""}
          placeholder="Сумма"
          autoComplete="off"
          onChangeValue={onChangeSumm}
          currencySymbol={getCurrencySymbol("643")}
        />
        <p className={s["operations__fee"]}>
          Размер комиссии будет уточнен после ввода реквизитов
        </p>
      </div>
      <div className={s["operations__row"]}>
        <Button
          buttonText="Отправить"
          disabled={!form.isValid}
          onClick={onSubmitForm}
        />
      </div>
    </div>
  )
}
