import { cfg, routes } from "config"
import { ApiResponse } from "store/types"
import { CookieManager } from "./CookieManager"

export enum RequestMethods {
  "GET",
  "POST",
  "PUT",
  "DELETE",
}

export type RequestMethod = keyof typeof RequestMethods

export const fetcher = async (options: {
  url: string
  method: RequestMethod
  body?: { [key: string]: any } | null
}): Promise<any> => {
  const { url, method, body } = options
  const params: { [key: string]: any } = {
    method,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      authorization: `Bearer ${CookieManager.getTokenCookie()}`,
      "X-Real-IP": "192.168.1.1", // Temporary
    },
  }

  if (body) params.body = JSON.stringify(body)

  return fetch(cfg.BACKEND_URL + url, params)
    .then((res) => res.json())
    .then((res: ApiResponse<{ data: any }>) => {
      const errors = res?.errors
      if (errors) {
        if (
          errors.some(
            (e) =>
              e.textCode === "SESSION_TOKEN_EXPIRED" ||
              e.textCode === "SESSION_TOKEN_VERIFICATION_ERROR"
          )
        ) {
          CookieManager.removeTokenCookie()
          window.location.href = "/" + routes.index.c.login
        }
        if (
          errors.some(
            (e) => e.textCode === "AUTH_TOKEN_MISSMATCH" || "AUTH_TOKEN_EXPIRED"
          )
        ) {
        }
      }

      return res
    })
}

// Makes all props optional
export type Optional<T> = {
  [P in keyof T]?: T[P]
}

export type Nullable<T> = T | null

export type NullableVals<T> = {
  [P in keyof T]: Nullable<T[P]>
}

export const chunkArray = <T>(
  arr: Array<T>,
  chunkSize: number
): Array<Array<T>> => {
  if (!arr?.length) return []
  return arr.reduce(
    (prevVal: any, curVal: any, curIndex: number, array: Array<T>) => {
      return curIndex % chunkSize === 0
        ? prevVal.concat([array.slice(curIndex, curIndex + chunkSize)])
        : prevVal
    },
    []
  )
}

export const chooseRandom = <T>(arr: Array<T>, num = 1): Array<T> => {
  const total = (arr || []).length
  if (!total) return []
  const max = total >= num ? num : total

  const res = []
  for (let i = 0; i < max; ) {
    const random = Math.floor(Math.random() * total)
    if (res.indexOf(arr[random]) !== -1) {
      continue
    }
    res.push(arr[random])
    i++
  }

  return res
}

export const isEnterKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) =>
  e.key === "Enter"
