import s from "./card-changer.module.scss"
import { ReactComponent as ArrowIcon } from "components/icon/arrow-next.svg"
import { Link } from "react-router-dom"
import { routes } from "config"
import { Card, CardProps } from "./Card"
import { useAppSelector } from "store/hooks"
import { Transformer } from "utils/Transformer"
import { cardsSelector } from "store/cards/cards/CardsActions"

interface CardChangerProps extends Pick<CardProps, "size"> {
  changeable?: boolean
}

export const CardChanger: React.FC<CardChangerProps> = ({
  changeable = false,
  size,
}) => {
  const { primaryCard } = useAppSelector(cardsSelector)

  const card = {
    pan: Transformer.maskPan(primaryCard?.pan || "", { returnOnly: 2 }),
    cardName: "Virtual Plus Card",
    active: true,
  }

  return changeable ? (
    <div className={s["card-changer"]} style={{ cursor: "pointer" }}>
      <Link className={s["card"]} to={routes.card.path}>
        <Card card={card} size={size} />
        <div className={s["card-arrow"]}>
          <ArrowIcon />
        </div>
      </Link>
    </div>
  ) : (
    <Card card={card} size={size} />
  )
}
