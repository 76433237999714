import { ApiResponseError } from "store/types"
import { Limit } from "./Limits"
import { LimitsActionTypes, PayloadedAction } from "./LimitsActions"

interface LimitsData {
  limits: Limit[] | null
  pending: boolean
  errors: ApiResponseError[] | null
}

export interface LimitsState {
  readonly data: LimitsData
}

const initialState: { data: LimitsData } = {
  data: {
    limits: null,

    pending: false,
    errors: null,
  },
}

export const LimitsReducer = (
  state: LimitsState = initialState,
  payloadedAction: PayloadedAction
): LimitsState => {
  if (payloadedAction.payload) {
    const {
      payload: { data, errors },
    } = payloadedAction

    if (process.env.NODE_ENV === "development") {
      if (errors) {
        const errs = errors.filter((e) => e.hasOwnProperty("message"))
        if (errs.length) console.log(errs)
      }
    }

    const stateData = state.data

    switch (payloadedAction.type) {
      case LimitsActionTypes.LIMITS_REQUEST:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: true,
          },
        }
      case LimitsActionTypes.LIMITS_REQUEST_SUCCESS:
        return {
          data: {
            ...stateData,
            errors: null,
            pending: false,
            limits: data,
          },
        }
      case LimitsActionTypes.LIMITS_REQUEST_FAILURE:
        return {
          data: {
            ...stateData,
            errors,
            pending: false,
          },
        }
      case LimitsActionTypes.LIMITS_CLEAR:
        return {
          data: initialState.data,
        }
      default:
        return state
    }
  }

  return state
}
