import { AppState } from "../store"
import { Dispatch } from "redux"
import { RawHistory, OperationHistory } from "./History"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum HistoryActionTypes {
  HISTORY_REQUEST = "HISTORY_REQUEST",
  HISTORY_REQUEST_FAILURE = "HISTORY_REQUEST_FAILURE",
  HISTORY_REQUEST_SUCCESS = "HISTORY_REQUEST_SUCCESS",
}

export interface PayloadedHistoryAction {
  type: HistoryActionTypes
  payload: {
    history: OperationHistory[]
    errors?: ApiResponseError[]
  }
}

export const historySelector = (state: AppState) => state.history.data

export const getHistory =
  (startDate: string, endDate: string, count: number = 50) =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: HistoryActionTypes.HISTORY_REQUEST, payload: {} })

    const acct = 0 // TODO -> Account number
    fetcher({
      url: "/history",
      method: "POST",
      body: { acct, startDate, endDate, count },
    }).then((res: ApiResponse<{ history: RawHistory[] }>) => {
      if (res.success) {
        const history = res.data.history.length
          ? res.data.history.map((item) => ({
              retailerName: item.termRetailerName,
              retailerMcc: item.termSic,

              transactionAmount: item.amount,
              transactionFee: item.fee,
              transactionCurrency: item.currency,
              transactionDate: item.date,
              transactionCode: item.type,
              transactionType: item.tranType,

              balanceCurrent: item.availBalance,
              balanceBefore: item.availBalanceBefore,

              terminalCity: item.termCity,
              terminalLocation: item.terminalLocation,
            }))
          : []

        return dispatch<PayloadedHistoryAction>({
          type: HistoryActionTypes.HISTORY_REQUEST_SUCCESS,
          payload: { history },
        })
      } else {
        return dispatch<PayloadedHistoryAction>({
          type: HistoryActionTypes.HISTORY_REQUEST_FAILURE,
          payload: {
            history: [],
            errors: res.errors,
          },
        })
      }
    })
  }
