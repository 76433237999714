import { AppState } from "../../store"
import { Dispatch } from "redux"
import { SavedCard } from "./SavedCard"
import { fetcher } from "utils"
import { ApiResponse, ApiResponseError } from "store/types"

export enum SavedCardActionTypes {
  SAVED_CARD_REQUEST = "SAVED_CARD_REQUEST",
  SAVED_CARD_FAILURE = "SAVED_CARD_FAILURE",
  SAVED_CARD_SUCCESS = "SAVED_CARD_SUCCESS",
}

export interface PayloadedSavedCardAction {
  type: SavedCardActionTypes
  payload: {
    data: SavedCard[]
    error?: ApiResponseError[]
  }
}

export const savedCards = (state: AppState) => state.savedCard.data

export const getSavedCard =
  () =>
  (dispatch: Dispatch, getState: () => AppState): void => {
    dispatch({ type: SavedCardActionTypes.SAVED_CARD_REQUEST, payload: {} })

    fetcher({ url: "/saved-cards", method: "GET" })
      .then((res: ApiResponse<{ cards: SavedCard[] }>) => {
        if (res.success) {
          return dispatch<PayloadedSavedCardAction>({
            type: SavedCardActionTypes.SAVED_CARD_SUCCESS,
            payload: { data: res.data.cards },
          })
        } else {
          return dispatch<PayloadedSavedCardAction>({
            type: SavedCardActionTypes.SAVED_CARD_FAILURE,
            payload: { data: [], error: res.errors },
          })
        }
      })
      .catch((error) => {
        dispatch({ type: SavedCardActionTypes.SAVED_CARD_FAILURE, error })
      })
  }
