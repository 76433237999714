import s from "./card-changer.module.scss"
import { ReactComponent as CardIcon } from "components/icon/card-image.svg"
import { ReactComponent as QuantyLogo } from "components/icon/quanty-logo.svg"

export interface CardProps {
  size?: "small" | "big"
  card: {
    cardName: string
    pan: string
  }
}

export const Card: React.FC<CardProps> = ({ card, size = "small" }) => {
  return (
    <div className={`${s["card"]} ${s[`card-${size}`]}`}>
      <div className={s["card__content"]}>
        <div className={s["card__card-image"]}>
          <CardIcon />
        </div>
        <div className={s["card__card-logo"]}>
          <QuantyLogo />
        </div>

        <div className={s["card-info"]}>
          <p className={s["card-info__name"]}>{card.cardName}</p>
          <p className={s["card-info__pan"]}>{card.pan}</p>
        </div>
      </div>
    </div>
  )
}
