import { useEffect, useState } from "react"
import { getCurrencySymbol } from "utils/currency/currency"
import s from "../operations.module.scss"
import {
  CardData,
  CardInputContainer,
} from "components/card/card-input/CardInputContainer"
import { Button } from "components/generic/button/button-default/Button"
import { InputCurrency } from "components/generic/input/input-currency/InputCurrency"
import { useDispatch } from "react-redux"
import { getSavedCard, savedCards } from "store/cards/saved-card/SavedCardActions"
import { SwiperCarousel } from "components/generic/vendor/swiper/SwiperCarousel"
import { isValidCard } from "utils/card/card"
import { useAppSelector } from "store/hooks"

type formState = {
  isValid: boolean
  data: { summ: string; card: CardData | null }
}

export const DepositFromCard: React.FC = () => {
  const dispatch = useDispatch()
  const { data } = useAppSelector(savedCards)

  const [activeSlide, setActiveSlide] = useState(0)

  const [form, setForm] = useState<formState>({
    isValid: false,
    data: { summ: "", card: null },
  })

  const formValid = (form: formState) =>
    form.data.summ !== "" && isValidCard(form.data.card)

  useEffect(() => {
    if (!data) dispatch(getSavedCard())
  }, [data, dispatch])

  const onChangeCard = (v: CardData) => {
    const { pan, expiredate, cvv } = form.data.card || {}
    // 'if' needed to prevent 'Maximum update depth exceeded...' error
    if (v.pan !== pan || v.expiredate !== expiredate || v.cvv !== cvv) {
      const newState = {
        ...form,
        data: {
          ...form.data,
          card: v,
        },
      }

      setForm({
        ...newState,
        isValid: formValid(newState),
      })
    }
  }

  const onChangeSumm = (v: string) => {
    const newState = {
      ...form,
      data: {
        ...form.data,
        summ: v,
      },
    }
    setForm({ ...newState, isValid: formValid(newState) })
  }

  const onSubmitForm = () => {}

  return (
    <div className={s["operations"]}>
      <div className={s["operations__row"]}>
        <SwiperCarousel
          slides={[
            ...(data
              ? data.map((card, i) => (
                  <CardInputContainer
                    key={card.uid}
                    card={card}
                    onChange={onChangeCard}
                    active={activeSlide === i}
                  />
                ))
              : []),
            <CardInputContainer
              onChange={onChangeCard}
              active={(data ? data.length : 0) === activeSlide}
            />,
          ]}
          onChangeSwiper={(i: number) => {
            setActiveSlide(i)
          }}
        />
      </div>
      <div className={s["operations__row"]}>
        <InputCurrency
          id="summ"
          value={form.data.summ || ""}
          placeholder="Сумма"
          autoComplete="off"
          onChangeValue={onChangeSumm}
          currencySymbol={getCurrencySymbol("643")}
        />
        <p className={s["operations__fee"]}>
          Размер комиссии будет уточнен после ввода реквизитов
        </p>
      </div>
      <div className={s["operations__row"]}>
        <Button
          buttonText="Пополнить"
          disabled={!form.isValid}
          onClick={onSubmitForm}
        />
      </div>
    </div>
  )
}
