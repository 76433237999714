import { CustomCalendar } from "components/generic/vendor/calendar/Calendar"
import { _moment } from "index"
import { useState } from "react"
import s from "../input.module.scss"

export interface InputTextProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  onChangeCalendar: (e: string) => void
}

export const InputDate: React.FC<InputTextProps> = ({
  onChange,
  onChangeCalendar,
  className = "",
  ...props
}) => {
  const onChangeInput = (e: React.FormEvent<HTMLInputElement>) =>
    onChange && onChange(e)

  const [targetType, setTargetType] = useState<"text" | "date">("text")
  const value = props.value as string
  const momentValue = _moment(value || new Date())

  return (
    <div className={s["input-date"]}>
      <input
        className={s["input"]}
        {...props}
        onChange={onChangeInput}
        value={
          targetType === "text" && value?.length
            ? _moment(value).format("DD.MM.YYYY")
            : value
        }
        onFocus={(e) => {
          setTargetType("date")
          e.target.type = "date"
        }}
      />

      <div className={s["input-date__calendar"]}>
        <CustomCalendar
          currentPeriod={{ begin: momentValue, end: momentValue }}
          onCalendarChanged={(period) => {
            onChangeCalendar(period.begin.format("YYYY-MM-DD"))
          }}
          displayAs="icon"
          selectRange={false}
        />
      </div>
    </div>
  )
}
