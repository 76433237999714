import { Button } from "components/generic/button/button-default/Button"
import { Popup } from "components/generic/popup/Popup"
import { routes } from "config"
import { useState } from "react"
import { Link } from "react-router-dom"
import { UpdateUser } from "./UpdateUser"
import s from "./identification.module.scss"

export const UpdateUserPopup: React.FC = () => {
  // const { data } = useAppSelector((state) => state.user.data)
  // const isShown = !data?.firstName || !data.lastName
  // const [isPopupShown, setIsPopupShown] = useState(!isShown)
  const [isPopupShown, setIsPopupShown] = useState(false)

  const onClickSubmit = () => {}
  return (
    <Popup
      isShown={isPopupShown}
      onClosePopup={() => {
        setIsPopupShown(!isPopupShown)
      }}
    >
      <UpdateUser />
      <Link to={routes.personal.path}>
        <div style={{ marginTop: 30 }}>
          <Button
            buttonText="Заполнить позже"
            onClick={onClickSubmit}
            buttonSize="small"
            buttonStyle="blue"
          />
        </div>
        {/* <p className="small-note" style={{ textAlign: "center" }}>
              Заполнить позже
            </p> */}
      </Link>
    </Popup>
  )
}
