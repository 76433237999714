import moment, { Moment } from "moment"

export class Time {
  public static now = () => moment()

  public static momentRange = (begin: Moment, end: Moment) => {
    const incrementDate = begin.clone()
    const days = [begin]

    while (incrementDate.add(1, "day") <= end) {
      days.push(incrementDate.clone())
    }

    return days
  }

  public static shiftDates = (
    begin: Moment,
    end: Moment,
    shouldAdd: boolean
  ) => {
    const days = end.diff(begin, "days") + 1
    const _begin = begin.clone()
    const _end = end.clone()

    if (this.isFullMonth(begin, end)) {
      return {
        begin: shouldAdd
          ? _begin.add(1, "month").startOf("month")
          : _begin.subtract(1, "month").startOf("month"),
        end: shouldAdd
          ? _end.add(1, "month").endOf("month")
          : _end.subtract(1, "month").endOf("month"),
      }
    }

    return {
      begin: shouldAdd
        ? _begin.add(days, "days")
        : _begin.subtract(days, "days"),
      end: shouldAdd ? _end.add(days, "days") : _end.subtract(days, "days"),
    }
  }

  public static isFullMonth = (begin: Moment, end: Moment) => {
    const _begin = begin.clone()
    const _end = end.clone()

    return (
      Time.formatMoment(_begin, "D") === "1" &&
      Time.momentRange(_begin, _end).length ===
        +Time.formatMoment(_begin.endOf("month"), "D")
    )
  }

  public static toIsoString = (date: Moment) =>
    date.format("YYYY-MM-DDTHH:mm:ss") + "Z"

  public static formatMoment = (
    date: Moment,
    format: "YYYYMMDD" | "DD MMM" | "DD MMMM" | "MMM" | "MMMM" | "D"
  ) => {
    return date.format(format)
  }

  public static YYYYMMDDRange = (moments: Moment[]) => {
    return moments.map((item) => this.formatMoment(item, "YYYYMMDD"))
  }

  public static matchDates = (str: string): RegExpMatchArray | null => {
    return str.match(/\d{4}[-|/]?\d{2}[-|/]?\d{2}/gi)
  }
}
