import { Settings } from "./Settings"
import { SettingsActionTypes, PayloadedSettingsAction } from "./SettingsActions"

interface SettingsData {
  settings: Settings
}

export interface SettingsState {
  readonly data: SettingsData
}

const initialState: { data: SettingsData } = {
  data: {
    settings: { windowSize: "DESKTOP" },
  },
}

export const SettingsReducer = (
  state: SettingsState = initialState,
  payloadedAction: PayloadedSettingsAction
): SettingsState => {
  if (payloadedAction.payload) {
    const {
      payload: { windowSize },
    } = payloadedAction

    switch (payloadedAction.type) {
      case SettingsActionTypes.WINDOW_RESIZE:
        return {
          data: {
            settings: { windowSize },
          },
        }

      default:
        return state
    }
  }

  return state
}
