import { CardInputView } from "components/card/card-input/CardInputView"
import { PanInputData } from "components/generic/input/input-pan/InputPan"
import { useEffect, useState } from "react"
import {
  CardType,
  formatCardNumber,
  getCardTypeByValue,
  formatCvc,
  DEFAULT_CVC_LENGTH,
} from "utils/card/card"
import { Transformer } from "utils/Transformer"

export interface CardData {
  pan: {
    value: string
    valueMasked: string
    valid: boolean
    error: string
  }
  expiredate: {
    value: string
    valid: boolean
    error: string
  }
  cvv: {
    value: string
    valid: boolean
    error: string
  }
}

interface CardInputContainerProps {
  card?: { pan: string; expiredate: string }
  onChange?: (data: CardData) => void
  active: boolean
}

const byCardType = (pan: string) => {
  const cardType = getCardTypeByValue(pan)
  const max = cardType?.maxCardNumberLength

  return { cardType, isValidPan: pan.length === max }
}

export const CardInputContainer: React.FC<CardInputContainerProps> = ({
  card,
  active,
  onChange,
}) => {
  const dReg = /\D/g
  const formattedPan = formatCardNumber(card?.pan || "")
  const typeOfCard = byCardType(formattedPan)
  const isValidPan = card?.pan ? typeOfCard.isValidPan : false

  const isValidExpiry = card?.expiredate?.length === 4
  const expiry =
    isValidExpiry && card.expiredate
      ? `${card.expiredate.slice(0, 2)} / ${card.expiredate.slice(2)}`
      : ""

  const [isFirstStepPassed, setIsFirstStepPassed] = useState(isValidPan)

  const [cardType, setCardType] = useState<CardType | null>(
    typeOfCard.cardType || null
  )

  const [touched, setTouched] = useState(false)

  const [receiverBank] = useState<{
    image: ""
    backgroundColor: "red"
  } | null>(null)

  const [cardData, setCardData] = useState<CardData>({
    pan: {
      value: card?.pan ? formattedPan : "",
      valueMasked: card?.pan
        ? Transformer.maskPan((card.pan || "").replace(dReg, ""), {
            returnOnly: 2,
          })
        : "",
      valid: isValidPan,
      error: "",
    },
    expiredate: {
      value: expiry || "",
      valid: isValidExpiry,
      error: "",
    },
    cvv: {
      value: "",
      valid: false,
      error: "",
    },
  })

  useEffect(() => {
    if (active && typeof onChange === "function") onChange(cardData)
  }, [active, cardData, onChange])

  const change = (data: CardData) => {
    if (typeof onChange === "function" && cardData) onChange(data)
  }

  const onChangePan = (data: PanInputData) => {
    if (data.valid) setIsFirstStepPassed(!isFirstStepPassed)
    const c = {
      ...cardData,
      pan: {
        ...cardData.pan,
        value: data.formattedValue,
        valueMasked: Transformer.maskPan(data.value, {
          returnOnly: 2,
        }),
        valid: data.valid,
      },
    }

    setCardType(data.cardType)
    setCardData(c)
    change(c)
  }

  const onChangeExpireDate = (value: string) => {
    const d = (value && value.replace(dReg, "")) || ""
    const prevExpire = (cardData.expiredate.value || "").replace(dReg, "")

    if (prevExpire.length === d?.length) return
    if (d?.length <= 4) {
      const data = {
        ...cardData,
        expiredate: {
          ...cardData.expiredate,
          value: value,
          valid: d?.length === 4,
        },
      }

      setCardData(data)
      change(data)
    }
  }

  const onChangeCvv = (value: string) => {
    const d = value.replace(dReg, "")
    const cvcLength = cardType?.cvcLength || DEFAULT_CVC_LENGTH
    if (d.length <= cvcLength) {
      const data = {
        ...cardData,
        cvv: {
          ...cardData.cvv,
          value: formatCvc(value),
          valid: d?.length === cvcLength,
        },
      }
      setCardData(data)
      change(data)
    }
  }

  const onNavClick = () => {
    setIsFirstStepPassed(!isFirstStepPassed)
  }

  return (
    <CardInputView
      cardData={cardData}
      receiverBank={receiverBank}
      isFirstStepPassed={isFirstStepPassed}
      touched={touched}
      onChangePan={onChangePan}
      onChangeExpireDate={onChangeExpireDate}
      onChangeCvv={onChangeCvv}
      onNavClick={onNavClick}
      onTouch={() => {
        setTouched(true)
      }}
    />
  )
}
