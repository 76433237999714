import Cookies from "js-cookie"

export class CookieManager {
  private static tokenCookie = "t"
  private static tokenCookieExpires = 7 // TODO -> Set expiration date

  public static setTokenCookie = (token: string): string | undefined =>
    Cookies.set(this.tokenCookie, token, { expires: this.tokenCookieExpires })

  public static getTokenCookie = (): string | undefined =>
    Cookies.get(this.tokenCookie)

  public static removeTokenCookie = (): void => Cookies.remove(this.tokenCookie)
}
