import { ReactComponent as DoneStarIcon } from "components/icon/done-star.svg"
import { InputCopy } from "components/generic/input/input-copy/InputCopy"
import { InputText } from "components/generic/input/input-text/inputText"
import { Button } from "components/generic/button/button-default/Button"
import { LabelPanel } from "components/generic/modular/LabelPanel"
import { Popup } from "components/generic/popup/Popup"
import { useDispatch, useSelector } from "react-redux"
import { Validator } from "utils/Validator"
import { AppState } from "store/store"
import { useState } from "react"
import {
  inviteClearForm,
  inviteSendInvite,
} from "store/forms/invite/InviteActions"
import { userSelector } from "store/user/UserActions"
import { routes } from "config"

export const Invite: React.FC = () => {
  const dispatch = useDispatch()
  const { qUser } = useSelector(userSelector)
  const { data: inviteForm } = useSelector(inviteFormSelector)
  const {
    values: data,
    // formValid
  } = inviteForm
  // const url = data.partnerUrl.value
  const url = qUser?.inviteLink || ""
  const [email, setEmail] = useState("")
  const [valid, setValid] = useState(false)

  // useEffect(() => {
  //   // if (!data.partnerUrl.value) dispatch(invitePartnerUrl())
  //   if (data.sentTo.value !== "") {
  //     setTimeout(() => {
  //       dispatch(inviteClearForm())
  //     }, POPUPS_DISSAPEAR_TIMEOUT)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.partnerUrl.value, data.sentTo.value])

  const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
    // dispatch(inviteFormChangeEmail(e.currentTarget.value))
    const v = e.currentTarget.value
    setEmail(v)
    setValid(Validator.isEmail(v))
  }

  const onSendEmail = () => {
    dispatch(inviteSendInvite(email))
  }

  return (
    <>
      <div className="rows">
        <div className="row">
          <LabelPanel title="Ссылка для приглашения" textColor="dark-gray" />
          <InputCopy
            value={
              window.location.origin + "/" + routes.index.c.register + "/" + url
            }
            disabled
          />
        </div>
        <div className="row">
          <LabelPanel title="Отправить ссылку на почту" textColor="dark-gray" />
          <InputText
            value={email}
            placeholder="Адрес эл. почты"
            onChange={onChangeEmail}
          />
          <p className="small-note">На эту почту мы отправим приглашение</p>
          <div className="row__item">
            <Button
              buttonText="Отравить"
              disabled={!valid}
              onClick={onSendEmail}
            />
          </div>
        </div>
      </div>
      <Popup
        onClosePopup={() => dispatch(inviteClearForm())}
        isShown={data.sentTo.value.length > 0}
      >
        <div style={{ textAlign: "center" }}>
          <div className="popup-icon">
            <DoneStarIcon />
          </div>
          <p className="popup-text">
            Приглашение отправлено на почту
            <br />
            <b>{data.sentTo.value}</b>
          </p>
        </div>
      </Popup>
    </>
  )
}

const inviteFormSelector = ({ inviteForm }: AppState) => inviteForm
