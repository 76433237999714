import { ChartBarData } from "components/chart/bar/BarChart"
import moment, { Moment } from "moment"
import { OperationHistory } from "store/history/History"
import { Mcc, MccGroup } from "store/mcc/Mcc"
import { chunkArray } from "utils"
import { Time } from "utils/Time"

export interface Period {
  begin: Moment
  end: Moment
}

export interface GroupSpent {
  name: string
  background: string
  active: boolean
  amount: number
  currency: string
  mcc: string[]
}

export type ActiveGroup = { [key: string]: boolean }

export const getMccData = (mccCode: string, mcc: Mcc) => {
  const groupId = mcc.mcc[mccCode]
  if (!groupId) return mcc.groups["OTHER"]

  return mcc.groups[groupId]
}

export const byPeriod = (item: OperationHistory, period: Period) => {
  const tranDate = moment(item.transactionDate).unix()
  const begin = moment(period.begin).startOf("day").unix()
  const end = moment(period.end).endOf("day").unix()

  return tranDate >= begin && tranDate <= end
}

export const bySpents = (item: OperationHistory) =>
  +item.balanceCurrent <= +item.balanceBefore

export const byActiveGroup = (item: HistoryWithGroup, groups: ActiveGroup) =>
  groups[item?.group?.name]

export const calculateOperationsHistory = (
  history: OperationHistory[]
): { [date: string]: HistoryWithGroup[] } => {
  return history.reduce((previous: any, current: OperationHistory) => {
    const date = moment(current.transactionDate).format("DD MMMM")

    if (!previous.hasOwnProperty(date)) {
      previous[date] = [current]
    } else {
      previous[date].push(current)
    }

    return previous
  }, {})
}

export type HistoryWithGroup = OperationHistory & { group: MccGroup }

export const addMccGroup = (
  history: OperationHistory[],
  mcc: Mcc
): HistoryWithGroup[] =>
  history.map((item) => ({ ...item, group: getMccData(item.retailerMcc, mcc) }))

export const calculateGroupSpents = (
  history: HistoryWithGroup[],
  activeGroups: ActiveGroup
): GroupSpent[] => {
  const historyObj = history.reduce(
    (previous: { [key: string]: GroupSpent }, current) => {
      const { retailerMcc, transactionAmount, transactionCurrency, group } =
        current

      const amount = +transactionAmount
      const name = group?.name
      const background = group?.groupColor

      const active = activeGroups[name]

      if (!previous.hasOwnProperty(name)) {
        previous[name] = {
          name,
          background,
          active,
          amount: amount,
          currency: transactionCurrency,
          mcc: [retailerMcc],
        }
      } else {
        previous[name].amount = previous[name].amount + amount
        previous[name].mcc.push(retailerMcc)
      }

      return previous
    },
    {}
  )

  return Object.values(historyObj).sort((a, b) => b.amount - a.amount)
}

export const calculateChartData = (
  history: HistoryWithGroup[],
  period: Period,
  activeGroups: ActiveGroup
): ChartBarData => {
  const WEEK = 7
  const modifiedHistory = history.map((item) => {
    const date = moment(item.transactionDate)
    const YYYYMMDD = Time.formatMoment(date, "YYYYMMDD")

    return {
      ...item,
      timeData: {
        YYYYMMDD,
      },
    }
  })

  const YYYYMMDD = Time.YYYYMMDDRange(
    Time.momentRange(period.begin, period.end)
  )

  if (YYYYMMDD.length > WEEK) {
    const chunks = chunkArray(YYYYMMDD, WEEK)
    // Если неделя не полная, оставшиеся дни как отдельные
    // const lastChunk = chunks[chunks.length - 1]
    // if (lastChunk.length < WEEK) {
    //   chunks.pop()
    //   chunks.push(...lastChunk.map((d) => [d]))
    // }

    const chartData = chunks.reduce(
      (acc: { [key: string]: GroupSpent[] }, curr) => {
        const start = curr[0]
        const end = curr.length > 1 ? curr[curr.length - 1] : null

        const periodHistory = modifiedHistory.filter((item) =>
          curr.includes(item.timeData.YYYYMMDD)
        )

        acc[`${start}-${end}`] = calculateGroupSpents(
          periodHistory,
          activeGroups
        )
        return acc
      },
      {}
    )
    return chartData
  } else {
    const chartData = YYYYMMDD.reduce(
      (acc: { [key: string]: GroupSpent[] }, curr) => {
        const YYYYMMDD = curr

        const periodHistory = modifiedHistory.filter(
          (item) => item.timeData.YYYYMMDD === YYYYMMDD
        )

        acc[YYYYMMDD] = calculateGroupSpents(periodHistory, activeGroups)

        return acc
      },
      {}
    )

    return chartData
  }
}
